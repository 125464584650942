import type { MySoilClassificationOptions } from '../my-soil-classification-layer';
import { useMySoilClassificationLayer } from './use-mysoilclassification-layer';
import { useMySoilClassificationLabelsLayer } from './use-mysoilclassification-point-labels';

export function MySoilClassificationPolygonLayer(
	opts: MySoilClassificationOptions
) {
	const [, data, legendEntries] = useMySoilClassificationLayer(opts);
	useMySoilClassificationLabelsLayer({
		...opts,
		geojson: data,
		legendEntries: legendEntries || []
	});
	return null;
}
