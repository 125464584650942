// Reference:
import type { Coordinate } from 'ol/coordinate';
import type { DragBoxEvent } from 'ol/interaction/DragBox';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { CustomDragBoxOptions } from './custom-drag-box';
import { CustomDragBox } from './custom-drag-box';

export function useDragBox(options: CustomDragBoxOptions) {
	const dragBoxRef = useRef<CustomDragBox>(new CustomDragBox(options));
	const [start, setStart] = useState<Coordinate>();

	const onBoxStart = useCallback((event: DragBoxEvent) => {
		setStart(event.coordinate);
	}, []);

	const onBoxDrag = useCallback(
		(event: DragBoxEvent) => {
			if (!start) return;
			const end = event.coordinate;
			const dx = end[0] - start[0];
			const direction = dx > 0 ? 'right' : 'left';
			const className =
				direction === 'right'
					? 'bg-red-500 bg-opacity-10 border-red-500'
					: 'bg-blue-500 bg-opacity-10 border-blue-500';
			dragBoxRef.current.box.setClassName(className);
			dragBoxRef.current.set('direction', direction);
		},
		[start]
	);

	useEffect(() => {
		const dragBox = dragBoxRef.current;
		dragBox.on('boxstart', onBoxStart);
		dragBox.on('boxdrag', onBoxDrag);
		return () => {
			dragBox.un('boxstart', onBoxStart);
			dragBox.un('boxdrag', onBoxDrag);
		};
	}, [onBoxStart, onBoxDrag]);

	return [dragBoxRef.current] as const;
}
