import type { LayerFilter } from '@agritechnovation/api';
import { useLayerFilter } from '@agritechnovation/api-query';
import type { Rule, Style, Symbolizer, TextSymbolizer } from 'geostyler-style';
import { useMemo } from 'react';

export function useGeoJSONPointStyle(options: {
	farmId: number;
	layerFilterId: number;
	displayKey?: DisplayKey;
	iconURLCreator?: (layerFilter: LayerFilter) => string;
}) {
	const { data: layerFilter } = useLayerFilter(options.layerFilterId);
	const style: Style | null = useMemo(() => {
		if (!layerFilter) return null;
		if (layerFilter.icon) {
			return createIconStyle({
				layerFilter: layerFilter,
				displayKey: options.displayKey,
				urlCreator: options.iconURLCreator
			});
		} else {
			return createDefaultStyle({
				layerFilter: layerFilter,
				displayKey: options.displayKey
			});
		}
	}, [layerFilter, options.displayKey, options.iconURLCreator]);

	return {
		style,
		isPending: !style,
		unit: ''
	};
}

export type DisplayKey = {
	key: string;
	rules?: Rule[];
	color?: string;
};

function createDefaultStyle(options: {
	displayKey?: DisplayKey;
	layerFilter: LayerFilter;
}) {
	const symbolizers: Symbolizer[] = [
		{
			kind: 'Mark',
			wellKnownName: 'circle',
			color: '#001dff',
			radius: 8,
			strokeColor: '#000001',
			strokeWidth: 1
		}
	];

	if (options.displayKey) {
		symbolizers.push(
			displayKeySymbolizer(options.displayKey.key, options.displayKey.color)
		);
	}

	const additionalRules = options.displayKey?.rules ?? [];

	return {
		name: `Point for ${options.layerFilter.id} ${options.displayKey?.key}`,
		rules: [
			...additionalRules,
			{
				name: 'Default Point',
				symbolizers
			}
		],
		metadata: {
			classified: false
		}
	};
}

export function createLayerFilterIconURL(layerFilter: LayerFilter) {
	if (!layerFilter.icon) {
		throw new Error('layerFilter.icon is required for icon style');
	}
	return layerFilter.icon
		.replace(
			'https://myfarmweb.agritechnovation.com/',
			'https://myfarmweb.com/static-data/'
		)
		.replace(
			'https://us-mfw-site.s3.amazonaws.com/',
			'https://us.myfarmweb.com/static-data/'
		);
}

function createIconStyle(options: {
	layerFilter: LayerFilter;
	displayKey?: DisplayKey;
	urlCreator?: (layerFilter: LayerFilter) => string;
}) {
	if (!options.layerFilter) {
		throw new Error('layerFilter is required for icon style');
	}
	if (!options.layerFilter.icon) {
		throw new Error('layerFilter.icon is required for icon style');
	}
	const iconURL = options.urlCreator
		? options.urlCreator(options.layerFilter)
		: createLayerFilterIconURL(options.layerFilter);

	const iconSymbolizers: Symbolizer[] = [
		{
			kind: 'Icon',
			image: iconURL,
			size: 38,
			offset: [0, 19]
		}
	];

	const rules = [
		{
			name: `Default Point ${options.layerFilter.id} ${iconURL}`,
			symbolizers: iconSymbolizers
		}
	];
	if (options.displayKey) {
		if (!options.displayKey.rules) {
			iconSymbolizers.unshift(
				displayKeySymbolizer(options.displayKey.key, options.displayKey.color)
			);
		} else {
			rules.push(...options.displayKey.rules);
		}
	}
	return {
		name: `Point with icon for ${options.layerFilter.id} and ${options.displayKey?.key}`,
		rules,
		metadata: {
			classified: false
		}
	};
}

/**
 * Create a text symbolizer for a given point property (displayKey).
 */
function displayKeySymbolizer(
	displayKey: string,
	color?: string
): TextSymbolizer {
	return {
		kind: 'Text',
		label: `{{${displayKey}}}`,
		fontWeight: 'bold',
		size: 12,
		font: ['Arial'],
		offset: [0, -15],
		color: color ?? '#ffffff',
		haloColor: '#333333',
		haloWidth: 10,
		haloOpacity: 1,
		opacity: 1,
		maxAngle: 0,
		allowOverlap: true,
		avoidEdges: false,
		anchor: 'center'
	};
}
