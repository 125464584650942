import type Map from 'ol/Map';
import type { RefObject } from 'react';
import { createContext, useContext } from 'react';

export type MapContextValue = {
	map: Map;
	ref: RefObject<HTMLDivElement>;
};

export const MapContext = createContext<MapContextValue | null>(null);

const useMapContext = () => {
	const context = useContext(MapContext);
	if (!context) {
		throw new Error('Element is not wrapped in MapContextProvider');
	}
	return context;
};

export const useMap = () => {
	const context = useMapContext();
	return context.map;
};

export const useMapRef = () => {
	const context = useMapContext();
	return context.ref;
};
