export function roundNumber(num: number, precision: number) {
	if (!('' + num).includes('e')) {
		return +(
			Math.round((num + 'e+' + precision) as unknown as number) +
			'e-' +
			precision
		);
	} else {
		const arr = ('' + num).split('e');
		let sig = '';
		if (+arr[1] + precision > 0) {
			sig = '+';
		}
		return +(
			Math.round(
				(+arr[0] + 'e' + sig + (+arr[1] + precision)) as unknown as number
			) +
			'e-' +
			precision
		);
	}
}
