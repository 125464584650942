import {
	isMyProfitabilityLayer,
	isYieldLayer,
	useLayerFilter
} from '@agritechnovation/api-query';
import type { AllLayerOptions } from './layer-options';
import {
	isITESTCarbohydatesLayer,
	isITESTMicroLifeLayer,
	isITESTSoilLayer,
	isMyIrrigationLayer,
	isPPMLayer,
	isSoilClassificationLayer
} from '@agritechnovation/api';
import { MyIrrigationLayer } from './products/myirrigation/my-irrigiation-layer';
import {
	ITESTCarbohydrateLayer,
	ITESTMicroLifeLayer,
	ITESTSoilLayer
} from './products';
import { MySoilClassificationLayer } from './products/mysoilclassification/my-soil-classification-layer';
import { PPMLayer } from './products/ppm/ppm-layer';
import { DefaultLayer } from './products/default/default.layer';
import { MyYieldLayer } from './products/myyield';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { useCallback, useEffect } from 'react';
import { useMapLayersContext } from '../context/map-layers.context';

export function Layer(opts: AllLayerOptions) {
	const Fallback = useCallback(
		({ error, resetErrorBoundary }: FallbackProps) => (
			<ErrorFallback
				error={error}
				resetBoundary={resetErrorBoundary}
				layerId={opts.layer.uniqueId}
			/>
		),
		[opts.layer.uniqueId]
	);
	return (
		<ErrorBoundary FallbackComponent={Fallback}>
			<WrappedLayer {...opts} />
		</ErrorBoundary>
	);
}

function ErrorFallback({
	error,
	resetBoundary,
	layerId
}: {
	error: Error;
	resetBoundary: () => void;
	layerId: string;
}) {
	const { addError } = useMapLayersContext();
	const handleError = useCallback(
		(error: Error) => {
			console.error(error);
			addError(layerId, error, resetBoundary);
		},
		[addError, layerId, resetBoundary]
	);

	useEffect(() => {
		handleError(error);
	}, [error, handleError]);
	return null;
}

function WrappedLayer(opts: AllLayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	if (!layerFilter) return null;
	if (isMyIrrigationLayer(layerFilter)) {
		return <MyIrrigationLayer {...toDefaults(opts)} />;
	}
	if (isITESTSoilLayer(layerFilter)) {
		return <ITESTSoilLayer {...toDefaults(opts)} />;
	}
	if (isSoilClassificationLayer(layerFilter)) {
		return <MySoilClassificationLayer {...toDefaults(opts)} />;
	}
	if (isPPMLayer(layerFilter)) {
		return <PPMLayer {...toDefaults(opts)} />;
	}

	if (isMyProfitabilityLayer(layerFilter) || isYieldLayer(layerFilter)) {
		return <MyYieldLayer {...toDefaults(opts)} />;
	}

	if (isITESTMicroLifeLayer(layerFilter)) {
		return <ITESTMicroLifeLayer {...toDefaults(opts)} />;
	}

	if (isITESTCarbohydatesLayer(layerFilter)) {
		return <ITESTCarbohydrateLayer {...toDefaults(opts)} />;
	}

	return <DefaultLayer {...toDefaults(opts)} />;
}

function toDefaults(opts: AllLayerOptions): AllLayerOptions {
	return {
		...opts,
		render: {
			...opts.render,
			zIndex: opts.render.zIndex,
			declutter: !!opts.render.declutter
		}
	};
}
