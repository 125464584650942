import { useEffect, useMemo } from 'react';
import {
	parseClassToFloatMinMax,
	useLayerFilter
} from '@agritechnovation/api-query';
import { useFindLayer } from '../../../../helpers';
import { toOL, useGeoTIFFData, useGeoTIFFStyle } from '../../../data';
import type { CustomTileLayer } from '@agritechnovation/ol-react';
import { useTileLayer } from '@agritechnovation/ol-react';
import type { MyYieldLayerOptions } from '../my-yield-layer';
import { useLayerAvailableLayers } from '../../../helpers';

export function useMyYieldGeoTIFFLayer(opts: MyYieldLayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	const { data: dates = [] } = useLayerAvailableLayers(opts.data);

	const { data, isPending } = useGeoTIFFData(opts.data);

	const { data: style } = useGeoTIFFStyle(opts);

	const initialLayer = useFindLayer<CustomTileLayer>(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);
	const availableLayerId = useMemo(() => dates?.at(0)?.id, [dates]);

	const { maxTons, minTons } = useMemo(() => {
		const maxTons =
			parseClassToFloatMinMax(style?.merged?.at(-1)?.legend as string).max || 0;
		const minTons =
			parseClassToFloatMinMax(style?.merged?.at(0)?.legend as string).min || 0;
		return {
			maxTons,
			minTons
		};
	}, [style?.merged]);

	const [layer] = useTileLayer({
		initialLayer,
		zIndex: opts.render.zIndex,
		data: data,
		style:
			layerFilter && style?.merged
				? toOL(layerFilter, style?.merged)
				: undefined,
		opacity: opts.render.opacity,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			layerFilterId: opts.data.layerFilterId,
			dates: opts.data.dates,
			// the backend seems not to care most of the time, it seems it just uses the al to determine the farm id, layerfilter id and dates
			// but we sometimes need to pass it to the backend from the layer properties (e.g. LayerInfo component)
			available_layer_id: availableLayerId,
			geoStylerStyle: style?.style,
			type: 'cog'
		}
	});

	useEffect(() => {
		layer.set('maxTons', maxTons);
		layer.set('minTons', minTons);
	}, [layer, maxTons, minTons]);

	useEffect(() => {
		if (layer) {
			layer.set('mfw_uuid', opts.layer.uniqueId);
			layer.set('farmId', opts.data.farmId);
			layer.set('layerFilterId', opts.data.layerFilterId);
			layer.set('dates', opts.data.dates);
			layer.set('type', 'cog');

			// layer.set('available_layer_id', availableLayerId);
		}
	}, [
		layer,
		opts.data.dates,
		opts.data.farmId,
		opts.data.layerFilterId,
		opts.layer.uniqueId
	]);

	return [{ data, isPending }, layer] as const;
}
