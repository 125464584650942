import {
	useITESTCarbohydratesLayer,
	type ITESTCarbohydratesLayerOptions
} from './use-itest-carbohydrates-layer';

export function ITESTCarbohydrateLayer(
	options: ITESTCarbohydratesLayerOptions
) {
	useITESTCarbohydratesLayer(options);
	return null;
}
