import { createContext, useContext } from 'react';

export interface IMapLayersContext {
	errors: {
		layerId: string;
		error: Error;
		time: number;
		reset: () => void;
	}[];
	addError: (layerId: string, error: Error, reset: () => void) => void;
	removeError: (layerId: string) => void;
	staticIconsPath: string;
	getIconPath: (icon: string) => string;
}

export const MapLayersContext = createContext<IMapLayersContext | null>(null);

export function useMapLayersContext() {
	const context = useContext(MapLayersContext);
	if (!context) {
		return {
			errors: [],
			addError: (_: string, __: Error) => {
				// do nothing
			},
			removeError: (_: string) => {
				// do nothing
			},
			staticIconsPath: '/',
			getIconPath: (icon: string) => {
				if (icon.startsWith('/')) {
					return icon;
				} else {
					return `/${icon}`;
				}
			}
		};
	}
	return context;
}
