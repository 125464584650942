import { useCompareContext } from './compare.context';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import './compare.handle.style.css';

export function CompareToolHandle({
	renderLeft,
	renderRight
}: {
	renderLeft: React.ReactNode;
	renderRight: React.ReactNode;
}) {
	const { setPositionPercent, positionPercent, windowWidth } =
		useCompareContext();
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<>
			<div
				ref={containerRef}
				className={twMerge(
					'pointer-events-none compare-tool-slider absolute top-0 left-0 z-50 bg-opacity-50 overflow-hidden select-none',
					'w-full h-full'
				)}
			>
				{renderLeft}
				<input
					ref={inputRef}
					type="range"
					className="w-full h-full pointer-events-auto z-50 relative"
					min="0"
					max={windowWidth}
					step="1"
					onChange={(e) =>
						setPositionPercent((e.target.valueAsNumber / windowWidth) * 100)
					}
					value={(positionPercent / 100) * windowWidth}
				/>
				{renderRight}
			</div>
		</>
	);
}
