import type { Options } from 'ol/layer/WebGLTile';
import TileLayer from 'ol/layer/WebGLTile';
import { useEffect, useState } from 'react';
import type {
	Options as GeoTIFFOptions,
	GeoTIFFSourceOptions,
	SourceInfo
} from 'ol/source/GeoTIFF';
import GeoTIFF from 'ol/source/GeoTIFF';
import { useMap } from '../../map';
import type { Pixel } from 'ol/pixel';
import type { ExpressionValue } from 'ol/expr/expression';
import { TileWMS } from 'ol/source';

export class CustomGeoTIFFSource extends GeoTIFF {
	_optionStore: GeoTIFFOptions;

	constructor(options: GeoTIFFOptions) {
		super(options);
		this._optionStore = options;
	}

	getOptions() {
		return this._optionStore;
	}

	clone() {
		return new CustomGeoTIFFSource(this._optionStore);
	}
}

export type CustomTileLayerOptions = Options & {
	properties?: {
		[key: string]: unknown;
	};
	sld_body_fn?: (scale: number) => string;
};

// This is needed so that we can set custom properties on TileLayers
export class CustomTileLayer extends TileLayer {
	mfw_properties: {
		[key: string]: unknown;
	} = {};
	sl_body_fn: ((scale: number) => string) | undefined = undefined;

	originalOptions_: CustomTileLayerOptions = {};

	constructor(options: CustomTileLayerOptions) {
		super(options);
		if (options.properties) {
			this.mfw_properties = options.properties;
		}
		if (options.sld_body_fn) {
			this.sl_body_fn = options.sld_body_fn;
		}
		this.originalOptions_ = options;
	}

	get = (key: string) => {
		const val = this.mfw_properties[key];
		if (!val) {
			return super.get(key);
		}
		return val;
	};

	getData(
		pixel: Pixel
	): Uint8ClampedArray | Uint8Array | Float32Array | DataView | null {
		return super.getData(pixel);
	}

	getStyle() {
		// @ts-expect-error -- reasons
		return this.style_;
	}

	clone(scaling?: number) {
		const source = this.getSource();
		if (source instanceof CustomGeoTIFFSource) {
			return new CustomTileLayer({
				zIndex: this.getZIndex(),
				source: source.clone(),
				properties: this.mfw_properties,
				style: this.getStyle()
			});
		} else if (source instanceof TileWMS) {
			const urls = source.getUrls() as string[];
			// @ts-expect-error -- shhh
			const serverType = source.serverType_ as 'geoserver';
			// @ts-expect-error -- shhh
			const hidpi = source.hidpi_;
			let params = source.getParams();
			if (scaling) {
				params = {
					...params,
					sld_body: this.sl_body_fn?.(scaling) ?? params.sld_body
				};
			}
			const cloneSource = new TileWMS({
				urls,
				serverType,
				hidpi,
				params
			});
			const options = {
				...this.originalOptions_,
				source: cloneSource
			};
			return new CustomTileLayer(options);
		} else {
			return this;
		}
	}
}

export type UseTileLayerOpts<
	P extends {
		[key: string]: unknown;
	}
> = {
	initialLayer?: CustomTileLayer | (() => CustomTileLayer | undefined);
	normalize?: boolean;
	opacity?: number;
	data: Array<SourceInfo> | undefined;
	sourceOptions?: GeoTIFFSourceOptions;
	style?: ExpressionValue;
	visible?: boolean;
	/**
	 * Ol layer properties, not updated if changed, other option changes will update the layer
	 */
	properties?: P;
	featureProjection?: string;
	zIndex?: number;
	interpolate?: boolean;
	isRGB?: boolean;
};

export function useTileLayer<
	P extends {
		[key: string]: unknown;
	}
>({
	initialLayer,
	normalize = false,
	sourceOptions,
	properties,
	style,
	zIndex,
	opacity = 1,
	data,
	interpolate,
	isRGB
}: UseTileLayerOpts<P>) {
	const map = useMap();
	const [layer] = useState<CustomTileLayer>(() => {
		if (initialLayer) {
			if (typeof initialLayer === 'function') {
				const init = initialLayer();
				if (init) {
					return init;
				}
			} else {
				return initialLayer;
			}
		}
		return new CustomTileLayer({
			zIndex,
			properties,
			style: {
				color: style
			}
		});
	});

	useEffect(() => {
		if (style) {
			layer.setStyle({
				color: style
			});
		}
	}, [layer, style]);

	useEffect(() => {
		if (data && data.length > 0) {
			const source = layer.getSource();
			if (source) {
				source.clear();
				if (isRGB !== undefined) {
					if (isRGB) {
						layer.setSource(
							new CustomGeoTIFFSource({
								sources: data
							})
						);
					} else {
						layer.setSource(
							new CustomGeoTIFFSource({
								sources: data,
								normalize,
								interpolate
							})
						);
					}
				} else {
					layer.setSource(
						new CustomGeoTIFFSource({
							sources: data,
							normalize,
							interpolate
						})
					);
				}
				map.addLayer(layer);
			} else {
				if (isRGB) {
					layer.setSource(
						new CustomGeoTIFFSource({
							sources: data,
							sourceOptions
						})
					);
				} else {
					layer.setSource(
						new CustomGeoTIFFSource({
							sources: data,
							normalize,
							interpolate,
							sourceOptions
						})
					);
				}
				map.addLayer(layer);
			}
			return () => {
				map.removeLayer(layer);
				layer.dispose();
			};
		}
	}, [data, interpolate, isRGB, layer, map, normalize, sourceOptions]);

	useEffect(() => {
		layer.setOpacity(opacity);
	}, [layer, opacity]);

	useEffect(() => {
		if (zIndex) {
			layer.setZIndex(zIndex);
		}
	}, [layer, zIndex]);

	return [layer] as const;
}
