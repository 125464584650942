import type BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import { Point } from 'ol/geom';
import type { Extent } from 'ol/extent';
import { buffer, isEmpty } from 'ol/extent';
import VectorTileLayer from 'ol/layer/VectorTile';
import type { ProjectionLike } from 'ol/proj';
import { transformExtent } from 'ol/proj';
import { CustomTileLayer } from '../layers/tile/use-tile-layer';
import TileLayer from 'ol/layer/Tile';

/**
 * Get the zoom extent for a layer, with a buffer around a point, never returns an empty extent
 * @param layer the layer to get the extent for
 * @param mapProjection
 * @param pointBuffer default 200
 */
export function getZoomExtent<T extends BaseLayer>(
	layer: T,
	mapProjection: ProjectionLike,
	pointBuffer = 200
): Extent | undefined {
	let extent;
	if (layer instanceof VectorLayer) {
		const source = layer.getSource();
		extent = source?.getExtent();
		const features = source?.getFeatures() || [];
		if (features.length === 1) {
			const feature = features[0];
			const geometry = feature.getGeometry();
			if (geometry instanceof Point && extent) {
				extent = buffer(extent, pointBuffer);
			}
		}
	}
	if (layer instanceof VectorTileLayer) {
		extent = layer.get('featuresExtent');
	}
	if (layer instanceof CustomTileLayer || layer instanceof TileLayer) {
		const source = layer.getSource();
		if (!source) return;
		extent = source.getTileGrid()?.getExtent();
		if (!extent) return;
		const projection = source.getProjection();
		if (!projection) return;
		extent = transformExtent(extent, projection, mapProjection);
	}
	if (!extent) return;
	if (!isEmpty(extent)) {
		return extent;
	}
	return;
}
