export function makeDataSetProps<T extends Record<string, string>>(
	dataset: Record<string, string>
): {
	[K in keyof T]: string;
} {
	return Object.entries(dataset).reduce(
		(acc, [key, value]) => {
			acc[`data-${key}` as keyof T] = value;
			return acc;
		},
		{} as {
			[K in keyof T]: string;
		}
	);
}

export function convertRemToPixels(rem: string) {
	return (
		parseFloat(rem) *
		parseFloat(getComputedStyle(document.documentElement).fontSize)
	);
}

export async function getImageDimensions(
	src: string
): Promise<{ width: number; height: number }> {
	return new Promise((resolve) => {
		const image = new Image();
		image.onload = () => {
			resolve({ width: image.width, height: image.height });
		};
		image.src = src;
	});
}
