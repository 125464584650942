import type { UseVectorLayerOpts } from '../vector/use-vector-layer';
import { GeoJSON } from 'ol/format';
import { useEffect, useState } from 'react';
import VectorTileSource from 'ol/source/VectorTile';
import { useMap } from '../../map';
import type { Feature, Features } from 'geojson-vt';
import geojsonvt from 'geojson-vt';
import VectorTileLayer from 'ol/layer/VectorTile';
import { Projection } from 'ol/proj';
import type {
	GeoJSONFeature,
	GeoJSONFeatureCollection,
	GeoJSONGeometry
} from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import type { FeatureLike } from 'ol/Feature';

function geojsonvtToGeoJSON(features: Features) {
	const fc: GeoJSONFeatureCollection = {
		type: 'FeatureCollection',
		features: []
	};
	for (const f of features) {
		fc.features.push(geojsonvtFeatureToGeoJSON(f));
	}
	return fc;
}

function geojsonvtFeatureToGeoJSON(inputFeature: Feature): GeoJSONFeature {
	return {
		type: 'Feature',
		geometry: geojsonvtGeomToGeoJSONGeom(inputFeature),
		properties: inputFeature.tags || {}
	};
}

function geojsonvtGeomToGeoJSONGeom(feature: Feature): GeoJSONGeometry {
	if (feature.type === 1) {
		if (feature.geometry.length === 1) {
			return {
				type: 'Point',
				coordinates: feature.geometry[0]
			};
		}
		return {
			type: 'MultiPoint',
			coordinates: feature.geometry
		};
	}
	if (feature.type === 2) {
		if (feature.geometry.length === 1) {
			return {
				type: 'LineString',
				coordinates: feature.geometry[0]
			} as unknown as GeoJSONGeometry;
		}
		return {
			type: 'MultiLineString',
			coordinates: feature.geometry
		} as unknown as GeoJSONGeometry;
	}
	if (feature.type === 3) {
		if (feature.geometry.length > 1) {
			return {
				type: 'MultiPolygon',
				coordinates: [feature.geometry]
			} as unknown as GeoJSONGeometry;
		}
		return {
			type: 'Polygon',
			coordinates: feature.geometry
		} as unknown as GeoJSONGeometry;
	}
	throw new Error('Unknown geometry type');
}

export function useGeoJSONVTLayer(
	opts: UseVectorLayerOpts<{
		[p: string]: unknown;
	}>
) {
	const map = useMap();
	const [layer] = useState<VectorTileLayer<FeatureLike>>(() => {
		let layer = opts.initialLayer as unknown as VectorTileLayer<FeatureLike>;
		if (typeof layer === 'function') {
			// @ts-expect-error -- erm
			layer = layer();
		}
		if (layer) return layer;
		return new VectorTileLayer({
			zIndex: opts.zIndex,
			properties: opts.properties
			// renderMode: 'vector'
		});
	});

	useEffect(() => {
		if (!opts.data) return;
		const tileIndex = geojsonvt(opts.data, {
			extent: 4096,
			tolerance: 1,
			maxZoom: 14,
			promoteId: 'label'
		});

		// calculate the extent of the features
		const features = new GeoJSON().readFeatures(opts.data, {
			dataProjection: 'EPSG:4326',
			featureProjection: map.getView().getProjection()
		});
		const featSource = new VectorSource({
			features: features
		});
		layer.set('featuresExtent', featSource.getExtent());
		featSource.clear();

		const format = new GeoJSON({
			dataProjection: new Projection({
				code: 'TILE_PIXELS',
				units: 'tile-pixels',
				extent: [0, 0, 4096, 4096]
			})
		});
		const source = new VectorTileSource({
			maxZoom: 14,
			tileUrlFunction: function (tileCoord) {
				return tileCoord as unknown as string;
			},
			tileLoadFunction: function (tile, url) {
				const tileCoord = url as unknown as [number, number, number];
				const data = tileIndex.getTile(
					tileCoord[0],
					tileCoord[1],
					tileCoord[2]
				);
				const geojson = geojsonvtToGeoJSON(data?.features || []);
				const features = format.readFeatures(geojson, {
					extent: source.getTileGrid()?.getTileCoordExtent(tileCoord),
					featureProjection: map.getView().getProjection()
				});
				// @ts-expect-error -- ol types are wrong
				tile.setFeatures(features);
			}
		});
		layer.setSource(source);
	}, [layer, map, opts.data]);

	useEffect(() => {
		if (opts.visible === false) {
			layer.setVisible(false);
		} else {
			layer.setVisible(true);
		}
	}, [opts.visible, layer]);

	useEffect(() => {
		if (opts.style) {
			layer.setStyle(opts.style);
		}
	}, [opts.style, layer]);

	useEffect(() => {
		if (opts.addToMap) {
			const hasLayer = map.getLayers().getArray().includes(layer);
			if (!hasLayer) {
				map.addLayer(layer);
			}
		}
		return () => {
			map.removeLayer(layer);
		};
	}, [opts.addToMap, layer, map]);

	useEffect(() => {
		layer.setZIndex(opts.zIndex ?? 0);
	}, [opts.zIndex, layer]);

	useEffect(() => {
		layer.setOpacity(opts.opacity ?? 1);
	}, [opts.opacity, layer]);

	return [layer];
}
