import type { Geometry } from 'ol/geom';
import { LineString, Point, Polygon } from 'ol/geom';

export function getLastCoordinate(geom: Geometry) {
	if (geom instanceof Point) {
		return geom.getCoordinates();
	}
	if (geom instanceof Polygon) {
		return geom.getLastCoordinate();
	}
	if (geom instanceof LineString) {
		return geom.getLastCoordinate();
	}
}
