import type TileLayer from 'ol/layer/Tile';
import type { BingMaps, OSM, TileImage, XYZ } from 'ol/source';
import { useEffect, useMemo, useState } from 'react';
import './base-layer-select.css';
import { twMerge } from 'tailwind-merge';
import { Transition } from '@headlessui/react';
import { getTilePreview } from '../utils/get-tile-preview';

export type MapBaseLayerDefinition = {
	id: string;
	name: string;
	previewImage?: string;
	load(): TileLayer<OSM | XYZ | BingMaps | TileImage>;
};

export const BaseLayerSelect = (props: {
	layers: Array<MapBaseLayerDefinition>;
	selected?: string;
	onChange?: (layer: string) => void;
}) => {
	const [expanded, setExpanded] = useState(false);

	const sorted = useMemo(() => {
		const selected = props.layers.find((l) => l.id === props.selected);
		// Sort selected layer to the bottom
		const withoutSelected = props.layers.filter((l) => l.id !== props.selected);
		return selected ? [...withoutSelected, selected] : props.layers;
	}, [props.layers, props.selected]);

	return (
		<div
			data-testid="base-layer-select"
			className="base-layer-select hover:cursor-pointer z-10 pointer-events-auto flex flex-col items-end justify-center text-white pt-2 h-fit"
			onMouseEnter={() => setExpanded(true)}
			onMouseLeave={() => setExpanded(false)}
		>
			{sorted.map((layer, index) => {
				return (
					<BaseLayerItem
						expanded={expanded || layer.id === props.selected}
						selected={layer.id === props.selected}
						first={index === 0}
						last={index === props.layers.length - 1}
						key={layer.id}
						layer={layer}
						onClick={(name) => {
							if (props.onChange) props.onChange(name);
							setExpanded(false);
						}}
					/>
				);
			})}
		</div>
	);
};

function BaseLayerItem({
	layer,
	onClick,
	first,
	last,
	selected,
	expanded
}: {
	layer: MapBaseLayerDefinition;
	onClick?: (name: string) => void;
	first: boolean;
	last: boolean;
	selected: boolean;
	expanded: boolean;
}) {
	const [preview, setPreview] = useState({
		url: '',
		loaded: false
	});

	useEffect(() => {
		if (layer.id === 'blank') {
			setPreview({ url: '', loaded: true });
			return;
		}
		if (layer.id === 'google') {
			setPreview({
				url: layer.previewImage || '',
				loaded: true
			});
			return;
		}
		if (!expanded) return;
		getTilePreview(
			layer.load(),
			[2118398.886661197, -3978259.1896437006],
			28.62984752877523
		).then((url) => {
			setPreview({ url, loaded: true });
		});
	}, [expanded, layer]);

	const style = useMemo(() => {
		const dims = '56px'; // selected ? '80px' : '56px';
		if (layer.id === 'blank')
			return {
				boxShadow: 'inset 0 0 10px 2px rgba(51, 51, 51, 0.8)',
				background: 'white',
				width: dims,
				height: dims
			};
		return {
			backgroundImage: `url(${preview.url})`,
			width: dims,
			height: dims
		};
	}, [layer, preview]);

	return (
		<Transition
			show={expanded}
			className="shadow-inner shadow-lg"
			enter="transition duration-100 ease-out"
			enterFrom="transform scale-95 opacity-0"
			enterTo="transform scale-100 opacity-100"
		>
			<div
				className={twMerge(
					'base-layer-item pointer-events-auto shadow-lg border-4 border-white h-fit bottom-0',
					first && !selected && 'border-b-0',
					last && !selected && 'border-t-0',
					!preview.loaded ? 'animate-pulse bg-primary-200' : ''
				)}
				key={layer.id}
				style={style}
				onClick={() => {
					if (selected) return;
					onClick && onClick(layer.id);
				}}
			>
				<div className="relative w-full h-full flex items-end text-xs relative text-center pointer-events-auto text-transparent">
					<h1 className="base-layer-item-title w-full flex items-center justify-center  pointer-events-auto py-0.5">
						{layer.name}
					</h1>
				</div>
			</div>
		</Transition>
	);
}
