import { useEffect } from 'react';
import { useFarmBlocks } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react/src/layers/vector/use-vector-layer';
import type { Style } from 'geostyler-style';
import type {
	default as OlStyleObject,
	GeometryFunction,
	StyleLike
} from 'ol/style/Style';
import type OlMap from 'ol/Map';
import type { FeatureLike } from 'ol/Feature';
import { MultiPolygon as OlMultiPolygon } from 'ol/geom';
import { fromExtent } from 'ol/geom/Polygon';
import { useBlockConfig } from './boundary.config';
import { useFindLayer } from '../helpers';
import { useParseGeostyler } from '../use-parse-geostyler';

export type BoundaryOptions = {
	farmSelected?: boolean;
	measuringSystem?: 'metric' | 'imperial';
	visible?: boolean;
	showLabels?: boolean;
	forceLabels?: boolean;
	showAreas?: boolean;
	color?: string;
	zIndex?: number;
	// sometimes other tools may want to use the same layer but a different instance, this can cause issues with the layer unmounting and remounting and thus visibility
	version?: string;
};

export function useFarmBlocksLayer(opts: BoundaryOptions & { id: number }) {
	const { id } = opts;
	const query = useFarmBlocks(id);

	const config = useBlockConfig(opts);

	const styleQuery = useParseGeostyler(config.style);

	const initialLayer = useFindLayer((l) => {
		if (opts?.version) {
			return (
				l.get('farmId') === id &&
				l.get('type') === 'blocks' &&
				l.get('version') === opts.version
			);
		}
		return l.get('farmId') === id && l.get('type') === 'blocks';
	});

	const [layer, zoomTo, isOnMap] = useVectorLayer({
		initialLayer,
		data: query.data,
		style: styleQuery.data?.output,
		declutter: false,
		zIndex: opts?.zIndex,
		properties: {
			farmId: id,
			mfw_uuid: `farm-${id}-blocks`,
			type: 'blocks',
			version: opts?.version
		}
	});

	useEffect(() => {
		if (opts?.visible === false) {
			layer.setVisible(false);
		} else {
			layer.setVisible(true);
		}
	}, [opts?.visible, layer]);

	useEffect(() => {
		if (opts?.farmSelected) {
			zoomTo();
		}
	}, [opts?.farmSelected, zoomTo]);

	return [query, layer, zoomTo, isOnMap] as const;
}

export function multiPolygonSingleLabelStyle(
	geostylerStyle: Style,
	finalStyle: StyleLike,
	map: OlMap
): StyleLike {
	const renderLookup = new Map<string, number>();
	map.on('rendercomplete', function () {
		renderLookup.clear();
	});

	function setMultiPolygongLabelGeometry(
		style: OlStyleObject,
		feature: FeatureLike
	) {
		const text = style.getText();
		if (text) {
			const originalGeom = style.getGeometry();
			const geom = feature.getGeometry();
			style.setGeometry(function () {
				if (geom instanceof OlMultiPolygon) {
					const ext = geom.getExtent();
					const poly = fromExtent(ext);
					const center = poly.getInteriorPoint();
					const centerCoord = center.getCoordinates();
					const pixel = map.getPixelFromCoordinate(centerCoord);
					const features = map.getFeaturesAtPixel(pixel);
					const otherFeatures = features.filter(
						(f) => f.getGeometry() instanceof OlMultiPolygon
					);

					if (otherFeatures.length > 0) {
						const geom = style.getGeometry();
						if (typeof geom === 'function') {
							return geom(feature);
						} else {
							return geom;
						}
					}
					return center;
				} else {
					return originalGeom;
				}
			} as GeometryFunction);
		}
	}

	const fn = function (feature: FeatureLike, res: number) {
		const geom = feature.getGeometry();
		if (!(geom instanceof OlMultiPolygon)) {
			if (typeof finalStyle === 'function') {
				return finalStyle(feature, res);
			}
			return finalStyle;
		}
		if (typeof finalStyle === 'function') {
			const computedStyle = finalStyle(feature, res);
			if (Array.isArray(computedStyle)) {
				for (const style of computedStyle) {
					setMultiPolygongLabelGeometry(style, feature);
				}
			} else if (computedStyle) {
				setMultiPolygongLabelGeometry(computedStyle, feature);
			}
			return computedStyle;
		} else if (Array.isArray(finalStyle)) {
			for (const style of finalStyle) {
				setMultiPolygongLabelGeometry(style, feature);
			}
			return finalStyle;
		} else {
			setMultiPolygongLabelGeometry(finalStyle, feature);
			return finalStyle;
		}
	};
	fn.__geoStylerStyle = geostylerStyle;
	fn.__multiPolygon = true;
	return fn as StyleLike;
}
