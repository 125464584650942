import type { LayerOptions } from '../../layer-options';
import { useGeoJSONPointData } from '../../data';
import { useMyIrrigationStyle } from './myirrigation.style';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { useFindLayer } from '../../../helpers';
import { useLayerAvailableLayers } from '../../helpers';
import { useParseGeostyler } from '../../../use-parse-geostyler';
import { useMemo } from 'react';
import { useSyncProperties } from '../default';

export function useMyIrrigationLayer(options: LayerOptions) {
	const { data } = useGeoJSONPointData(options.data);

	const geostyler = useMyIrrigationStyle();

	const { data: availableLayers = [] } = useLayerAvailableLayers(options.data);

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === options.layer.uniqueId
	);
	const { data: parsedStyle } = useParseGeostyler(geostyler);

	const properties = useMemo(() => {
		return {
			mfw_uuid: options.layer.uniqueId,
			farmId: options.data.farmId,
			dates: options.data.dates,
			layerFilterId: options.data.layerFilterId,
			available_layer_id: availableLayers?.[0]?.id,
			geoStylerStyle: geostyler,
			product: 'myirrigation'
		};
	}, [
		availableLayers,
		geostyler,
		options.data.dates,
		options.data.farmId,
		options.data.layerFilterId,
		options.layer.uniqueId
	]);

	const [layer] = useVectorLayer({
		initialLayer,
		style: parsedStyle?.output,
		data,
		opacity: options.render.opacity,
		zIndex: options.render.zIndex ? options.render.zIndex + 100 : 250,
		declutter: options.render.declutter,
		properties: {
			mfw_uuid: options.layer.uniqueId,
			farmId: options.data.farmId,
			dates: options.data.dates,
			available_layer_id: availableLayers[0]?.id
		}
	});
	useSyncProperties(layer, properties);
	return layer;
}
