export const LAYERS_WITHOUT_POLY_LABELS = [
	'soil_form',
	'structure_of_topsoil',
	'structure_of_subsoil',
	'texture_class',
	'tekstuur_1',
	'Tekstuur_1',
	'tekstuur_2',
	'textural_layering',
	'beraamde_i',
	'carbonate_in_topsoil',
	'carbonate_in_subsoil',
	'limiting_layer_type',
	'risk_for_root_diseases',
	'denitrification_leaching_zones',
	'mica_yn',
	'spoelklip',
	'struktuu_2',
	'contours'
];

export const SOIL_CLASSIFICATION_UNIQUE_IDS_WITH_TRIMMED_CLASSES = [
	'soil_form(0)',
	'Sones(0)',
	'texture_class(0)',
	'teksuur_1(0)',
	'textural_layering(0)',
	'limiting_layer_type(0)',
	'coarse_fragments_composition_topsoil(0)',
	'tekstuur_2'
];

export const POINTS_WITH_LAB_NUMBER_LABELS = [
	'itest_soil_mikro(0)',
	'itest_soil_ondergrond(0)',
	'itest_soil_chemie(0)'
];

export const SOIL_CLASSIFICATION_WITH_LABELS_FROM_API = [
	'soil_form',
	'depth_for_potential_root_development',
	'depth_of_transitional_horizons_ab(0)',
	'depth_of_transitional_horizons_bc',
	'clay_perc_a_horizon(0)',
	'clay_perc_b_horizon(0)',
	'klei___c_h(0)',
	'volume_perc_coarse_fragments_a_horizon',
	'volume_perc_coarse_fragments_b_horizon',
	'pawc_300_600',
	'pawc_0_300',
	'pawc_effective_depth',
	'volume___2(0)',
	'oorgangs_2(0)'
];

export const SOIL_CLASS_PROBE_LAYER = 'probe(0)';
