import type { FeatureCollection } from 'geojson';

export function mergeFeatureCollections<T extends FeatureCollection>(
	...args: T[]
): T {
	if (args.length === 1) return args[0];
	const features = args.reduce(
		(acc, cur) => {
			if (cur.features) {
				acc.push(...cur.features);
			}
			return acc;
		},
		[] as T['features']
	);

	return {
		type: 'FeatureCollection',
		features
	} as T;
}
