import { useCallback, useEffect, useState } from 'react';
import {
	farmBoundaryGeojsonQueryFN,
	farmBoundaryQueryKey,
	useApi
} from '@agritechnovation/api-query';
import { useQueries } from '@tanstack/react-query';
import type { FeatureCollection } from 'geojson';
import { transformExtent } from 'ol/proj';
import { useMap, useMapProjection } from '@agritechnovation/ol-react';
import bbox from '@turf/bbox';
import { isEmpty } from 'ol/extent';

export function useZoomToMultipleFarmBoundaries(
	farms: number[],
	autoZoom = true
) {
	const [hasZoomed, setHasZoomed] = useState({
		farms: [] as unknown[]
	});

	useEffect(() => {
		setHasZoomed({
			farms: []
		});
	}, [farms]);

	const api = useApi();

	// beware if projection were to ever change the combine function would not actually update
	const projection = useMapProjection();

	const { geojson, extent, isPending, isError } = useQueries({
		queries: farms.map((farm) => ({
			queryKey: farmBoundaryQueryKey(farm),
			queryFn: farmBoundaryGeojsonQueryFN(api, farm),
			enabled: farms.length > 0
		})),
		combine: (results) => {
			const geojson: FeatureCollection = {
				type: 'FeatureCollection',
				features: []
			};
			const isPending = results.some((result) => result.isPending);
			const isError = results.some((result) => result.isError);
			if (isPending)
				return {
					isPending,
					geojson,
					extent: [Infinity, Infinity, Infinity, Infinity]
				};
			for (const result of results) {
				if (result.data) {
					geojson.features.push(...result.data.features);
				}
			}
			const [minx, miny, maxx, maxy] = bbox(geojson);
			const extent = transformExtent(
				[minx, miny, maxx, maxy],
				'EPSG:4326',
				projection
			);
			return { isPending, geojson, extent, isError };
		}
	});

	const map = useMap();

	const zoom = useCallback(() => {
		if (!isEmpty(extent)) {
			map.getView().fit(extent);
		}
	}, [extent, map]);

	useEffect(() => {
		if (!autoZoom) return;
		if (geojson.features.length) {
			// prevent zooming from happening every time state changes
			if (geojson.features.length !== hasZoomed.farms.length) {
				if (!isEmpty(extent)) {
					map.getView().fit(extent);
					setHasZoomed({
						farms: geojson.features.map((f) => f.properties?.id)
					});
				}
			}
		}
	}, [
		autoZoom,
		extent,
		geojson.features,
		geojson.features.length,
		hasZoomed.farms.length,
		map
	]);

	return [zoom, isPending, isError] as const;
}
