import type { CSSProperties } from 'react';
import { useEffect } from 'react';
import { useMap, useMapRef } from './map.context';

export type MapComponentProps = {
	style?: CSSProperties;
	[key: `data-${string}`]: string;
};
export const MapComponent = ({ style, ...props }: MapComponentProps) => {
	const ref = useMapRef();
	const map = useMap();

	useEffect(() => {
		if (ref.current) {
			map.setTarget(ref.current);
		}
	}, [map, ref]);

	useEffect(() => {
		const target = map.getTarget();
		if (target instanceof HTMLElement) {
			const canvasElements = target.getElementsByTagName('canvas');
			for (const canvas of canvasElements) {
				for (const [key, value] of Object.entries(props)) {
					canvas.setAttribute(key, value);
				}
			}
		}
	}, [map, props]);

	return (
		<div
			ref={ref}
			style={{
				position: 'relative',
				zIndex: 0,
				width: '100%',
				height: '100%',
				...(style ? style : {})
			}}
			{...props}
		/>
	);
};
