type CookieOpts = {
	days?: number;
	path: string;
	['max-age']?: number;
};

export const Cookie = {
	get: (name: string) => {
		const c = document?.cookie?.match(
			`(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`
		)?.[1];
		if (c) return decodeURIComponent(c);
	},
	set: (name: string, value: string, opts: CookieOpts) => {
		/*If options contains days then we're configuring max-age*/
		if (opts.days) {
			opts['max-age'] = opts.days * 60 * 60 * 24;

			/*Deleting days from options to pass remaining opts to cookie settings*/
			delete opts.days;
		}

		/*Configuring options to cookie standard by reducing each property*/
		const cookie = Object.entries(opts).reduce(
			(accumulatedStr, [k, v]) => `${accumulatedStr}; ${k}=${v}`,
			''
		);

		/*Finally, creating the key*/
		document.cookie = name + '=' + encodeURIComponent(value) + cookie;
	},
	delete: (name: string, opts?: CookieOpts) =>
		Cookie.set(name, '', opts ? { ...opts } : { 'max-age': -1, path: '/' })
	// path & domain must match cookie being deleted
};
