import { useGeoJSONPolygonData } from '../../../data';
import type { MySoilClassificationOptions } from '../my-soil-classification-layer';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { useFindLayer } from '../../../../helpers';
import type { Style } from 'geostyler-style';
import { useParseGeostyler } from '../../../../use-parse-geostyler';

export const CONTOUR_STYLE: Style = {
	name: 'Default Contour Style',
	rules: [
		{
			name: 'Major (10m)',
			filter: ['==', 'isMajor10', true],
			symbolizers: [
				{
					kind: 'Line',
					opacity: 1,
					color: '#ffcc00',
					width: 3
				},
				{
					kind: 'Text',
					label: '{{elev}}',
					size: 12,
					font: ['Arial'],
					offset: [0, -15],
					color: '#ffcc00',
					haloColor: '#000000',
					haloWidth: 5,
					haloOpacity: 1,
					opacity: 1,
					maxAngle: 0,
					allowOverlap: true,
					avoidEdges: false,
					anchor: 'center'
				}
			]
		},
		{
			name: 'Minor',
			filter: ['==', 'isMajor10', false],
			symbolizers: [
				{
					kind: 'Text',
					label: '{{elev}}',
					size: 12,
					font: ['Arial'],
					offset: [0, -15],
					color: '#ffcc00',
					haloColor: '#000000',
					haloWidth: 5,
					haloOpacity: 1,
					opacity: 1,
					maxAngle: 0,
					allowOverlap: true,
					avoidEdges: false,
					anchor: 'center'
				},
				{
					kind: 'Line',
					opacity: 1,
					color: '#ffcc00',
					width: 1
				}
			]
		}
	],
	metadata: {
		classified: true,
		isLine: true
	}
} as Style;

export function useSoilClassificationContours(
	opts: MySoilClassificationOptions
) {
	const { data, error, isError } = useGeoJSONPolygonData(opts.data);

	if (isError) {
		throw error;
	}
	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	const { data: parsedStyle } = useParseGeostyler(CONTOUR_STYLE);

	const [layer] = useVectorLayer({
		initialLayer,
		data,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex,
		style: parsedStyle?.output,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId
		}
	});
	return layer;
}
