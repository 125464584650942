import { useLayerFilter } from '@agritechnovation/api-query';
import { isPointLayer } from '../../helpers';
import { useDefaultPointLayer } from '../default/use-default-point-layer';
import { usePpmLayer, type PpmLayerOptions } from './use-ppm-layer';

export function PPMLayer(opts: PpmLayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	if (!layerFilter) return null;
	if (isPointLayer(layerFilter)) {
		return <PPMPointLayer {...opts} />;
	}
	return <PPMPolygonLayer {...opts} />;
}

function PPMPolygonLayer(opts: PpmLayerOptions) {
	usePpmLayer(opts);
	return null;
}

function PPMPointLayer(opts: PpmLayerOptions) {
	useDefaultPointLayer(opts);
	return null;
}
