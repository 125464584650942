// returns the date or date range for the layer for display purposes
import { useMemo } from 'react';
import type { LayerAvailableLayersOptions } from './layers';
import { useLayerAvailableLayers } from './layers';

export const useMapLayerDate = (opts: LayerAvailableLayersOptions) => {
	const { data: observations } = useLayerAvailableLayers(opts);
	return useMemo(() => {
		if (opts.dateBucket) {
			return observations?.at(0)?.date_bucket;
		}
		if (observations?.length === 1) {
			return observations[0].date;
		}
		if (observations && observations.length > 1) {
			const first = observations.at(0);
			const last = observations.at(1);
			if (first && last && first.date !== last.date) {
				return `${first.date} - ${last.date}`;
			}
			if (first) {
				return first.date;
			}
			return undefined;
		}
		return undefined;
	}, [opts.dateBucket, observations]);
};
