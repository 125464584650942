import { useLayerFilter } from '@agritechnovation/api-query';
import type { LayerOptions } from '../../../layer-options';
import { DefaultLayer } from '../../default/default.layer';
import { isPointLayer } from '../../../helpers/layer-type';
import { ITESTSoilPointLayer } from './itestsoil-point-layer';

export type ITESTSoilLayerOptions = LayerOptions;

export function ITESTSoilLayer(opts: ITESTSoilLayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	if (!layerFilter) return null;
	if (layerFilter.layer_type === 'geojson') {
		return <DefaultLayer {...opts} />;
	}
	if (isPointLayer(layerFilter)) {
		return <ITESTSoilPointLayer {...opts} />;
	}
}
