import type { MapBrowserEvent, Overlay } from 'ol';
import { useCallback, useEffect, useRef } from 'react';
import { useMap } from '../map';

export function useTooltipOverlayPositioning(overlay?: Overlay) {
	const ref = useRef<Overlay>(overlay ?? null);
	const onMouseOut = useCallback(() => {
		if (ref.current) {
			ref.current.setPosition(undefined);
		}
	}, []);

	const onPointerMove = useCallback((e: MapBrowserEvent<UIEvent>) => {
		if (ref.current) {
			ref.current.setPosition(e.coordinate);
		}
	}, []);

	const map = useMap();
	useEffect(() => {
		map.getViewport().addEventListener('mouseout', onMouseOut);
		map.on('pointermove', onPointerMove);
		return () => {
			map.getViewport().removeEventListener('mouseout', onMouseOut);
			map.un('pointermove', onPointerMove);
		};
	}, [map, onMouseOut, onPointerMove]);

	return ref;
}
