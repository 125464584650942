import { useLayerFilter } from '@agritechnovation/api-query';
import type { LayerOptions } from '../../layer-options';
import { MySoilClassificationPolygonLayer } from './polygon';
import { MySoilClassificationPointLayer } from './point';
import { isPointLayer } from '../../helpers';
import { MySoilClassificationContoursLayer } from './contours';
import { MySoilClassificationHoverOverlay } from './polygon/mysoilclassification-hover-overlay';
import { MySoilClassificationLineLayer } from './line/soil-classification-lines-layer';
import { DefaultLineLayer } from '../default/default.line-layer';

export type MySoilClassificationOptions = LayerOptions & {
	soilClass: {
		showPointLabels?: boolean;
	};
};

export function MySoilClassificationLayer(opts: MySoilClassificationOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	if (!layerFilter) return null;
	if (
		layerFilter.layer_type === 'Line' ||
		['contours(0)', 'line_name(0)'].includes(layerFilter.unique_id)
	) {
		if (layerFilter.unique_id === 'contours(0)') {
			return <MySoilClassificationContoursLayer {...opts} />;
		}
		if (layerFilter.unique_id === 'line_name(0)') {
			return <MySoilClassificationLineLayer {...opts} />;
		}
		return <DefaultLineLayer {...opts} />;
	}
	if (layerFilter.layer_type === 'geojson') {
		return (
			<>
				<MySoilClassificationPolygonLayer {...opts} />
				<MySoilClassificationHoverOverlay
					farmId={opts.data.farmId}
					uuid={opts.layer.uniqueId}
					zIndex={opts.render.zIndex}
				/>
			</>
		);
	}
	if (isPointLayer(layerFilter)) {
		return <MySoilClassificationPointLayer {...opts} />;
	}
	return null;
}
