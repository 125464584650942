import { useEffect, useState } from 'react';
import { useMap } from '../map';

export function useMapZoom() {
	const map = useMap();
	const [zoom, setZoom] = useState(() => map.getView()?.getZoom());
	useEffect(() => {
		const view = map.getView();
		if (!view) return;
		view.on('change:resolution', () => {
			const zoom = view.getZoom();
			setZoom(zoom);
		});
	}, [map]);
	return zoom;
}
