import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import type Map from 'ol/Map';
import { MapContext } from './map.context';

export const MapContextProvider = ({
	map,
	children
}: PropsWithChildren<{ map: Map }>) => {
	const ref = useRef<HTMLDivElement>(null);
	return (
		<MapContext.Provider value={{ map, ref }}>{children}</MapContext.Provider>
	);
};
