import { useMap } from '@agritechnovation/ol-react';
import { useEffect } from 'react';
import type { MapBaseLayer } from './base-layers';
import { getBaseLayer } from './base-layers';

export function useBaseMapLayer(l: MapBaseLayer) {
	const map = useMap();
	useEffect(() => {
		if (!l) return;
		const otherBaseLayers = map
			.getLayers()
			.getArray()
			.filter((l) => l.get('type') === 'base' && l.get('uniqueId') !== l);

		otherBaseLayers.forEach((l) => map.removeLayer(l));

		const layer = getBaseLayer(l);
		map.addLayer(layer);
		return () => {
			map.removeLayer(layer);
		};
	}, [l, map]);
	return l;
}
