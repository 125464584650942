import { useLayerFilter } from '@agritechnovation/api-query';
import { useMemo } from 'react';
import { useVectorLayer } from '@agritechnovation/ol-react';
import type { ITESTSoilLayerOptions } from './itest-soil-layer';
import { useGeoJSONPointData, useGeoJSONPointStyle } from '../../../data';
import { useLayerAvailableLayers } from '../../../helpers';
import { useParseGeostyler } from '../../../../use-parse-geostyler';
import { useFindLayer } from '../../../../helpers';
import { useSyncProperties } from '../../default';

const POINTS_WITH_LAB_NUMBER_LABELS = [
	'itest_soil_mikro(0)',
	'itest_soil_ondergrond(0)',
	'itest_soil_chemie(0)'
];

export function ITESTSoilPointLayer(opts: ITESTSoilLayerOptions) {
	const { data, isError, error } = useGeoJSONPointData(opts.data);

	if (isError) {
		throw error;
	}

	if (data && data.features.length === 0) {
		throw new Error('Layer has no features');
	}

	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);

	const {
		data: availableLayers = [],
		isError: isAlError,
		error: AlError
	} = useLayerAvailableLayers({
		farmId: opts.data.farmId,
		layerFilterId: opts.data.layerFilterId,
		dates: opts.data.dates,
		dateBucket: opts.data.dateBucket,
		depth: opts.data.depth
	});

	if (isAlError) {
		throw AlError;
	}

	const displayKey = useMemo(() => {
		if (opts.style?.displayKey) return opts.style.displayKey;
		if (
			layerFilter &&
			POINTS_WITH_LAB_NUMBER_LABELS.includes(layerFilter.unique_id)
		) {
			return {
				key: 'sample_id'
			};
		}
	}, [layerFilter, opts.style?.displayKey]);

	const style = useGeoJSONPointStyle({
		...opts.data,
		displayKey,
		iconURLCreator: opts.style?.iconURLCreator
	});

	const {
		data: parsedStyle,
		isError: isStyleError,
		error: styleError
	} = useParseGeostyler(style.style);
	if (isStyleError) {
		throw styleError;
	}

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	const [layer] = useVectorLayer({
		initialLayer,
		declutter: !!opts.render.declutter,
		zIndex: opts.render.zIndex ? opts.render.zIndex + 100 : 250,
		opacity: opts.render.opacity,
		data,
		style: parsedStyle?.output
	});

	const properties = useMemo(
		() => ({
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId,
			available_layer_id: availableLayers?.[0]?.id
		}),
		[
			availableLayers,
			opts.data.dates,
			opts.data.farmId,
			opts.data.layerFilterId,
			opts.layer.uniqueId
		]
	);

	useSyncProperties(layer, properties);

	return null;
}
