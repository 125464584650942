import type { Rule, Symbolizer, TextSymbolizer } from 'geostyler-style';
import type { DisplayKey } from '../../../data';
import { useParseGeostyler } from '../../../../use-parse-geostyler';

export function useITESTFilterGroupStyle(
	icon: string,
	displayKey?: DisplayKey
) {
	const style = createIconStyle({ icon, displayKey });
	const { data: parsed } = useParseGeostyler(style);
	return parsed?.output;
}

function createIconStyle(options: { icon: string; displayKey?: DisplayKey }) {
	const iconSymbolizers: Symbolizer[] = [
		{
			kind: 'Icon',
			image: options.icon,
			size: 38,
			offset: [0, 19]
		}
	];

	const rules = [
		{
			name: `Default Point MicroLife`,
			symbolizers: iconSymbolizers
		}
	] satisfies Rule[];
	if (options.displayKey) {
		if (!options.displayKey.rules) {
			iconSymbolizers.unshift(
				displayKeySymbolizer(options.displayKey.key, options.displayKey.color)
			);
		} else {
			rules.push(...options.displayKey.rules);
		}
	}
	return {
		name: `Point with icon for MicroLife and ${options.displayKey?.key}`,
		rules,
		metadata: {
			classified: false
		}
	};
}

function displayKeySymbolizer(
	displayKey: string,
	color?: string
): TextSymbolizer {
	return {
		kind: 'Text',
		label: `{{${displayKey}}}`,
		fontWeight: 'bold',
		size: 12,
		font: ['Arial'],
		offset: [0, -38],
		color: color ?? '#ffffff',
		haloColor: '#333333',
		haloWidth: 10,
		haloOpacity: 1,
		opacity: 1,
		maxAngle: 0,
		allowOverlap: true,
		avoidEdges: false,
		anchor: 'center'
	};
}
