import type { Rule } from 'geostyler-style';
import { imgSrc } from '@agritechnovation/utils';

const colors: string[] = ['red', 'blue', 'green', 'gray']; // List of available colors
const iconSize = 32; // Adjust the size as needed
const iconOpacity = 1; // Adjust the opacity as needed

// Function to generate all permutations of an array
function getPermutations<T>(array: T[]): T[][] {
	const permutations: T[][] = [];

	function permute(arr: T[], m: T[] = []) {
		if (arr.length === 0) {
			permutations.push(m);
		} else {
			for (let i = 0; i < arr.length; i++) {
				const curr = arr.slice();
				const next = curr.splice(i, 1);
				permute(curr.slice(), m.concat(next));
			}
		}
	}

	permute(array);
	for (const col of array) {
		permutations.push([col, col, col]);
	}
	return permutations;
}

// Generate all permutations of colors
const threeColorPermutations: string[][] = getPermutations(colors);

// Create GeoStyler rules for each color permutation
export const MY_IRRIGATION_ICON_RULES_THREE_COLOR: Rule[] =
	threeColorPermutations.map((colorCombination) => {
		const rule: Rule = {
			name: `Color Combination: ${colorCombination.join(', ')}`,
			filter: [
				'||',
				[
					'&&',
					['==', 'color_top', colorCombination[0]],
					['==', 'color_middle', colorCombination[1]],
					['==', 'color_bottom', colorCombination[2]]
				]
			],
			symbolizers: [
				{
					kind: 'Icon',
					image: imgSrc(
						import.meta.env.BASE_URL,
						`/pin-icons/${colorCombination.join('-')}.png`
					).toLowerCase(), // Create a filename based on the permutation
					size: iconSize,
					opacity: iconOpacity
				}
			]
		};
		return rule;
	});
export const MY_IRRIGATION_ICON_RULES_TWO_COLOR: Rule[] =
	threeColorPermutations.map((colorCombination) => {
		const rule: Rule = {
			name: `Color Combination: ${colorCombination.slice(0, 2).join(', ')}`,
			filter: [
				'&&',
				['==', 'color_top', colorCombination[0].toLowerCase()],
				['==', 'color_bottom', colorCombination[1].toLowerCase()]
			],
			symbolizers: [
				{
					kind: 'Icon',
					image: imgSrc(
						import.meta.env.BASE_URL,
						`/pin-icons/${colorCombination.slice(0, 2).join('-')}.png`
					).toLowerCase(), // Create a filename based on the permutation
					size: iconSize,
					opacity: iconOpacity
				}
			]
		};
		return rule;
	});

export const MY_IRRIGATION_ICON_RULES =
	MY_IRRIGATION_ICON_RULES_TWO_COLOR.concat(
		MY_IRRIGATION_ICON_RULES_THREE_COLOR
	);

export function useMyIrrigationStyle() {
	return {
		name: 'My Irrigation',
		rules: MY_IRRIGATION_ICON_RULES,
		metadata: {
			classified: true
		}
	};
}
