import type { LayerOptions } from '../../layer-options';
import type { GeoTIFFStorage } from '../../data/geotiff/use-geotiff-data';
import { useGeoTIFFData } from '../../data/geotiff/use-geotiff-data';
import { toOL, useGeoTIFFStyle } from '../../data/geotiff/use-geotiff-style';
import { useFindLayer } from '../../../helpers';
import { useTileLayer, type CustomTileLayer } from '@agritechnovation/ol-react';
import { useLayerFilter } from '@agritechnovation/api-query';
import { useSyncProperties } from './use-sync-properties';
import { useMemo } from 'react';
import { useLayerAvailableLayers } from '../../helpers';

export type DefaultGeoTIFFLayerOptions = LayerOptions & {
	tiffStorage?: GeoTIFFStorage;
};
export function useDefaultGeoTIFFLayer(opts: DefaultGeoTIFFLayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	const { data: availableLayers = [] } = useLayerAvailableLayers(opts.data);
	const isRGB = layerFilter?.unique_id.includes('fieldimage');

	const {
		data,
		isError: isDataError,
		error: dataError
	} = useGeoTIFFData({
		...opts.data,
		isRGB,
		tiffStorage: opts.tiffStorage
	});

	if (isDataError) {
		throw dataError || new Error('Error loading tiff data');
	}

	const {
		data: style,
		isError: isStyleError,
		error: styleError
	} = useGeoTIFFStyle({
		...opts,
		customLegend: opts.style?.customLegend
	});

	if (isStyleError) {
		throw styleError || new Error('Error loading tiff style');
	}

	const initialLayer = useFindLayer<CustomTileLayer>(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	const properties = useMemo(
		() => ({
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			layerFilterId: opts.data.layerFilterId,
			dates: opts.data.dates,
			available_layer_id: availableLayers?.[0]?.id,
			geoStylerStyle: style?.style,
			unit: style?.unit,
			type: 'cog'
		}),
		[
			style?.unit,
			availableLayers,
			opts.data.dates,
			opts.data.farmId,
			opts.data.layerFilterId,
			opts.layer.uniqueId,
			style?.style
		]
	);

	const olStyle =
		layerFilter && style?.merged ? toOL(layerFilter, style.merged) : undefined;

	const [layer] = useTileLayer({
		initialLayer,
		data,
		style: olStyle,
		opacity: opts.render.opacity,
		zIndex: opts.render.zIndex,
		isRGB
	});

	useSyncProperties(layer, properties);
}
