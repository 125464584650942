import { useApi, useFarm, useLayerFilter } from '@agritechnovation/api-query';
import {
	compareMFWDates,
	dateBucketToRange,
	isDateBucketString,
	toYYYYMMDD
} from '@agritechnovation/utils';
import { queryOptions, useQuery } from '@tanstack/react-query';
import type { LayerDataOptions } from '../layer-data-options';
import { useLayerAvailableLayers } from '../../helpers';
import type {
	Api,
	AvailableLayerObservationWithFarmId,
	Farm,
	LayerFilter
} from '@agritechnovation/api';

export type GeoJSONPointDataOptionsArgs = {
	availableLayers: AvailableLayerObservationWithFarmId[];
	layerFilter?: LayerFilter;
	farm?: Farm;
	dateBucket: boolean;
	depth?: string | null;
};
export function createGeoJSONPointDataOptions(
	options: GeoJSONPointDataOptionsArgs
) {
	const geom = options.layerFilter?.layer_type;
	const allowedGeoms = ['point', 'pdf_point'];

	// if (geom && !allowedGeoms.includes(geom)) {
	// 	throw new Error(
	// 		`Cannot use ${geom} layer filter with polygon/geojson data`
	// 	);
	// }
	//
	const sortedAls = options.availableLayers.sort((a, b) =>
		compareMFWDates(a.date, b.date)
	);

	const projectName = options.farm?.project_name;
	const layerName = options.layerFilter?.layer_name;

	const availableLayerID = options.availableLayers?.[0]?.id;

	let from = options.availableLayers[0]?.date;
	let to = options.availableLayers.at(-1)?.date;

	if (options.availableLayers.length > 0) {
		if (options.dateBucket && isDateBucketString(sortedAls[0].date_bucket)) {
			const range = dateBucketToRange(sortedAls[0].date_bucket);
			from = toYYYYMMDD(range.from);
			to = toYYYYMMDD(range.to);
		} else {
			if (!from && to) {
				from = to;
			}
			if (!to) {
				to = from;
			}
		}
	}
	const depth = options.depth;
	return {
		projectName,
		layerName,
		availableLayerID,
		from,
		to,
		allowedGeoms,
		geom,
		depth
	};
}

export function useGeoJSONPointData({
	layerFilterId,
	farmId,
	dates,
	dateBucket,
	depth
}: LayerDataOptions) {
	const { data: layerFilter } = useLayerFilter(layerFilterId);
	const { data: farm } = useFarm(farmId);
	const { data: availableLayers = [] } = useLayerAvailableLayers({
		farmId,
		layerFilterId,
		dates,
		dateBucket,
		depth
	});

	const api = useApi();

	const options = createGeoJSONPointDataQueryOptions(
		api,
		{ farmId, layerFilterId, dates, dateBucket, depth },
		{
			availableLayers,
			layerFilter,
			farm,
			dateBucket
		}
	);
	return useQuery(options);
}

export function createGeoJSONPointDataQueryKey(
	dataOptions: LayerDataOptions,
	options: GeoJSONPointDataOptionsArgs
) {
	const { layerFilterId, dateBucket } = dataOptions;
	const { farm } = options;
	const { layerName, projectName, from, to, availableLayerID, depth } =
		createGeoJSONPointDataOptions(options);
	return [
		'farm',
		farm?.id,
		'layer-filter',
		layerFilterId,
		'geojson',
		'point',
		dateBucket,
		layerName,
		projectName,
		availableLayerID,
		from,
		to,
		depth
	];
}

export function createGeoJSONPointDataQueryOptions(
	api: Api,
	dataOptions: LayerDataOptions,
	options: GeoJSONPointDataOptionsArgs
) {
	const { farmId, layerFilterId, dateBucket, dates } = dataOptions;
	const { layerFilter } = options;
	const {
		layerName,
		projectName,
		from,
		to,
		availableLayerID,
		allowedGeoms,
		geom,
		depth
	} = createGeoJSONPointDataOptions({
		...options,
		depth: dataOptions.depth || options.depth || undefined
	});
	return queryOptions({
		queryKey: [
			'farm',
			farmId,
			'layer-filter',
			layerFilterId,
			'geojson',
			'point',
			dateBucket,
			layerName,
			projectName,
			availableLayerID,
			from,
			to,
			depth
		],
		queryFn: async ({ signal }) => {
			if (!projectName || !layerName || !availableLayerID)
				throw new Error(
					'Missing project name, layer name, or available layer id'
				);
			return api.point.get({
				projectName,
				layerFilter: layerName,
				id: availableLayerID,
				dateFrom: from,
				dateTo: to as string,
				cfg: { abort: signal },
				itest_soil_depth_value: depth ?? undefined
			});
		},
		enabled:
			options.availableLayers.length > 0 &&
			dates.length > 0 &&
			!!layerFilter &&
			allowedGeoms.includes(geom as string) &&
			!!projectName &&
			!!layerName &&
			!!from &&
			!!to
	});
}
