import type { Style } from 'geostyler-style';

export const MYSOILCLASSIFICATION_PROBE_STYLE = {
	name: '',
	rules: [
		{
			name: 'My Soil Classification Probe',
			symbolizers: [
				{
					kind: 'Mark',
					wellKnownName: 'circle',
					color: '#ff0000',
					strokeColor: '#000000',
					radius: 3
				},
				{
					kind: 'Text',
					label: '(X) {{x}}',
					offset: [0, -27],
					color: '#ffffff',
					haloWidth: 2,
					font: ['Arial'],
					fontWeight: 'bold',
					size: 10
				},
				{
					kind: 'Text',
					label: '(Y) {{y}}',
					color: '#ffffff',
					offset: [0, -13],
					haloWidth: 2,
					font: ['Arial'],
					fontWeight: 'bold',
					size: 10
				}
			]
		}
	],
	metadata: {
		classified: false
	}
} as Style;
