import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from '../map';
import type BaseLayer from 'ol/layer/Base';
import type VectorLayer from 'ol/layer/Vector';
import type { Feature } from 'ol';

export const useOlLayers = () => {
	const map = useMap();

	const [olLayers, setOlLayers] = useState<BaseLayer[]>(() => {
		return [...map.getLayers().getArray()];
	});

	const handler = useCallback(() => {
		setTimeout(() => {
			setOlLayers([...map.getLayers().getArray()]);
		}, 100);
	}, [map]);

	const groupHandler = useCallback(() => {
		setTimeout(() => {
			setOlLayers([...map.getLayers().getArray()]);
		}, 100);
	}, [map]);

	useEffect(() => {
		for (const l of olLayers) {
			l.on('propertychange', handler);
		}
		return () => {
			for (const l of olLayers) {
				l.un('propertychange', handler);
			}
		};
	}, [handler, olLayers]);

	useEffect(() => {
		const layerGroup = map.getLayerGroup();
		layerGroup.on('change', groupHandler);
		return () => {
			layerGroup.un('change', groupHandler);
		};
	}, [groupHandler, map]);

	return olLayers;
};

export type FindOlLayerPredicate = (layer: BaseLayer) => boolean;
export const useFindOlLayer = <T = VectorLayer<Feature>>(
	predicate: FindOlLayerPredicate
): T | undefined => {
	const layers = useOlLayers();
	return useMemo(
		() => layers.find(predicate) as T | undefined,
		[layers, predicate]
	);
};

export const useObserveOlLayer = <T = VectorLayer<Feature>>(
	inputLayer: T | undefined
) => {
	const [layer, setLayer] = useState<T | undefined>(() => inputLayer);
	const [nUpdate, setNUpdate] = useState(0);

	const handleChange = useCallback(() => {
		if (!inputLayer) return;
		setLayer(inputLayer);
		setNUpdate((n) => n + 1);
	}, [inputLayer]);

	useEffect(() => {
		(layer as VectorLayer<Feature>)?.on('propertychange', handleChange);
		return () => {
			(layer as VectorLayer<Feature>)?.un('propertychange', handleChange);
		};
	}, [handleChange, layer]);

	return [layer, nUpdate] as const;
};
