import { createPortal } from 'react-dom';
import type { ForwardedRef, PropsWithChildren } from 'react';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState
} from 'react';
import { useMap } from '../map';
import { twMerge } from 'tailwind-merge';
import type { Positioning } from 'ol/Overlay';
import OlOverlay from 'ol/Overlay';
import type { Coordinate } from 'ol/coordinate';
import type { MapBrowserEvent } from 'ol';
import { useVectorLayer } from '../layers/vector/use-vector-layer';
import type { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { toLonLat } from 'ol/proj';

const MOVEABLE_LINE_STYLE = {
	'circle-radius': 2,
	'circle-fill-color': '#01FF00',
	'circle-stroke-color': '#01FF00',
	'stroke-width': 1,
	'stroke-color': '#01FF00'
};

export const Overlay = forwardRef(function Overlay(
	{
		children,
		position,
		style,
		positioning,
		show,
		offset,
		className,
		overlayClassName,
		moveable = false,
		disableAutoPan = false
	}: PropsWithChildren<{
		moveable?: boolean;
		style?: {
			width?: string | number;
			height?: string | number;
		};
		position?: Coordinate;
		positioning?: Positioning;
		offset?: [number, number];
		show: boolean;
		className?: string;
		stopEvent?: boolean;
		insertFirst?: boolean;
		overlayClassName?: string;
		disableAutoPan?: boolean;
	}>,
	ref: ForwardedRef<OlOverlay>
) {
	const map = useMap();
	const [overlay] = useState<OlOverlay>(() => {
		const element = document.createElement('div');
		element.className = twMerge(className);
		element.style.willChange = 'transform';
		return new OlOverlay({
			element,
			className: overlayClassName ?? 'pop-up',
			positioning: positioning ?? 'bottom-center',
			offset: offset ?? [0, 0],
			autoPan: disableAutoPan
				? false
				: {
						margin: 50,
						animation: {
							duration: 250
						}
					}
		});
	});

	// setting overlay props imperatively is much more performant than using useEffect and react props
	useImperativeHandle(ref, () => overlay, [overlay]);

	useEffect(() => {
		const el = overlay.getElement();
		if (!el) return;
		el.className = twMerge(el.className, className);
	}, [overlay, className]);

	const data = useMemo(() => {
		if (!position) return undefined;
		return {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: position ? toLonLat(position) : [0, 0]
					},
					properties: {}
				}
			]
		} satisfies GeoJSONFeatureCollection;
	}, [position]);
	// original position point
	const [originalPointPosition] = useVectorLayer({
		data: moveable ? data : undefined,
		style: MOVEABLE_LINE_STYLE,
		zIndex: 1000
	});

	// line from original position to new position
	const [lineData, setLineData] = useState<GeoJSONFeatureCollection>();

	useEffect(() => {
		overlay.on('change:position', () => {
			if (!position) return;
			const markerpos = overlay.getPosition();
			if (!markerpos) {
				setLineData(undefined);
				return;
			}
			const data = {
				type: 'FeatureCollection',
				features: [
					{
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [toLonLat(position), toLonLat(markerpos)]
						},
						properties: {}
					}
				]
			} satisfies GeoJSONFeatureCollection;
			setLineData(data);
		});
	}, [overlay, position]);

	useVectorLayer({
		data: moveable ? lineData : undefined,
		style: MOVEABLE_LINE_STYLE,
		zIndex: 1000
	});

	useEffect(() => {
		const el = overlay.getElement();
		if (!el) return;
		let deltaX = 0,
			deltaY = 0;
		const getAdjustedCoords = (coordinate: Coordinate) => {
			return [coordinate[0] - deltaX, coordinate[1] - deltaY];
		};

		const onMouseDown = (evt: MouseEvent) => {
			if (evt.target instanceof HTMLElement) {
				if (evt.target.className.includes('react-resizable-handle')) return;
				if (evt.target instanceof HTMLInputElement) {
					if (evt.target.type === 'range') return;
				}
			}
			const markerpos = overlay.getPosition();
			if (!markerpos) return;
			const clickPixel = [evt.x, evt.y];
			const clickCoords = map.getCoordinateFromPixel(clickPixel);

			deltaX = clickCoords[0] - markerpos[0];
			deltaY = clickCoords[1] - markerpos[1];
			overlay.set('dragging', true);
		};
		const onPointerMove = (e: MapBrowserEvent<MouseEvent>) => {
			if (overlay.get('dragging')) {
				overlay.setPosition(getAdjustedCoords(e.coordinate));
			}
		};
		const onMouseUp = () => {
			if (overlay.get('dragging') === true) {
				// dragPan.setActive(true);
				overlay.set('dragging', false);
			}
		};
		el.addEventListener('mousedown', onMouseDown);
		map.on('pointermove', onPointerMove);
		el.addEventListener('mouseup', onMouseUp);
		return () => {
			el.removeEventListener('mousedown', onMouseDown);
			map.un('pointermove', onPointerMove);
			el.removeEventListener('mouseup', onMouseUp);
		};
	}, [overlay, map]);

	useEffect(() => {
		overlay.setOffset(offset ?? [0, 0]);
	}, [overlay, offset]);

	useEffect(() => {
		map.addOverlay(overlay);
		return () => {
			map.removeOverlay(overlay);
		};
	}, [show, map, overlay]);

	useEffect(() => {
		if (show && position) {
			overlay.setPosition(position);
			if (!map.getLayers().getArray().includes(originalPointPosition)) {
				map.addLayer(originalPointPosition);
			}
		} else {
			overlay.setPosition(undefined);
			map.removeLayer(originalPointPosition);
		}
	}, [show, position, overlay, map, originalPointPosition]);

	useEffect(() => {
		if (overlay) {
			const element = overlay.getElement();
			if (element) {
				if (style?.height && style?.width) {
					element.style.height = `${style.height}px`;
					element.style.width = `${style.width}px`;
				}
			}
		}
	}, [overlay, style]);
	useEffect(() => {
		overlay.setPositioning(positioning ?? 'bottom-center');
	}, [overlay, positioning]);

	if (!show) return null;
	if (!overlay) return null;
	const overlayElement = overlay.getElement();
	if (!overlayElement) return null;
	return createPortal(<>{children}</>, overlayElement);
});
