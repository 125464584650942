import Disposable from 'ol/Disposable';
import { Polygon } from 'ol/geom';
import type Map from 'ol/Map';
import type { Pixel } from 'ol/pixel';

export class CustomRenderBox extends Disposable {
	private geometry_: null | Polygon;
	private element_: HTMLDivElement;
	private map_: null | Map;
	private startPixel_: null | Pixel;
	private endPixel_: null | Pixel;

	constructor(className: string) {
		super();

		/**
		 * @type {import("../geom/Polygon.js").default}
		 * @private
		 */
		this.geometry_ = null;

		this.element_ = document.createElement('div');
		this.element_.style.position = 'absolute';
		this.element_.style.pointerEvents = 'auto';
		this.element_.className = 'ol-box ' + className;

		this.map_ = null;

		this.startPixel_ = null;

		this.endPixel_ = null;
	}

	disposeInternal() {
		this.setMap(null);
	}

	setClassName(className: string) {
		this.element_.className = 'ol-box ' + className;
	}

	private render_() {
		const startPixel = this.startPixel_;
		const endPixel = this.endPixel_;
		const px = 'px';
		const style = this.element_.style;
		// @ts-expect-error - it will be a number
		style.left = Math.min(startPixel[0], endPixel[0]) + px;
		// @ts-expect-error - it will be a number
		style.top = Math.min(startPixel[1], endPixel[1]) + px;
		// @ts-expect-error - it will be a number
		style.width = Math.abs(endPixel[0] - startPixel[0]) + px;
		// @ts-expect-error - it will be a number
		style.height = Math.abs(endPixel[1] - startPixel[1]) + px;
	}

	setMap(map: Map | null) {
		if (this.map_) {
			this.map_.getOverlayContainer().removeChild(this.element_);
			const style = this.element_.style;
			style.left = 'inherit';
			style.top = 'inherit';
			style.width = 'inherit';
			style.height = 'inherit';
		}
		this.map_ = map;
		if (this.map_) {
			this.map_.getOverlayContainer().appendChild(this.element_);
		}
	}

	setPixels(startPixel: Pixel, endPixel: Pixel) {
		this.startPixel_ = startPixel;
		this.endPixel_ = endPixel;
		this.createOrUpdateGeometry();
		this.render_();
	}

	/**
	 * Creates or updates the cached geometry.
	 */
	createOrUpdateGeometry() {
		const startPixel = this.startPixel_;
		const endPixel = this.endPixel_;
		const pixels = [
			startPixel,
			// @ts-expect-error - it will be a number
			[startPixel[0], endPixel[1]],
			endPixel,
			// @ts-expect-error - it will be a number
			[endPixel[0], startPixel[1]]
		];
		const coordinates = pixels.map(
			// @ts-expect-error - this is from ol source
			this.map_.getCoordinateFromPixelInternal,
			this.map_
		);
		// close the polygon
		coordinates[4] = coordinates[0].slice();
		if (!this.geometry_) {
			this.geometry_ = new Polygon([coordinates]);
		} else {
			this.geometry_.setCoordinates([coordinates]);
		}
	}

	getGeometry() {
		return this.geometry_;
	}
}
