import TileLayer from 'ol/layer/Tile';
import { BingMaps, TileImage, XYZ } from 'ol/source';
import OSM from 'ol/source/OSM';
import type { MapBaseLayerDefinition } from '@agritechnovation/ol-react';

export const baseLayers = [
	{
		id: 'google' as const,
		name: 'Google',
		previewImage:
			'https://www.google.com/maps/vt/pb=!1m5!1m4!1i6!2i73!3i73!4i128!2m2!1e1!3i960!3m9!2sen!3sza!5e1105!12m1!1e4!12m1!1e47!12m1!1e3!4e0!5m2!1e0!5f2!23i10203575!23i47055961!23i47055961!23i47055961!23i1381033!23i1368782!23i1368785!23i4861626!23i4592408!23i4640515!23i4897086!23i4940989!23i1375050!23i4536287!23i47054629!23i72382377!23i72310157',
		load: () =>
			new TileLayer({
				source: new TileImage({
					url: 'https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}',
					crossOrigin: 'anonymous'
				}),
				properties: {
					uniqueId: 'google',
					name: 'Google',
					type: 'base',
					previewImage:
						'https://www.google.com/maps/vt/pb=!1m5!1m4!1i6!2i73!3i73!4i128!2m2!1e1!3i960!3m9!2sen!3sza!5e1105!12m1!1e4!12m1!1e47!12m1!1e3!4e0!5m2!1e0!5f2!23i10203575!23i47055961!23i47055961!23i47055961!23i1381033!23i1368782!23i1368785!23i4861626!23i4592408!23i4640515!23i4897086!23i4940989!23i1375050!23i4536287!23i47054629!23i72382377!23i72310157'
				}
			})
	},
	{
		id: 'osm' as const,
		name: 'OSM',
		load: () =>
			new TileLayer({
				source: new OSM(),
				properties: { uniqueId: 'osm', name: 'OpenStreetMap', type: 'base' }
			})
	},
	{
		id: 'bing' as const,
		name: 'Bing',
		load: () =>
			new TileLayer({
				source: new BingMaps({
					key: 'AhLcw12ECTanXEuLe8l9hhm4sgQV4MFMQUErmayVbtgTm8iC6MfHYBdDVeOXExXD',
					imagerySet: 'Aerial',
					maxZoom: 19
				}),
				properties: {
					uniqueId: 'bing',
					name: 'Bing',
					type: 'base'
				}
			})
	},
	{
		id: 'blank' as const,
		name: 'Blank',
		load: () =>
			new TileLayer({
				source: new XYZ({
					url: 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
				}),
				properties: { uniqueId: 'blank', name: 'Blank', type: 'base' }
			})
	},
	{
		id: 'aerial' as const,
		name: 'Aerial',
		load: () =>
			new TileLayer({
				properties: {
					uniqueId: 'aerial',
					name: 'Aerial',
					type: 'base'
				},
				// preload: Infinity,
				source: new XYZ({
					attributions: [
						'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
						'NGI'
					],
					url: 'https://api.agritechnovation.com/en/api/5.0.0/agri/tile-layer/ngi-aerial?z={z}&x={x}&y={y}',
					crossOrigin: 'anonymous'
				})
			})
	}
] as const satisfies MapBaseLayerDefinition[];

export type MapBaseLayer = (typeof baseLayers)[number]['id'];

export function getBaseLayer(key: MapBaseLayer) {
	const def = baseLayers.find((x) => x.id === key);
	if (!def) {
		throw new Error(`Base layer ${key} not found`);
	}
	return def.load();
}
