import type { CustomTileLayer } from '@agritechnovation/ol-react';
import type { Feature } from 'ol';
import type VectorLayer from 'ol/layer/Vector';
import type { Style as GeoStylerStyle } from 'geostyler-style';
import { parser } from '../../geostyler-parser';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import type { Style } from 'ol/style';
import type { RuleMetaData } from './convert-mfw-style-to-geostyler-style';

export function extractMetaFromOlLayer(
	layer: VectorLayer<Feature> | CustomTileLayer | WebGLTileLayer
) {
	const id = layer.get('mfw_uuid');
	const name = layer.get('mfw_name');
	const unit = layer.get('unit');
	const date = layer.get('date');
	const highlightedLegend = layer.get('highlighted-legend');
	const style = parseGeoStylerSync(layer);
	if (!style) return;
	return {
		id,
		name,
		style,
		unit,
		date,
		legendEntries: (style.metadata?.rules ?? []) as RuleMetaData[],
		zIndex: layer.getZIndex() ?? 0,
		highlightedLegend
	};
}

function parseGeoStylerSync(
	layer: VectorLayer<Feature> | CustomTileLayer | WebGLTileLayer
): GeoStylerStyle | undefined {
	// @ts-expect-error -- doesn't read properly when using layer.values_.geoStylerStyle
	if (layer.values_.geoStylerStyle) return layer.values_.geoStylerStyle;
	if (layer instanceof WebGLTileLayer) return;
	const style = layer.getStyle();
	if (!style) return undefined;
	// @ts-expect-error -- doesn't read properly when using style.__geoStylerStyle
	if (style.__geoStylerStyle) return style.__geoStylerStyle;
	try {
		return parser.olStyleToGeoStylerStyle(style as Style);
	} catch {
		return;
	}
}
