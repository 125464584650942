import type { Style } from 'geostyler-style';
import { useFindLayer } from '../../../../helpers';
import { useGeoJSONPolygonData } from '../../../data';
import type { MySoilClassificationOptions } from '../my-soil-classification-layer';
import { useParseGeostyler } from '../../../../use-parse-geostyler';
import { useVectorLayer } from '@agritechnovation/ol-react';

export const LINE_STYLE: Style = {
	name: 'Default Line Style',
	rules: [
		{
			name: 'Line',
			symbolizers: [
				{
					kind: 'Line',
					opacity: 1,
					color: '#33E8FF',
					width: 3
				},
				{
					kind: 'Text',
					label: '{{label}}',
					size: 12,
					font: ['Arial'],
					offset: [0, -15],
					color: '#33E8FF',
					haloColor: '#000000',
					haloWidth: 5,
					haloOpacity: 1,
					opacity: 1,
					maxAngle: 0,
					allowOverlap: true,
					avoidEdges: false,
					anchor: 'center'
				}
			]
		}
	],
	metadata: {
		classified: true,
		isLine: true
	}
} as Style;

export function useSoilClassificationLines(opts: MySoilClassificationOptions) {
	const { data, isError, error } = useGeoJSONPolygonData(opts.data);

	if (isError) {
		throw error;
	}

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	const { data: parsedStyle } = useParseGeostyler(LINE_STYLE);

	const [layer] = useVectorLayer({
		initialLayer,
		data,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex,
		style: parsedStyle?.output,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId
		}
	});

	return layer;
}
