export const Milliseconds = {
	days(n: number) {
		return 1000 * 60 * 60 * 24 * n;
	},
	hours(n: number) {
		return 1000 * 60 * 60 * n;
	},
	minutes(n: number) {
		return 1000 * 60 * n;
	},
	seconds(n: number) {
		return 1000 * n;
	}
};
