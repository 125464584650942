import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMultiplePPMTrapsGraph } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { Fill, Stroke, Style as OLStyle, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import type { StyleFunction } from 'ol/style/Style';
import type { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { Cluster } from 'ol/source';
import { useMap } from '@agritechnovation/ol-react';
import type { Style } from 'geostyler-style';
import type { PpmLayerOptions } from './use-ppm-layer';
import type { Point } from 'ol/geom';
import { useFindLayer } from '../../../helpers';
import type { LayerDataOptions } from '../../data';
import { useParseGeostyler } from '../../../use-parse-geostyler';
import { useLayerAvailableLayers } from '../../helpers';

export function usePPMTraps(
	opts: PpmLayerOptions & {
		siblingLayer: VectorLayer<Feature>;
	}
) {
	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === `ppm-traps-${opts.data.farmId}`
	);

	const config = useMemo(() => ppmTrapConfig(), []);
	const { trapsData, ids, id } = usePPMTrapsForLayer(opts.data);
	const styleQuery = useParseGeostyler(config.style);
	const [layer, , , source] = useVectorLayer({
		initialLayer,
		data: trapsData,
		properties: {
			available_layer_ids: ids,
			mfw_uuid: `ppm-${opts.data.farmId}`,
			farmId: opts.data.farmId,
			type: 'ppm-traps'
		},
		declutter: false,
		style: styleQuery.data?.output,
		addToMap: false,
		visible: opts.ppm.showTraps === undefined ? true : opts.ppm.showTraps,
		zIndex: opts.render.zIndex
	});

	const { current: style } = useRef([
		new OLStyle({
			image: new CircleStyle({
				radius: 10,
				stroke: new Stroke({
					color: '#fff'
				}),
				fill: new Fill({
					color: '#3399CC'
				})
			}),
			text: new Text({
				text: '',
				textAlign: 'center',
				font: 'bold 14px Calibri,sans-serif',
				stroke: new Stroke({
					color: '#fff',
					width: 2
				}),
				fill: new Fill({
					color: '#000'
				})
			})
		}),
		new OLStyle({
			text: new Text({
				text: '',
				textAlign: 'center',
				font: 'bold 12px Calibri,sans-serif',
				stroke: new Stroke({
					color: '#333333',
					width: 8
				}),
				offsetX: 0,
				offsetY: -18,
				fill: new Fill({
					color: '#FFFFFF'
				})
			})
		})
	]);
	const styleFn: StyleFunction = useCallback(
		(feature) => {
			const features = feature.get('features') as Array<Feature>;
			let sum = 0;
			// hacky way to show not observed if all traps are not observed
			let isNotObservedCount = 0;
			for (const f of features) {
				const val = f.get('value');
				sum += val;
				if (val === 0) {
					const geom = f.getGeometry() as Point;
					const coords = geom.getCoordinates();
					const features = opts.siblingLayer
						.getSource()
						?.getFeaturesAtCoordinate(coords);
					if (features && features.length > 0) {
						const text = features[0].get('label');
						if (text === 'No' || text === 'Not Observed') {
							isNotObservedCount++;
						}
					}
				}
			}
			const [countStyle, labelStyle] = style;
			if (isNotObservedCount === features.length) {
				countStyle.getText()?.setText('-');
			} else {
				countStyle.getText()?.setText(sum.toString());
			}
			if (features.length === 1) {
				labelStyle.getText()?.setText(features[0].get('lokvalnommer') ?? '');
			} else {
				labelStyle.getText()?.setText('');
			}
			return style;
		},
		[style, opts.siblingLayer]
	);
	const [clusterLayer] = useState(() => {
		return new VectorLayer({
			source: new Cluster({
				distance: 20,
				source
			}),
			style: styleFn,
			zIndex: opts.render.zIndex,
			properties: {
				available_layer_id: id,
				layerFilterId: opts.data.layerFilterId,
				mfw_uuid: `ppm-${opts.data.farmId}-traps-cluster`,
				farmId: opts.data.farmId,
				type: 'ppm-traps-cluster'
			},
			visible: opts.ppm.showTraps === undefined ? true : opts.ppm.showTraps
		});
	});

	useEffect(() => {
		if (opts.ppm.showTraps === false) {
			clusterLayer.setVisible(false);
		} else {
			clusterLayer.setVisible(true);
		}
	}, [clusterLayer, opts.ppm.showTraps]);

	const map = useMap();
	useEffect(() => {
		map.addLayer(clusterLayer);
		return () => {
			map.removeLayer(clusterLayer);
		};
	}, [map, clusterLayer]);

	useEffect(() => {
		layer?.set('available_layer_ids', ids);
		clusterLayer?.set('available_layer_id', ids);
	}, [clusterLayer, id, ids, layer]);
}

function ppmTrapConfig() {
	return {
		style: {
			name: 'PPM Trap',
			rules: [
				{
					name: 'Labels',
					symbolizers: [
						{
							kind: 'Text',
							font: ['Arial'],
							size: 12,
							color: '#000000',
							offset: [0, -18],
							haloColor: '#FFFFFF',
							haloWidth: 2,
							label: '{{lokvalnommer}}'
						},
						{
							kind: 'Text',
							allowOverlap: true,
							font: ['Arial'],
							size: 8,
							color: '#000000',
							haloColor: '#FFFFFF',
							haloWidth: 2,
							label: '{{value}}'
						},
						{
							kind: 'Mark',
							wellKnownName: 'circle',
							color: '#002dff',
							radius: 8
						}
					]
				}
			]
		} satisfies Style
	};
}

export function usePPMTrapsForLayer(opts: LayerDataOptions) {
	const { data: dates } = useLayerAvailableLayers(opts);
	const id = useMemo(() => dates?.at(0)?.id, [dates]);
	const ids = useMemo(() => dates?.map((d) => d.id) || [], [dates]);
	const { data: trapsData, isPending } = useMultiplePPMTrapsGraph(ids);

	return {
		id,
		ids,
		trapsData,
		isPending
	};
}
