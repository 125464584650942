import type { Style } from 'geostyler-style';
import { parseClassToFloatMinMax } from '../../helpers/parse-class-to-min-max';
import type {
	StaticMyYieldS3Style,
	TempStaticMyYieldS3Style
} from './my-yield-layer';

export const lossColors = [
	{ label: 'Very High Loss', color: '#b30000' },
	{ label: 'High Loss', color: '#e60000' },
	{ label: 'Medium Loss', color: '#ff1a1a' },
	{ label: 'Small Loss', color: '#ff4d4d' }
];

export const profitColors = [
	{ label: 'Small Profit', color: '#79de6e' },
	{ label: 'Medium Profit', color: '#3fd02f' },
	{ label: 'High Profit', color: '#2c9321' },
	{ label: 'Very High Profit', color: '#195313' }
];

export const fullProfitColors = [
	...lossColors,
	{
		label: 'Break Even',
		color: '#000000'
	},
	...profitColors
];

export const ProfitabilityAsGeostyler = (): Style => {
	return {
		name: 'Profitability',
		rules: fullProfitColors.map((entry) => {
			return {
				name: entry.label,
				symbolizers: [
					{
						kind: 'Fill',
						color: entry.color
					}
				]
			};
		}),
		metadata: {
			supportsHectares: false
		}
	};
};

export function getProfitClass(
	label: string,
	{
		maxTons,
		minTons,
		sellingPricePerUnit,
		costPerHa
	}: {
		maxTons: number;
		minTons: number;
		sellingPricePerUnit: number;
		costPerHa: number;
	}
) {
	if (costPerHa === 0 && sellingPricePerUnit === 0) {
		return {
			label: 'Break Even',
			color: '#000000'
		};
	}

	const maxProfit = maxTons * sellingPricePerUnit - costPerHa;
	const minProfit = minTons * sellingPricePerUnit - costPerHa;
	const lostStep = Math.abs(minProfit / lossColors.length);
	const profitStep = Math.abs(maxProfit / profitColors.length);
	const { max, min } = parseClassToFloatMinMax(label);
	const profitMax = Math.floor(max * sellingPricePerUnit - costPerHa);
	const profitMin = Math.floor(min * sellingPricePerUnit - costPerHa);
	if (profitMin <= 0 && 0 <= profitMax) {
		return {
			label: 'Break Even',
			color: '#000000'
		};
	} else if (profitMin > 0) {
		const index = Math.floor(profitMin / profitStep);
		return profitColors[index];
	} else {
		const index = Math.floor(Math.abs(profitMax) / lostStep);
		return lossColors[index];
	}
}

export function isStaticStyle(style: unknown): style is StaticMyYieldS3Style {
	if (Array.isArray(style)) return false;
	if (typeof style !== 'object') return false;
	if (!style) return false;
	if (!('classes' in style)) return false;
	if (!('type' in style)) return false;
	return style.type === 'custom';
}

export function isTempStaticStyle(
	style: unknown
): style is TempStaticMyYieldS3Style {
	if (!Array.isArray(style)) return false;
	if (style.length === 0) return false;
	const entry = style[0];
	if (!entry) return false;
	if (typeof entry !== 'object') return false;
	if ('n_classes' in entry) return false;
	if ('classes' in entry) return false;
	return true;
}
