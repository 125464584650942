import type { DragBoxOnSignature } from 'ol/interaction/DragBox';
import {
	DragBoxEvent,
	type EndCondition,
	type Options
} from 'ol/interaction/DragBox';
import PointerInteraction from 'ol/interaction/Pointer';
import { CustomRenderBox } from './custom-box';
import type { Pixel } from 'ol/pixel';
import type { Condition } from 'ol/events/condition';
import { mouseActionButton } from 'ol/events/condition';
import type { MapBrowserEvent } from 'ol';
import type { EventsKey } from 'ol/events';

/**
 * @classdesc
 * Allows the user to draw a vector box by clicking and dragging on the map,
 * normally combined with an {@link module:ol/events/condition} that limits
 * it to when the shift or other key is held down. This is used, for example,
 * for zooming to a specific area of the map
 * (see {@link module:ol/interaction/DragZoom~DragZoom} and
 * {@link module:ol/interaction/DragRotateAndZoom~DragRotateAndZoom}).
 *
 * @fires DragBoxEvent
 * @api
 */
const DragBoxEventType = {
	/**
	 * Triggered upon drag box start.
	 * @event DragBoxEvent#boxstart
	 * @api
	 */
	BOXSTART: 'boxstart',

	/**
	 * Triggered on drag when box is active.
	 * @event DragBoxEvent#boxdrag
	 * @api
	 */
	BOXDRAG: 'boxdrag',

	/**
	 * Triggered upon drag box end.
	 * @event DragBoxEvent#boxend
	 * @api
	 */
	BOXEND: 'boxend',

	/**
	 * Triggered upon drag box canceled.
	 * @event DragBoxEvent#boxcancel
	 * @api
	 */
	BOXCANCEL: 'boxcancel'
};

export type CustomDragBoxOptions = Options & {
	className?: string;
};

/**
 * @classdesc
 * Allows the user to draw a vector box by clicking and dragging on the map,
 * normally combined with an {@link module:ol/events/condition} that limits
 * it to when the shift or other key is held down. This is used, for example,
 * for zooming to a specific area of the map
 * (see {@link module:ol/interaction/DragZoom~DragZoom} and
 * {@link module:ol/interaction/DragRotateAndZoom~DragRotateAndZoom}).
 *
 * @fires DragBoxEvent
 * @api
 */

export class CustomDragBox extends PointerInteraction {
	private box_: CustomRenderBox;
	private minArea_: number;
	private startPixel_: null | Pixel;
	private condition_: Condition;
	private boxEndCondition_: EndCondition;
	declare on: DragBoxOnSignature<EventsKey>;
	declare once: DragBoxOnSignature<EventsKey>;
	declare un: DragBoxOnSignature<void>;
	constructor(options?: CustomDragBoxOptions) {
		super();

		this.once;

		this.un;

		options = options
			? (options as CustomDragBoxOptions)
			: ({} as CustomDragBoxOptions);

		this.box_ = new CustomRenderBox(options.className || 'ol-dragbox');

		this.minArea_ = options.minArea !== undefined ? options.minArea : 64;

		if (options.onBoxEnd) {
			this.onBoxEnd = options.onBoxEnd;
		}

		this.startPixel_ = null;

		this.condition_ = options.condition ? options.condition : mouseActionButton;

		this.boxEndCondition_ = options.boxEndCondition
			? options.boxEndCondition
			: this.defaultBoxEndCondition;
	}

	defaultBoxEndCondition(
		_: MapBrowserEvent<UIEvent>,
		startPixel: Pixel,
		endPixel: Pixel
	) {
		const width = endPixel[0] - startPixel[0];
		const height = endPixel[1] - startPixel[1];
		return width * width + height * height >= this.minArea_;
	}

	getGeometry() {
		return this.box_.getGeometry();
	}

	handleDragEvent(mapBrowserEvent: MapBrowserEvent<UIEvent>) {
		// @ts-expect-error -- from ol source
		this.box_.setPixels(this.startPixel_, mapBrowserEvent.pixel);

		this.dispatchEvent(
			new DragBoxEvent(
				DragBoxEventType.BOXDRAG,
				mapBrowserEvent.coordinate,
				mapBrowserEvent
			)
		);
	}

	get box() {
		return this.box_;
	}

	handleUpEvent(mapBrowserEvent: MapBrowserEvent<UIEvent>) {
		this.box_.setMap(null);

		const completeBox = this.boxEndCondition_(
			mapBrowserEvent,
			// @ts-expect-error -- from ol source
			this.startPixel_,
			mapBrowserEvent.pixel
		);
		if (completeBox) {
			this.onBoxEnd(mapBrowserEvent);
		}
		this.dispatchEvent(
			new DragBoxEvent(
				completeBox ? DragBoxEventType.BOXEND : DragBoxEventType.BOXCANCEL,
				mapBrowserEvent.coordinate,
				mapBrowserEvent
			)
		);
		return false;
	}

	handleDownEvent(mapBrowserEvent: MapBrowserEvent<UIEvent>) {
		if (this.condition_(mapBrowserEvent)) {
			this.startPixel_ = mapBrowserEvent.pixel;
			this.box_.setMap(mapBrowserEvent.map);
			this.box_.setPixels(this.startPixel_, this.startPixel_);
			this.dispatchEvent(
				new DragBoxEvent(
					DragBoxEventType.BOXSTART,
					mapBrowserEvent.coordinate,
					mapBrowserEvent
				)
			);
			return true;
		}
		return false;
	}

	onBoxEnd(_event: MapBrowserEvent<UIEvent>) {
		// noop
	}
}
