import type { MapBrowserEvent } from 'ol';
import type Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import type { StyleLike } from 'ol/style/Style';
import { useCallback, useEffect, useRef } from 'react';
import { useMap } from '../../map';

const defaultHoverStyle = new Style({
	stroke: new Stroke({
		color: 'rgba(239,9,9,1)',
		width: 4
	})
});

export function useSelectLayers({
	layers,
	selected,
	hoverStyle,
	onHover: onHoverProp,
	onHoverFilter,
	styleOnlyFirst = false
}: {
	layers: VectorLayer<Feature>[];
	selected: Feature[];
	hoverStyle?: StyleLike;
	onHover?: (e: Feature) => void;
	onHoverFilter?: (e: Feature[]) => Feature[];
	// only style the first feature in hovered
	styleOnlyFirst?: boolean;
	dragBox?: boolean;
}) {
	const map = useMap();
	const source = useRef<VectorSource>(new VectorSource());
	const layer = useRef<VectorLayer<Feature>>(
		new VectorLayer({
			zIndex: 3000,
			source: source.current,
			style: new Style({
				stroke: new Stroke({
					color: 'rgb(0,44,255)',
					width: 4
				})
			})
		})
	);

	const hoveredSource = useRef<VectorSource>(new VectorSource());
	const hoveredLayer = useRef<VectorLayer<Feature>>(
		new VectorLayer({
			zIndex: 3000,
			source: hoveredSource.current,
			style: hoverStyle ?? defaultHoverStyle
		})
	);

	useEffect(() => {
		const l = layer.current;
		const h = hoveredLayer.current;
		map.addLayer(l);
		map.addLayer(h);
		return () => {
			map.removeLayer(l);
			map.removeLayer(h);
		};
	}, [map]);

	const onHover = useCallback(
		(e: MapBrowserEvent<MouseEvent>) => {
			const features = map.getFeaturesAtPixel(e.pixel);
			let featuresFromLayers = features.filter((f) =>
				layers.some((l) =>
					l
						.getSource()
						?.getFeatures()
						.includes(f as Feature)
				)
			) as Feature[];
			if (onHoverFilter) {
				featuresFromLayers = onHoverFilter(featuresFromLayers);
			}
			if (styleOnlyFirst) {
				const f = featuresFromLayers[0];
				if (f) {
					hoveredSource.current.clear();
					hoveredSource.current.addFeature(f);
					onHoverProp?.(f);
				} else {
					hoveredSource.current.clear();
				}
			} else {
				for (const f of hoveredSource.current.getFeatures()) {
					if (!featuresFromLayers.includes(f)) {
						hoveredSource.current.removeFeature(f);
					}
				}
				for (const f of featuresFromLayers) {
					if (!hoveredSource.current.getFeatures().includes(f)) {
						hoveredSource.current.addFeature(f);
						onHoverProp?.(f);
					}
				}
			}
			map.render();
		},
		[layers, map, onHoverFilter, onHoverProp, styleOnlyFirst]
	);

	useEffect(() => {
		map.on('pointermove', onHover);
		return () => {
			map.un('pointermove', onHover);
		};
	}, [onHover, map]);

	useEffect(() => {
		if (source.current.getFeatures().length !== selected.length) {
			source.current.clear();
			source.current.addFeatures(selected);
		}
	}, [selected, source]);

	return { map, source };
}
