import { useQuery } from '@tanstack/react-query';
import type { Style } from 'geostyler-style';
import { useMap } from '@agritechnovation/ol-react';
import type { FeatureLike } from 'ol/Feature';
import type OlMap from 'ol/Map';
import { MultiPolygon, Polygon } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { getArea } from 'ol/sphere';
import type { StyleLike } from 'ol/style/Style';
import type Text from 'ol/style/Text';
import { useMemo } from 'react';
import type Feature from 'ol/Feature';
import { parser } from './geostyler-parser';

/**
 * A hook to convert a GeoStyler style to an OpenLayers style
 * caches based on the style name
 * WARNING: ensure that styles have a unique name, if a style is converted from a MFWStyle it will automatically have a unique name as base64 encoded string of the options/rules, however for manually defined styles ensure that the name is unique, e.g. put the layerfilter id in the name
 */
export const useParseGeostyler = (
	style: Style | undefined | null,
	opts?: {
		singleLabelPerPolygon?: boolean;
	}
) => {
	const map = useMap();
	const queryKey = useMemo(
		() => ['geo-styler-style', opts?.singleLabelPerPolygon, style?.name],
		[style, opts]
	);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps -- queryKey is memoized
		queryKey: queryKey,
		structuralSharing: false,
		queryFn: async () => {
			if (!style) {
				throw new Error('Style is undefined');
			}
			const output = await parser.writeStyle(style);
			if (opts?.singleLabelPerPolygon) {
				const multiStyle = singleLabelPerPolygon(
					style,
					output.output as StyleLike,
					map
				);
				return {
					...output,
					output: multiStyle as StyleLike
				};
			}
			return output;
		},
		enabled: !!style
	});
};

function getAreaSafely(feature: FeatureLike) {
	try {
		return getArea(feature.getGeometry() as Polygon);
	} catch {
		return 0;
	}
}

/**
 * Given a MultiPolygon or Polygon feature, this function will ensure that if features share the same label, only the largest feature will render the label
 */
export function singleLabelPerPolygon(
	geostylerStyle: Style,
	finalStyle: StyleLike,
	map: OlMap
) {
	let areas: {
		feature: FeatureLike;
		area: number;
		label: string;
	}[] = [];
	map.on('rendercomplete', function () {
		areas = [];
		renderLookup.clear();
	});
	const renderLookup = new Map<string | string[], string>();

	function guardText(text: Text | undefined, feature: FeatureLike) {
		if (text) {
			const label = text.getText();
			if (label) {
				const isRendered = renderLookup.get(label);
				if (isRendered) {
					text.setText('');
					return;
				}
				if (areas.length === 0) {
					const layerForFeature = map
						.getLayers()
						.getArray()
						.find(
							(l) =>
								l instanceof VectorLayer &&
								l.getSource()?.getFeatures().includes(feature)
						) as VectorLayer<Feature>;
					if (layerForFeature) {
						const source = layerForFeature.getSource();
						const features = source?.getFeatures();
						areas =
							features
								?.filter((f) => f.getGeometry() instanceof Polygon)
								.map((f) => {
									return {
										feature: f,
										area: getAreaSafely(f),
										label:
											f.get('translatedLabel') ??
											f.get('label') ??
											f.get('legend')
									};
								})
								.sort((a, b) => a.area - b.area) ?? [];
					}
				}

				const largest = areas.filter((x) => x.label === label).at(-1);
				const currentArea = getAreaSafely(feature);

				if (!largest) return;

				const largestArea = largest?.area ?? 0;
				if (currentArea >= largestArea) {
					renderLookup.set(label, label as string);
				} else {
					text.setText('');
				}
			}
		}
	}

	const polygonOrMultiPolygon = (feat: FeatureLike) => {
		const geom = feat.getGeometry();
		return geom instanceof Polygon || geom instanceof MultiPolygon;
	};

	const fn = function (feature: FeatureLike, res: number) {
		if (!polygonOrMultiPolygon(feature)) {
			if (typeof finalStyle === 'function') {
				const style = finalStyle(feature, res);
				return style;
			} else {
				return finalStyle;
			}
		}
		if (typeof finalStyle === 'function') {
			const computedStyle = finalStyle(feature, res);
			if (Array.isArray(computedStyle)) {
				for (const style of computedStyle) {
					const text = style.getText();
					if (text) guardText(text, feature);
				}
				return computedStyle;
			} else if (computedStyle) {
				const text = computedStyle.getText();
				if (text) guardText(text, feature);
				return computedStyle;
			}
		} else if (Array.isArray(finalStyle)) {
			for (const style of finalStyle) {
				const text = style.getText();
				if (text) guardText(text, feature);
			}
			return finalStyle;
		} else {
			const text = finalStyle.getText();
			if (text) guardText(text, feature);
			return finalStyle;
		}
	};
	// these ensure that the parser can still read the style
	fn.__geoStylerStyle = geostylerStyle;
	fn.__isMulti = true;
	return fn;
}
