import { forwardRef, useImperativeHandle } from 'react';
import type { MapLayerRef } from '../map-layers.types';
import type VectorLayer from 'ol/layer/Vector';
import { useFarmSubBlocksLayer } from './use-farm-sub-blocks';
import type { FarmBlocksProps } from './farm-blocks';
import type { Feature } from 'ol';

export type FarmSubBlocksProps = FarmBlocksProps;

export const FarmSubBlocksLayer = forwardRef<
	MapLayerRef<VectorLayer<Feature>>,
	FarmSubBlocksProps
>(function FarmBlocks({ id, ...props }, ref) {
	const [layer, query, zoomTo] = useFarmSubBlocksLayer(id, props);
	useImperativeHandle(
		ref,
		() => ({
			layer,
			zoomTo,
			query
		}),
		[layer, zoomTo, query]
	);
	return null;
});
