import { useLayerFilter } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { useFindLayer } from '../../../../helpers';
import { useGeoJSONPointData } from '../../../data';
import { MYSOILCLASSIFICATION_PROBE_STYLE } from './probe.style';
import { useGeoJSONPointStyle } from '../../../data';
import { SOIL_CLASS_PROBE_LAYER } from '../constants';
import type { MySoilClassificationOptions } from '../my-soil-classification-layer';
import { useParseGeostyler } from '../../../../use-parse-geostyler';

export function useMySoilClassificationPointLayer(
	opts: MySoilClassificationOptions
) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);

	const { data, isError, error } = useGeoJSONPointData(opts.data);

	if (isError) {
		throw error;
	}

	const { style: defaultStyle } = useGeoJSONPointStyle(opts.data);

	const style = () => {
		if (!layerFilter) return defaultStyle;
		return layerFilter.unique_id === (SOIL_CLASS_PROBE_LAYER as string)
			? MYSOILCLASSIFICATION_PROBE_STYLE
			: defaultStyle;
	};

	const { data: parsedStyle } = useParseGeostyler(style());

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	return useVectorLayer({
		initialLayer,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex ? opts.render.zIndex + 100 : 250,
		data,
		style: parsedStyle?.output,
		opacity: opts.render.opacity,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId
		}
	});
}
