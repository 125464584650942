import {
	useFarmInfrastructure,
	useInfrastructureType
} from '@agritechnovation/api-query';
import { useEffect, useMemo } from 'react';
import type { Style } from 'geostyler-style';
import type { LayerOptions } from '../../layer-options';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { useFindLayer } from '../../../helpers';
import { useParseGeostyler } from '../../../use-parse-geostyler';

export type MyInfrastructureLayerProps = Omit<LayerOptions, 'data'> & {
	farmId: number;
	typeId: number;
};
export function useMyInfrastructureLayer({
	typeId,
	farmId,
	render: { zIndex, declutter, opacity },
	layer: { uniqueId }
}: MyInfrastructureLayerProps) {
	const { data: type } = useInfrastructureType(typeId);
	const { data } = useFarmInfrastructure(type?.name, farmId);
	const initialLayer = useFindLayer((l) => l.get('mfw_uuid') === uniqueId);
	const isPolygon = useMemo(() => {
		if (!type) return false;
		if (type.polygon_style) {
			return true;
		}
		return (
			data?.features.some(
				(f) =>
					f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon'
			) ?? false
		);
	}, [data?.features, type]);
	const geoStylerStyle = useMyInfrastructureStyle(typeId, {
		isPolygon
	});
	const { data: style } = useParseGeostyler(geoStylerStyle);
	const [layer, zoomTo] = useVectorLayer({
		initialLayer,
		declutter,
		zIndex,
		data: data,
		style: style?.output,
		opacity,
		properties: {
			mfw_uuid: uniqueId,
			farmId
		}
	});
	useEffect(() => {
		if (layer) {
			layer.set('mfw_uuid', uniqueId);
			layer.set('farmId', farmId);
			layer.set('typeId', typeId);
		}
	}, [uniqueId, farmId, typeId, layer]);
	return [layer, zoomTo];
}

export function useMyInfrastructureStyle(
	typeId: number,
	{
		isPolygon,
		alwaysOmitIcon
	}: {
		isPolygon: boolean;
		alwaysOmitIcon?: boolean;
	}
) {
	const { data: type } = useInfrastructureType(typeId);

	const style: Style | undefined = useMemo(() => {
		if (!type)
			return {
				name: 'My Infrastructure Style',
				rules: []
			} satisfies Style;
		const { name, icon, polygon_style } = type;
		if (isPolygon) {
			if (polygon_style) {
				return {
					name,
					rules: [
						{
							name: 'My Infrastructure Style Rule',
							symbolizers: [
								{
									kind: 'Text',
									label: '{{name}}',
									font: ['Arial'],
									size: 12,
									color: '#000000',
									haloColor: '#FFFFFF',
									haloWidth: 5,
									allowOverlap: true
								},
								polygon_style.show_fill_color
									? {
											kind: 'Fill',
											color: polygon_style.fill_color,
											outlineColor: polygon_style.line_color,
											outlineWidth: polygon_style.line_width
										}
									: {
											kind: 'Line',
											color: polygon_style.line_color,
											width: polygon_style.line_width
										}
							]
						}
					]
				} satisfies Style;
			} else {
				const style: Style = {
					name,
					rules: [
						{
							name: 'My Infrastructure Style Rule',
							symbolizers: [
								{
									kind: 'Text',
									label: '{{name}}',
									font: ['Arial'],
									size: 12,
									color: '#000000',
									haloColor: '#FFFFFF',
									haloWidth: 5,
									allowOverlap: true
								},
								{
									kind: 'Fill',
									color: '#0cbfff',
									fillOpacity: 0.5,
									outlineColor: '#0cbfff',
									outlineWidth: 1
								}
							]
						}
					]
				};
				if (alwaysOmitIcon) {
					return style;
				} else {
					if (icon) {
						style.rules[0].symbolizers.unshift({
							kind: 'Icon',
							image: icon,
							allowOverlap: true,
							size: 32,
							anchor: 'center',
							offset: [0, 16]
						});
					}
					return style;
				}
			}
		}
		if (icon) {
			return {
				name,
				rules: [
					{
						name: 'My Infrastructure Style Rule',
						symbolizers: [
							{
								kind: 'Icon',
								image: icon
									.replace(
										'https://myfarmweb.agritechnovation.com/media/icons/',
										'https://myfarmweb.com/static-data/media/icons/'
									)
									.replace(
										'https://us-mfw-site.s3.amazonaws.com/media/icons/',
										'https://us.myfarmweb.com/static-data/media/icons/'
									),
								size: 32,
								allowOverlap: true,
								anchor: 'center',
								offset: icon.endsWith('ezgif.com-resize_9.png')
									? [0, 16]
									: icon.endsWith('borehole_large6.svg')
										? [0, 36]
										: [0, 0]
							},
							{
								kind: 'Text',
								label: '{{name}}',
								font: ['Arial'],
								size: 12,
								color: '#000000',
								haloColor: '#FFFFFF',
								haloWidth: 5,
								offset: [0, 12],
								allowOverlap: true
							}
						]
					}
				]
			} satisfies Style;
		}
	}, [alwaysOmitIcon, isPolygon, type]);

	return style;
}
