import {
	parseClassToFloatMinMax,
	useLayerFilter,
	useMultipleAvailableCentroids,
	useMultipleSoilClassLabels
} from '@agritechnovation/api-query';
import { useMemo } from 'react';
import type {
	Feature,
	FeatureCollection,
	MultiPolygon,
	Point,
	Polygon,
	Position
} from 'geojson';
import { useVectorLayer } from '@agritechnovation/ol-react/src/layers/vector/use-vector-layer';
import flatten from '@turf/flatten';
import explode from '@turf/explode';
import pointsWithinPolygon from '@turf/points-within-polygon';
import nearestPoint from '@turf/nearest-point';
import area from '@turf/area';

import type { Style } from 'geostyler-style';
import { multiPolygon, polygon } from '@turf/helpers';
import type { RawLegendEntry } from '@agritechnovation/api';
import { SOIL_CLASSIFICATION_WITH_LABELS_FROM_API } from '../constants';
import { useLayerAvailableLayers } from '../../../helpers';
import type { MySoilClassificationOptions } from '../my-soil-classification-layer';
import { useParseGeostyler } from '../../../../use-parse-geostyler';

export function useMySoilClassificationLabelsLayer(
	opts: MySoilClassificationOptions & {
		geojson: FeatureCollection<Polygon | MultiPolygon> | undefined;
		legendEntries: RawLegendEntry[];
	}
) {
	const { data: layerDates } = useLayerAvailableLayers(opts.data);

	const availableLayerIds = useMemo(
		() => layerDates?.map((d) => d.id) || [],
		[layerDates]
	);

	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	const enabled = useMemo(() => {
		if (!layerFilter) return false;
		return SOIL_CLASSIFICATION_WITH_LABELS_FROM_API.some((l) =>
			layerFilter.unique_id.includes(l)
		);
	}, [layerFilter]);

	const { data: centroidLabels } = useMultipleAvailableCentroids(
		availableLayerIds,
		enabled
	);
	const { data: soilClassLabels } = useMultipleSoilClassLabels(
		availableLayerIds,
		enabled
	);

	const isSoilClass = useMemo(() => {
		if (!layerFilter) return false;
		return (
			layerFilter.unique_id.includes('soil_classification') ||
			layerFilter.unique_id.includes('soil_form')
		);
	}, [layerFilter]);

	const rawData: FeatureCollection<Point> | undefined = useMemo(() => {
		if (!layerFilter) return;
		if (!enabled) return;
		let data: FeatureCollection<Point>;
		if (isSoilClass) {
			data = soilClassLabels;
		} else {
			data = centroidLabels;
		}
		if (data && data.features.length === 0 && !isSoilClass) {
			data = soilClassLabels;
		}
		return data;
	}, [centroidLabels, enabled, isSoilClass, layerFilter, soilClassLabels]);

	const dataToUse = useMemo(() => {
		if (!rawData) return;
		if (!opts.geojson) return;
		// only layers that have range classes (besides soil class) need special labels on the map, but the data does not have the min/max values at times for each class, so we just add it
		const legendEntriesWithAssuredMinMax = opts.legendEntries.map((entry) => {
			const hasNoMin = entry.min === undefined;
			const hasNoMax = entry.max === undefined;
			if (hasNoMin || hasNoMax) {
				const { min, max } = parseClassToFloatMinMax(String(entry.legend));
				const updatedEntry = { ...entry };
				if (hasNoMin) {
					updatedEntry.min = min;
				}
				if (hasNoMax) {
					updatedEntry.max = max;
				}
				return updatedEntry;
			}
			return entry;
		});
		return extractValidLabels(
			opts.geojson,
			rawData,
			legendEntriesWithAssuredMinMax,
			isSoilClass
		);
	}, [rawData, opts.geojson, opts.legendEntries, isSoilClass]);

	const geostylerStyle: Style = useMemo(() => {
		return {
			name: 'MySoilClassificationPointLabels',
			rules: [
				{
					name: 'MySoilClassificationPointLabels',
					symbolizers: [
						{
							kind: 'Text',
							label: '{{value}}',
							size: 9,
							fontWeight: 'bold',
							font: ['Arial'],
							color: '#FFFFFF',
							haloColor: '#333333',
							haloWidth: 5,
							haloOpacity: 1,
							opacity: 1,
							maxAngle: 0,
							allowOverlap: true,
							avoidEdges: false,
							anchor: 'center'
						}
					]
				}
			]
		} satisfies Style;
	}, []);

	const { data: style } = useParseGeostyler(geostylerStyle);

	useVectorLayer({
		data: dataToUse,
		style: style?.output,
		zIndex: opts.render.zIndex + 1,
		declutter: true,
		visible: opts.soilClass.showPointLabels,
		properties: {
			mfw_uuid: `support-${opts.layer.uniqueId}`
		}
	});
	return null;
}
/**
 * find the largest polygon for each feature in the geojson
 * for each polygon in the geojson, find the points within the polygon
 * sort the points by distance to the nearest edge of the polygon it's in
 * keep the points that are furthest from the edge of the polygon each point is in. i.e. closes to the middle
 * if there are multiple points with the same value, only keep one
 * if there are multiple points with different values, keep all
 */
export function extractValidLabels(
	geojson: FeatureCollection<Polygon | MultiPolygon>,
	labels: FeatureCollection<Point>,
	legendEntries: RawLegendEntry[],
	isSoilClass: boolean
) {
	const flattened = isSoilClass ? flatten(geojson) : { ...geojson };
	const inside = pointsWithinPolygon(labels, flattened);
	// sort
	flattened.features.sort((a, b) => {
		const areaA = area(a);
		const areaB = area(b);
		return areaB - areaA;
	});
	// remove duplicates, keep the biggest, or if more than one unique label, keep it
	// because we have sorted by area, we remove the smallest, and keep the biggest (first found)
	// but if there are multiple unique labels, we keep them all
	const found = new Set();
	flattened.features = flattened.features.filter((f) => {
		if (found.has(f.properties?.label)) {
			const pointsInside = pointsWithinPolygon(labels, f);
			const uniqueValues = new Set(
				pointsInside.features.map((x) => x.properties?.value).filter(Boolean)
			);
			if (uniqueValues.size > 1) {
				return true;
			}
			return false;
		} else {
			found.add(f.properties?.label);
		}
		return true;
	});

	const fc: FeatureCollection<Point> = {
		type: 'FeatureCollection',
		features: []
	};

	for (const feature of flattened.features) {
		const featureClass = feature.properties?.label as string;
		const legend = legendEntries?.find((x) => x.legend === featureClass) as
			| undefined
			| {
					min?: number;
					max?: number;
			  };
		const insideFeature = pointsWithinPolygon(inside, feature);
		insideFeature.features = insideFeature.features.filter((f) => {
			if (!isSoilClass) {
				if (
					(legend?.min || legend?.min === 0) &&
					(legend?.max || legend?.max === 0)
				) {
					return (
						f.properties?.value >= legend.min &&
						f.properties?.value <= legend.max
					);
				}
				if (legend?.max || legend?.max === 0) {
					return f.properties?.value <= legend.max;
				}
				if (legend?.min || legend?.min === 0) {
					return f.properties?.value >= legend.min;
				}
				return true;
			} else {
				return true;
			}
		});

		const valid = validPoly(feature as Poly);
		if (!valid) continue;
		// explode the feature to get its vertices as points
		const exploded = explode(feature);
		insideFeature.features.sort((a, b) => {
			const distanceA = nearestPoint(a as Feature<Point>, exploded);
			const distanceB = nearestPoint(b as Feature<Point>, exploded);
			return (
				distanceB.properties.distanceToPoint -
				distanceA.properties.distanceToPoint
			);
		});
		const names = insideFeature.features
			.map((x) => x.properties?.value)
			.filter((x) => typeof x !== 'undefined') as string[];
		const set = new Set(names);
		const namesArray = Array.from(set);
		if (set.size === 1) {
			const feature = insideFeature.features.find(
				(x) => x.properties?.value === namesArray[0]
			);
			if (feature) {
				fc.features.push(feature as Feature<Point>);
			}
		} else {
			for (const name of namesArray) {
				const feature = insideFeature.features.find(
					(x) => x.properties?.value === name
				);
				if (feature) {
					fc.features.push(feature as Feature<Point>);
				}
			}
		}
	}
	return fc;
}

type Poly = Feature<Polygon | MultiPolygon>;
function validPoly(feature: Poly) {
	try {
		if (feature.geometry.type === 'MultiPolygon') {
			multiPolygon(feature.geometry.coordinates as Position[][][]);
			if (feature.geometry.coordinates.length === 1) {
				const first = feature.geometry.coordinates[0];

				if (first.length === 1) {
					const second = first[0];
					if (second.length === 1) {
						const third = second[0];
						if (third.length === 0) {
							return false;
						}
					}
				}
			}
		} else {
			polygon(feature.geometry.coordinates as Position[][]);
		}
		return true;
	} catch {
		return false;
	}
}
