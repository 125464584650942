import { useVectorLayer } from '@agritechnovation/ol-react';
import { useFindLayer } from '../../../helpers';
import { useGeoJSONPolygonData } from '../../data/geojson/use-geojson-polygon-data';
import { useGeoJSONPolygonStyle } from '../../data/geojson/use-geojson-polygon-style';
import type { LayerOptions } from '../../layer-options';
import { useSyncProperties } from './use-sync-properties';
import { useMemo } from 'react';
import { useLayerAvailableLayers } from '../../helpers';
import { useParseGeostyler } from '../../../use-parse-geostyler';

export function useDefaultPolygonLayer(opts: LayerOptions) {
	const { data: availableLayers = [] } = useLayerAvailableLayers({
		farmId: opts.data.farmId,
		layerFilterId: opts.data.layerFilterId,
		dates: opts.data.dates,
		dateBucket: opts.data.dateBucket,
		depth: opts.data.depth
	});

	const { data } = useGeoJSONPolygonData(opts.data);

	const {
		data: style,
		error: styleError,
		isError: isStyleError
	} = useGeoJSONPolygonStyle(data, opts.data);

	if (isStyleError) {
		throw styleError;
	}

	const {
		data: parsed,
		isError: isStyleParseError,
		error: styleParseError
	} = useParseGeostyler(style?.style);

	if (isStyleParseError) {
		throw styleParseError;
	}

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	// memoize properties so that when we have many other components observing the layer they don't have to re-render
	const properties = useMemo(
		() => ({
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId,
			available_layer_id: availableLayers?.[0]?.id,
			unit: style?.unit,
			depth: opts.data.depth
		}),
		[
			availableLayers,
			opts.data.dates,
			opts.data.farmId,
			opts.data.layerFilterId,
			opts.layer.uniqueId,
			style?.unit,
			opts.data.depth
		]
	);

	const [layer] = useVectorLayer({
		initialLayer,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex,
		opacity: opts.render.opacity,
		data,
		style: parsed?.output
	});

	useSyncProperties(layer, properties);
}
