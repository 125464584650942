import {
	isMyProfitabilityLayer,
	isYieldLayer,
	parseClassToFloatMinMax,
	useApi,
	useFarmLayerStyle,
	useLayerFilter
} from '@agritechnovation/api-query';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
	convertMfwStyleToGeoStyler,
	getUnit,
	useLayerAvailableLayers
} from '../../helpers';
import type {
	Api,
	CogHistogram,
	LayerFilter,
	RawLegend,
	RawLegendEntry
} from '@agritechnovation/api';
import type { LayerOptions } from '../../layer-options';
import isNetworkError from 'is-network-error';

export type GeoTIFFStyleOptions = LayerOptions & {
	customLegend?: RawLegendEntry[];
};

export function useGeoTIFFStyle(opts: GeoTIFFStyleOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);

	const { data: availableLayers } = useLayerAvailableLayers(opts.data);

	const { data: style } = useFarmLayerStyle({
		farmId: opts.data.farmId,
		layerFilter
	});

	const api = useApi();

	const availableLayerId = availableLayers?.[0]?.id;
	const isRGB = layerFilter?.unique_id.includes('fieldimage');

	const selector = useCallback(
		(histogram: CogHistogram | null) => {
			const isFCover = layerFilter?.unique_id.includes('verde_fcover');

			const legend = Object.entries(
				opts.customLegend ?? (style as RawLegend)
			).map(([color, value]) => {
				return {
					...value,
					colour: color,
					color: color,
					dontShowArea: true
				};
			});
			const withAreas = histogram
				? extractAreasFromCogLegendAndHistogram(
						opts.customLegend ?? (style as RawLegend),
						histogram,
						10 // currently all the cog layers are at 10m resolution
					)
				: legend.map((entry, i) => {
						const nextEntry = legend[i + 1];
						return {
							...entry,
							max: nextEntry?.min ?? Infinity
						};
					});
			if (isFCover) {
				const merged = withAreas.map((entry) => {
					let percent = String(parseFloat(String(entry.legend)) * 100);
					if (entry.min && entry.max) {
						if (entry.max === Infinity) {
							percent = `≥ ${entry.min * 100}`;
						} else if (entry.min === -Infinity) {
							percent = `≤ ${entry.max * 100}`;
						} else {
							percent = `≥ ${entry.min * 100} < ${entry.max * 100}`;
						}
					}
					return {
						...entry,
						legend_es: percent,
						legend_af: percent,
						legend_en: percent
					};
				});
				return {
					merged,
					style: convertMfwStyleToGeoStyler(merged, {
						dontShowArea: !histogram
					}),
					unit: getUnit(merged, layerFilter)
				};
			}
			return {
				merged: withAreas,
				style: convertMfwStyleToGeoStyler(withAreas, {
					dontShowArea: !histogram
				}),
				unit: getUnit(withAreas, layerFilter)
			};
		},
		[layerFilter, opts.customLegend, style]
	);

	const histogramQuery = useQuery({
		...createGeoTIFFHistogramQuery(
			{
				farmId: opts.data.farmId,
				layerFilterId: opts.data.layerFilterId,
				dateBucket: opts.data.dateBucket,
				availableLayerId: availableLayerId as number
			},
			api
		),
		select: selector,
		enabled: !!availableLayerId && !!layerFilter && isRGB === false && !!style
	});
	return histogramQuery;
}

export function createGeoTIFFHistogramQuery(
	{
		farmId,
		layerFilterId,
		dateBucket,
		availableLayerId
	}: {
		farmId: number;
		layerFilterId: number;
		dateBucket: boolean;
		availableLayerId: number;
	},
	api: Api
) {
	return queryOptions({
		queryKey: [
			'farm',
			farmId,
			'layerFilter',
			layerFilterId,
			'histogram',
			dateBucket,
			availableLayerId
		],
		queryFn: async () => {
			if (!availableLayerId) throw new Error('No available layer id');
			try {
				return await api.gis.histogram(availableLayerId, dateBucket);
			} catch (e) {
				if (isNetworkError(e)) {
					throw e;
				}
				return null;
			}
		}
	});
}

export function toOL(layerFilter: LayerFilter, style: RawLegendEntry[]) {
	if (!style || style.length === 0) return;
	const color = [
		// method
		'interpolate',
		// interpolation type
		['linear'],
		// interpolation value
		['band', 1],
		// color stops
		0,
		[0, 0, 0, 0]
	];
	const pallet = [] as (string | number)[];
	if (isYieldLayer(layerFilter)) {
		// because the no data value is 0, we need to set the first color stop to 0.01 to avoid the interpolation being transparent
		color.push(0.00001);
		color.push(style[0].colour?.toUpperCase() ?? 'rgba(0,0,0,0)');
	}
	for (const entry of style) {
		if (isMyProfitabilityLayer(layerFilter) || isYieldLayer(layerFilter)) {
			if ((!entry.max || !entry.min) && entry.legend) {
				const { min } = parseClassToFloatMinMax(String(entry.legend));
				if (min === 0) continue;
				pallet.push(min);
				pallet.push(entry.colour?.toUpperCase() ?? 'rgba(0,0,0,0)');
				continue;
			}
		}
		if (entry.min) {
			pallet.push(entry.min);
			pallet.push(entry.colour?.toUpperCase() ?? 'rgba(0,0,0,0)');
		}
	}
	const entry = style.at(-1);
	if (entry) {
		const { max } = parseClassToFloatMinMax(String(entry?.legend));
		if (!Number.isNaN(max)) {
			pallet.push(max);
			pallet.push(entry?.colour?.toUpperCase() ?? 'rgba(0,0,0,0)');
		}
	}
	const palletPairs = pallet.reduce(
		(acc, curr, i) => {
			if (i % 2 === 0) {
				acc.push([curr as number, pallet[i + 1] as string]);
			}
			return acc;
		},
		[] as [number, string][]
	);
	palletPairs.sort((a, b) => a[0] - b[0]);
	const palletFlat = palletPairs.flatMap((x) => x);
	color.push(...palletFlat);
	if (color.length <= 5) return;
	return color;
}

/**
 * COG styles are defined in discrete ranges, this function extracts the areas of each range from the histogram
 * @param style - RawLegend or RawLegendEntry[]
 * @param histogram - CogHistogram
 * @param pixelArea - number basically the area of a pixel in m^2
 */
export function extractAreasFromCogLegendAndHistogram(
	style: RawLegend | RawLegendEntry[],
	histogram: CogHistogram,
	pixelArea: number
) {
	if (!style || !histogram) return [];
	let legendArray: (RawLegendEntry & { min: number })[] = [];
	if (Array.isArray(style)) {
		legendArray = style.map((value) => {
			return {
				...value,
				colour: value.colour ?? value.color,
				min: value.min ?? -Infinity
			};
		});
	} else {
		legendArray = (
			Object.entries(style) as Array<[string, RawLegendEntry]>
		).map(([colour, value]: [string, RawLegendEntry]) => {
			return {
				...value,
				colour: colour,
				color: colour,
				min: value.min ?? -Infinity
			};
		});
	}
	const legendArrayWithMax = legendArray.map((value, i) => {
		const nextValue = legendArray[i + 1];
		return {
			...value,
			max: nextValue?.min ?? Infinity,
			areaCount: 0
		};
	});

	for (const [percentageString, count] of Object.entries(histogram.histogram)) {
		const percentageNumber = Number(percentageString);
		const entry = legendArrayWithMax.find((l) => {
			if (l.max === Infinity) return percentageNumber >= l.min;
			if (l.min === -Infinity) return percentageNumber < l.max;
			return percentageNumber >= l.min && percentageNumber < l.max;
		});
		if (entry) {
			entry.areaCount += count;
		}
	}
	const totalPixels = Object.values(histogram.histogram).reduce((acc, curr) => {
		return acc + curr;
	}, 0);

	return legendArrayWithMax.map((value) => {
		return {
			...value,
			area: value.areaCount * pixelArea,
			areaPercentage: (value.areaCount / totalPixels) * 100
		};
	});
}
