import type { FeatureCollection } from 'geojson';
import type { RawLegend } from '@agritechnovation/api';
import calcArea from '@turf/area';

export type LegendFromGeoJSONOptions = {
	labelKey?: string;
	colourKey?: string;
};

/**
 * Create a legend from a GeoJSON FeatureCollection
 * It calculates the area of each feature and assigns it to the legend entry. It also calculates the area percentage of each feature and assigns it to the legend entry. The function returns a RawLegend object.
 */
export function createLegendFromGeoJSON(
	geoJSON: FeatureCollection,
	opts?: LegendFromGeoJSONOptions
): RawLegend {
	const legend: RawLegend = {};
	const totalarea = calcArea(geoJSON);
	for (let i = 0; i < geoJSON.features.length; i++) {
		const feature = geoJSON.features[i];
		if (
			feature.geometry.type === 'LineString' ||
			feature.geometry.type === 'MultiLineString'
		) {
			continue;
		}
		const area = calcArea(feature); // same as using ol sphere area calculation method
		if (!feature.properties) {
			feature.properties = {};
		}
		feature.properties.area = area;
		feature.properties.areaHa = area / 10000;
		feature.properties.areaAcres = feature.properties?.areaHa * 2.47105;
		let label = feature.properties[opts?.labelKey ?? 'label'];
		if (typeof label === 'string') {
			label = feature.properties[opts?.labelKey ?? 'label']
				?.replace('&gt;', '>')
				.replace('&lt;', '<');
		} else {
			label = String(feature.properties[opts?.labelKey ?? 'label']);
		}
		if (label !== undefined && label !== 'undefined') {
			feature.properties[opts?.labelKey ?? 'label'] = label;
		}
		const colour = feature.properties?.[opts?.colourKey ?? 'colour']
			?.replace('0x', '#')
			.toUpperCase();
		if (legend[label]?.area) {
			// @ts-expect-error -- umm what?
			legend[label].area += area;
		} else {
			// @ts-expect-error -- shh
			legend[label] = {
				area: area
			};
		}
		legend[label] = {
			...(legend[label] ?? {}),
			legend: feature.properties?.[opts?.labelKey ?? 'label'],
			colour: colour,
			exactly: feature.properties?.[opts?.labelKey ?? 'label'],
			areaPercentage: ((legend[label]?.area ?? area) / totalarea) * 100
		};
	}
	return legend;
}
