import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	endOfMonth,
	endOfWeek,
	endOfYear,
	format,
	parse,
	startOfMonth,
	startOfWeek,
	startOfYear
} from 'date-fns';

const MONTHS = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december'
];

export function isMonth(date: string) {
	return MONTHS.some((m) => date.toLowerCase().includes(m));
}

export function isWeek(date: string) {
	return date.toLowerCase().includes('week');
}

export function isTertile(date: string) {
	return date.toLowerCase().includes('tertile');
}

export function isDateBucketString(date: string | undefined) {
	if (!date) return false;
	try {
		dateBucketToRange(date);
		return true;
	} catch {
		return false;
	}
}

export function dateBucketToRange(date: string) {
	if (isMonth(date)) {
		const [year, month] = date.split(' ').map((x) => x.trim());
		const monthIndex = MONTHS.indexOf(month.toLowerCase());
		const jsDate = parse(`${year}-${monthIndex + 1}`, 'yyyy-MM', new Date());
		return {
			from: startOfMonth(jsDate),
			to: endOfMonth(jsDate)
		};
	}
	if (isWeek(date)) {
		const [year, week] = date.split('week').map((x) => x.trim());
		const jsDate = parse(`${week}`, 'w', parse(`${year}`, 'yyyy', new Date()));
		return {
			from: startOfWeek(jsDate),
			to: endOfWeek(jsDate)
		};
	}

	if (isTertile(date)) {
		const year = date.slice(0, 4);
		const tertile = parseInt(date.slice(-1));
		if (tertile < 1 || tertile > 3) {
			throw new Error(`Invalid MFW date bucket string: ${date}`);
		}

		const tertileStart = [0, 4, 8];
		const tertileEnd = [3, 7, 11];
		let fromMonth: string | number = tertileStart[tertile - 1] + 1;
		let toMonth: string | number = tertileEnd[tertile - 1] + 1;

		fromMonth = fromMonth < 10 ? `0${fromMonth}` : fromMonth;
		toMonth = toMonth < 10 ? `0${toMonth}` : toMonth;
		return {
			from: parse(`${year}-${fromMonth}`, 'yyyy-MM', new Date()),
			to: endOfMonth(parse(`${year}-${toMonth}`, 'yyyy-MM', new Date()))
		};
	}

	if (date.length === 4) {
		const jsDate = parse(`${date}`, 'yyyy', new Date());
		return {
			from: startOfYear(jsDate),
			to: endOfYear(jsDate)
		};
	}
	throw new Error(`Invalid MFW date bucket string: ${date}`);
}

export function toYYYYMMDD(date: Date) {
	return format(date, 'yyyy-MM-dd');
}

export function parseYYYYMMDD(date: string) {
	return parse(date, 'yyyy-MM-dd', new Date());
}

export function dateIsBetween(date: Date | string, mfwDate: string) {
	const parsed = typeof date === 'string' ? parseYYYYMMDD(date) : date;
	const { from, to } = dateBucketToRange(mfwDate);
	return parsed.getTime() >= from.getTime() && parsed.getTime() <= to.getTime();
}

export function compareMFWDates(a: string, b: string) {
	if (isMonth(a) && isMonth(b)) {
		const [year, month] = a.split(' ').map((x) => x.trim());
		const [year2, month2] = b.split(' ').map((x) => x.trim());
		const monthIndex = MONTHS.indexOf(month.toLowerCase());
		const monthIndex2 = MONTHS.indexOf(month2.toLowerCase());
		const date = parse(`${year}-${monthIndex + 1}`, 'yyyy-MM', new Date());
		const date2 = parse(`${year2}-${monthIndex2 + 1}`, 'yyyy-MM', new Date());
		return Math.sign(date.getTime() - date2.getTime());
	}
	if (isWeek(a) && isWeek(b)) {
		const [year, week] = a.split('week').map((x) => x.trim());
		const [year2, week2] = b.split('week').map((x) => x.trim());
		const weekAsDate = parse(
			`${week}`,
			'w',
			parse(`${year}`, 'yyyy', new Date())
		);
		const weekAsDate2 = parse(
			`${week2}`,
			'w',
			parse(`${year2}`, 'yyyy', new Date())
		);
		return Math.sign(weekAsDate.getTime() - weekAsDate2.getTime());
	}
	return a.localeCompare(b);
}
/**
 * Converts a date string to a PPM date bucket string
 * @param date A date string in the format 'yyyy-MM-dd'
 * @returns A PPM date bucket string in the format 'yyyy week w' e.g. '2021 week 1'
 */
export function toPPMDateBucket(
	date: string,
	weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 0
) {
	const jsDate = parseYYYYMMDD(date);
	const week = format(jsDate, 'w', {
		weekStartsOn
	});
	const year = format(jsDate, 'yyyy');
	return `${year} week ${week}`;
}

export function fromPPMDateBucket(
	date: string,
	weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 0
): Interval {
	const [year, week] = date.split(' week ');
	const jsDate = parse(
		`${week}`,
		'w',
		parse(`${year}`, 'yyyy', new Date(), { weekStartsOn }),
		{
			weekStartsOn
		}
	);
	const start = startOfWeek(jsDate, {
		weekStartsOn
	});
	const end = endOfWeek(jsDate, {
		weekStartsOn
	});

	return { start, end };
}

export function formatDateForDisplay(date: Date): string {
	const now = new Date();
	const minutesDiff = differenceInMinutes(now, date);
	const hoursDiff = differenceInHours(now, date);
	const daysDiff = differenceInDays(now, date);

	if (minutesDiff < 1) {
		return 'less than a minute ago';
	} else if (minutesDiff < 60) {
		return `${minutesDiff} minutes ago`;
	} else if (hoursDiff < 24) {
		const hourstr = `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''}`;
		if (minutesDiff % 60 === 0) {
			return `${hourstr} ago`;
		}
		return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''}, ${minutesDiff % 60} minutes ago`;
	} else if (daysDiff === 1) {
		return `Yesterday at ${format(date, 'HH:mm')}`;
	} else {
		return `${daysDiff} days ago`;
	}
}
