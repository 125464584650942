import type VectorLayer from 'ol/layer/Vector';
import type { Extent } from 'ol/extent';
import { containsExtent, intersects, isEmpty } from 'ol/extent';
import { useMapProjection, useMapViewExtent } from './use-map-view';
import { getZoomExtent } from '../utils/get-zoom-extent';
import type { FeatureLike } from 'ol/Feature';

export function useIsInView<S extends FeatureLike>(layer?: VectorLayer<S>) {
	const viewExtent = useMapViewExtent();
	const projection = useMapProjection();
	if (!layer) return false;
	return isInView(getZoomExtent(layer, projection), viewExtent);
}

export function isInView(layerExtent: Extent | undefined, mapExtent: Extent) {
	if (!layerExtent) return false;
	if (isEmpty(layerExtent) || isEmpty(mapExtent)) {
		return false;
	}
	return (
		intersects(mapExtent, layerExtent) || containsExtent(mapExtent, layerExtent)
	);
}
