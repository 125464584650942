import { useFarmBoundaryGeojson } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react/src/layers/vector/use-vector-layer';
import { useBoundaryConfig } from './boundary.config';
import { useParseGeostyler } from '../use-parse-geostyler';
import { useCallback } from 'react';

export const useFarmBoundaryLayer = (
	opts: {
		addToMap?: boolean;
		visible?: boolean;
	} & { id: number; zoomPadding?: number[] }
) => {
	const { id } = opts;
	const config = useBoundaryConfig();
	const { data: style } = useParseGeostyler(config.style);
	const { data, isPending, isError, refetch } = useFarmBoundaryGeojson(id);
	const [layer, zoomTo, isOnMap] = useVectorLayer({
		data: data,
		properties: {
			mfw_uuid: `farm-boundary-${id}`,
			farmId: id,
			type: 'boundary'
		},
		style: style?.output,
		addToMap: opts?.addToMap,
		visible: opts?.visible
	});

	const handleZoomTo = useCallback(() => {
		if (isOnMap) {
			const zoomedCount = layer?.get('zoomed_count') || 0;
			layer.set('zoomed_count', zoomedCount + 1);
			zoomTo(opts.zoomPadding);
		}
	}, [isOnMap, layer, opts.zoomPadding, zoomTo]);

	return [
		{ data, isPending, isError, refetch },
		isOnMap ? layer : null,
		handleZoomTo,
		isOnMap
	] as const;
};
