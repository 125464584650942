import { useQuery } from '@tanstack/react-query';
import {
	createFarmAvailableLayersQuery,
	getMyYieldClassifications,
	isYieldLayer,
	useApi,
	useLayerFilter,
	useUser
} from '@agritechnovation/api-query';
import type { LayerDataOptions } from '../data';
import { useCallback } from 'react';
import type { AvailableLayerObservationWithFarmId } from '@agritechnovation/api';

export type LayerAvailableLayersOptions = LayerDataOptions & {
	variant?: number;
	depth?: string | null | undefined;
};

export function useLayerAvailableLayers({
	farmId,
	layerFilterId,
	dates,
	dateBucket,
	variant,
	depth
}: LayerAvailableLayersOptions) {
	const { data: user } = useUser();
	const { data: layerFilter } = useLayerFilter(layerFilterId);
	const api = useApi();
	const select = useCallback(
		(data: AvailableLayerObservationWithFarmId[]) => {
			if (!data || !layerFilter) return [];
			if (dates && dates?.length > 0) {
				const included = data.filter((d) => {
					if (depth) {
						return (
							dates.includes(dateBucket ? d.date_bucket : d.date) &&
							d.itest_soil_depth_value === depth
						);
					}
					return dates.includes(dateBucket ? d.date_bucket : d.date);
				});
				if (isYieldLayer(layerFilter)) {
					const classifications = getMyYieldClassifications(included, {
						variant: variant ?? 8,
						dates: included.map((d) => d.date_bucket ?? d.date),
						dateBucket
					});
					return classifications;
				}
				if (included.length === 0 && data.length >= 1 && !depth) {
					const mostRecent = data.at(-1);
					if (mostRecent) {
						return [mostRecent];
					}
				}
				return included;
			}

			const mostRecent = data.at(-1);
			if (dateBucket && mostRecent) {
				return data.filter((d) => d.date_bucket === mostRecent.date_bucket);
			}
			if (mostRecent) {
				return [mostRecent];
			}
			return [];
		},
		[dateBucket, dates, depth, layerFilter, variant]
	);
	return useQuery({
		...createFarmAvailableLayersQuery(api, {
			farmId,
			layerFilterId,
			enabled: !!user && !!layerFilter
		}),
		placeholderData: [],
		select
	});
}
