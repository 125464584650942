export function parseClassToFloatMinMax(label?: string) {
	if (!label) {
		return {
			min: 0,
			max: 0
		};
	}
	if (!label.includes('-')) {
		return {
			min: parseFloat(label),
			max: parseFloat(label)
		};
	}
	const [min, max] = label.split('-');
	return {
		min: parseFloat(min),
		max: parseFloat(max)
	};
}
