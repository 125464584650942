import { useMemo } from 'react';
import type { Style, TextSymbolizer } from 'geostyler-style';
import { B64 } from '@agritechnovation/utils';

export type BlockConfigOpts = {
	measuringSystem: 'metric' | 'imperial';
	showLabels: boolean;
	forceLabels: boolean;
	showAreas: boolean;
	color?: string;
};

export function useBoundaryConfig() {
	return useMemo(() => BoundaryConfig, []);
}

export function useBlockConfig(opts?: {
	measuringSystem?: 'metric' | 'imperial';
	showLabels?: boolean;
	forceLabels?: boolean;
	showAreas?: boolean;
	color?: string;
}) {
	return useMemo(
		() =>
			makeBlockConfig({
				measuringSystem: opts?.measuringSystem ?? 'metric',
				showLabels: opts?.showLabels ?? true,
				forceLabels: opts?.forceLabels ?? false,
				color: opts?.color,
				showAreas: opts?.showAreas ?? true
			}),
		[
			opts?.color,
			opts?.forceLabels,
			opts?.measuringSystem,
			opts?.showAreas,
			opts?.showLabels
		]
	);
}

export const BoundaryConfig = {
	id: 'boundary',
	style: {
		name: 'Boundary Base Style',
		rules: [
			{
				name: 'Boundary',
				symbolizers: [
					{
						kind: 'Fill',
						color: '#000000',
						fillOpacity: 0,
						outlineWidth: 1,
						outlineWidthUnit: 'px',
						outlineColor: '#000000',
						outlineDasharray: [4.5, 5]
					}
				]
			}
		]
	} satisfies Style
};

function makeBlockConfig({
	measuringSystem,
	showLabels,
	showAreas,
	color,
	forceLabels
}: BlockConfigOpts) {
	const areaLabelSymbolizer: TextSymbolizer = {
		kind: 'Text',
		font: ['Arial'],
		size: 12,
		color: '#000000',
		haloColor: '#FFFFFF',
		haloWidth: 5,
		label: measuringSystem === 'metric' ? '{{area_ha}} ha' : '{{area_ac}} ac',
		offset: [0, 20],
		allowOverlap: false
	};
	const nameLabelSymbolizer: TextSymbolizer = {
		kind: 'Text',
		font: ['Arial'],
		size: 12,
		color: '#000000',
		haloColor: '#FFFFFF',
		haloWidth: 5,
		label: '{{name}}',
		allowOverlap: forceLabels
	};
	const labelSymbolizers = [];

	if (showAreas) {
		labelSymbolizers.push(areaLabelSymbolizer);
	}
	if (showLabels) {
		labelSymbolizers.push(nameLabelSymbolizer);
	}
	const style = {
		id: 'block',
		style: {
			name: 'Blocks',
			rules: [
				{
					name: 'Block Line',
					symbolizers: [
						{
							kind: 'Fill',
							outlineColor: color ?? '#AEAEAE',
							outlineWidth: 2,
							color: '#AEAEAE',
							fillOpacity: 0
						}
					]
				},
				{
					name: 'Block Label',
					symbolizers: labelSymbolizers
				}
			],
			metadata: {
				classified: false
			}
		} satisfies Style
	};
	// when this style is converted into an openlayers style (async) the name is used as the query key
	style.style.name = B64.encode(JSON.stringify(style.style));
	return style;
}
