import type { FindOlLayerPredicate } from '@agritechnovation/ol-react';
import { useFindOlLayer, useObserveOlLayer } from '@agritechnovation/ol-react';
import { useCallback } from 'react';
import { extractMetaFromOlLayer } from './layers';

export function useLayerInfo(uuid: string) {
	const find: FindOlLayerPredicate = useCallback(
		(layer) => layer.get('mfw_uuid') === uuid,
		[uuid]
	);
	const layer = useFindOlLayer(find);
	const [observedLayer] = useObserveOlLayer(layer);
	if (!observedLayer) return null;
	return extractMetaFromOlLayer(observedLayer);
}
