import type { LayerOptions } from '../../../layer-options';
import { useITESTMicroLifeReports } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { useITESTFilterGroupStyle } from '../helpers/itest.point-style';
import { useLayerAvailableLayers } from '../../../helpers';
import { useMapLayersContext } from '../../../../context/map-layers.context';

export type ITESTMicroLifeLayerOptions = LayerOptions;

export function useITESTMicroLifeLayer(opts: ITESTMicroLifeLayerOptions) {
	const { data: availableLayers = [] } = useLayerAvailableLayers({
		farmId: opts.data.farmId,
		layerFilterId: opts.data.layerFilterId,
		dates: opts.data.dates,
		dateBucket: opts.data.dateBucket,
		depth: null
	});

	const from = opts.data.dateBucket
		? availableLayers.at(0)?.date_bucket
		: availableLayers.at(0)?.date;

	const to = opts.data.dateBucket
		? availableLayers.at(-1)?.date_bucket
		: availableLayers.at(-1)?.date;

	const { data } = useITESTMicroLifeReports({
		farmId: opts.data.farmId,
		from,
		to
	});

	const { getIconPath } = useMapLayersContext();

	const style = useITESTFilterGroupStyle(
		getIconPath('/ITEST_MicroLife_Icon_pin.png'),
		opts.style?.displayKey
	);

	useVectorLayer({
		data: data,
		style,

		zIndex: opts.render.zIndex ? opts.render.zIndex + 100 : 250, // point layers higher
		opacity: opts.render.opacity,
		declutter: false,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			layerFilterId: opts.data.layerFilterId
		}
	});

	return null;
}
