import { useCallback, useEffect, useMemo } from 'react';
import {
	isMyProfitabilityLayer,
	parseClassToFloatMinMax,
	useLayerFilter
} from '@agritechnovation/api-query';
import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import { useGeoJSONVTLayer } from '@agritechnovation/ol-react/src/layers/geojson-vt/geojson-vt';
import type { LayerFilter, RawLegend } from '@agritechnovation/api';
import type { StyleMergeFN } from '../../../data';
import { useGeoJSONPolygonData, useGeoJSONPolygonStyle } from '../../../data';
import { useFindLayer } from '../../../../helpers';
import { getProfitClass, fullProfitColors } from '../myyield.utils';
import type { MyYieldLayerOptions } from '../my-yield-layer';
import { useLayerAvailableLayers } from '../../../helpers';
import { useParseGeostyler } from '../../../../use-parse-geostyler';

export function useMyYieldGeoJSONLayer(opts: MyYieldLayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	const { data: dates = [] } = useLayerAvailableLayers({
		...opts.data,
		variant: opts.yield.variant
	});

	const selector = useCallback(
		(data: FeatureCollection<Polygon | MultiPolygon>) => {
			if (layerFilter && isMyProfitabilityLayer(layerFilter)) {
				const { max: maxTons } = parseClassToFloatMinMax(
					data.features.at(-1)?.properties?.label
				);
				const { min: minTons } = parseClassToFloatMinMax(
					data.features[0]?.properties?.label
				);
				for (const feature of (data as FeatureCollection).features) {
					if (feature.properties) {
						const profit = getProfitClass(feature.properties.label, {
							maxTons,
							minTons,
							sellingPricePerUnit: opts.yield.sell ?? 0,
							costPerHa: opts.yield.cost ?? 0
						});
						if (profit) {
							feature.properties.profitLabel = profit.label;
							feature.properties.profitColour = profit.color;
						}
					}
				}
			}

			return data;
		},
		[layerFilter, opts.yield.cost, opts.yield.sell]
	);
	const profitLayer = layerFilter && isMyProfitabilityLayer(layerFilter);

	const { data: raw, isPending } = useGeoJSONPolygonData(
		opts.data,
		opts.yield.variant
	);
	const data = raw && profitLayer ? selector(raw) : raw;

	const mergeer: StyleMergeFN = useCallback((legend, _) => {
		const allProfitClasses = fullProfitColors.map((x) => [x.color, x.label]);
		const profitLegend = {} as RawLegend;
		for (const [color, label] of allProfitClasses) {
			const inLegend = legend[label];
			if (inLegend) {
				profitLegend[label] = inLegend;
			} else {
				profitLegend[label] = {
					label: label,
					legend: label,
					color: color,
					colour: color,
					area: 0,
					areaPercentage: 0
				};
			}
		}
		return Array.from(Object.values(profitLegend));
	}, []);

	const { data: style } = useGeoJSONPolygonStyle(
		data ?? { type: 'FeatureCollection', features: [] },
		{
			...opts.data,
			conversionOptions: {
				noOutline: true,
				labelKey: isMyProfitabilityLayer(layerFilter as LayerFilter)
					? 'profitLabel'
					: undefined
			},
			fromGeoJSONOptions: isMyProfitabilityLayer(layerFilter as LayerFilter)
				? {
						labelKey: 'profitLabel',
						colourKey: 'profitColour'
					}
				: undefined,
			merger: profitLayer ? mergeer : undefined
		}
	);

	const { data: olStyle } = useParseGeostyler(style?.style);
	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);
	const availableLayerId = useMemo(() => dates?.at(0)?.id, [dates]);

	const { maxTons, minTons } = useMemo(() => {
		const maxTons =
			parseClassToFloatMinMax(style?.merged?.at(-1)?.legend as string).max || 0;
		const minTons =
			parseClassToFloatMinMax(style?.merged?.at(0)?.legend as string).min || 0;
		return {
			maxTons,
			minTons
		};
	}, [style?.merged]);

	const [layer] = useGeoJSONVTLayer({
		initialLayer,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex,
		data: data as FeatureCollection,
		style: olStyle?.output,
		opacity: opts.render.opacity,
		addToMap: true,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			layerFilterId: opts.data.layerFilterId,
			dates: opts.data.dates,
			// the backend seems not to care most of the time, it seems it just uses the al to determine the farm id, layerfilter id and dates
			// but we sometimes need to pass it to the backend from the layer properties (e.g. LayerInfo component)
			available_layer_id: availableLayerId
		}
	});

	useEffect(() => {
		layer.set('maxTons', maxTons);
		layer.set('minTons', minTons);
	}, [layer, maxTons, minTons]);

	useEffect(() => {
		if (layer) {
			layer.set('mfw_uuid', opts.layer.uniqueId);
			layer.set('farmId', opts.data.farmId);
			layer.set('layerFilterId', opts.data.layerFilterId);
			layer.set('dates', opts.data.dates);
			// layer.set('available_layer_id', availableLayerId);
		}
	}, [
		layer,
		opts.data.dates,
		opts.data.farmId,
		opts.data.layerFilterId,
		opts.layer.uniqueId
	]);

	return [{ data, isPending }, layer] as const;
}
