import { useCallback, useEffect, useMemo, useState } from 'react';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import type { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import type { StyleLike } from 'ol/style/Style';
import type { FlatStyleLike } from 'ol/style/flat';
import { GeoJSON } from 'ol/format';
import { useMap } from '../../map';
import type Feature from 'ol/Feature';
import { getZoomExtent } from '../../utils';
import { useMapProjection } from '../../hooks';

export type UseVectorLayerOpts<
	P extends {
		[key: string]: unknown;
	}
> = {
	initialLayer?:
		| VectorLayer<Feature>
		| (() => VectorLayer<Feature> | undefined);
	data: GeoJSONFeatureCollection | undefined;
	style?: StyleLike | FlatStyleLike | undefined;
	visible?: boolean;
	opacity?: number;
	/**
	 * Ol layer properties, not updated if changed, other option changes will update the layer
	 */
	properties?: P;
	featureProjection?: string;
	zIndex?: number;
	declutter?: boolean;
	/**
	 * Should the layer be added to the map on mount
	 * */
	addToMap?: boolean;
};

export function useVectorLayer<
	P extends {
		[key: string]: unknown;
	}
>({
	initialLayer,
	properties,
	data,
	opacity = 1,
	visible,
	style,
	featureProjection = 'EPSG:4326',
	zIndex = 200,
	declutter = true,
	addToMap = true
}: UseVectorLayerOpts<P>) {
	const map = useMap();
	const [isOnMap, setIsOnMap] = useState(false);

	const [layer] = useState<VectorLayer<Feature>>(() => {
		let layer = initialLayer;
		if (typeof layer === 'function') {
			layer = layer();
		}
		if (layer) return layer;
		return new VectorLayer({
			zIndex,
			source: new VectorSource<Feature>({
				features: []
			}),
			properties,
			declutter
		});
	});

	const projection = useMapProjection();

	useEffect(() => {
		const geojson = data;
		const source = layer.getSource();
		if (source) {
			source.clear();
			if (geojson && geojson.features?.length > 0) {
				source.addFeatures(
					new GeoJSON({
						dataProjection: 'EPSG:4326',
						featureProjection: projection
					}).readFeatures(geojson) as Feature[]
				);
				setIsOnMap(true);
			}
		}
	}, [data, featureProjection, layer, projection]);

	useEffect(() => {
		if (visible === false) {
			layer.setVisible(false);
		} else {
			layer.setVisible(true);
		}
	}, [visible, layer]);

	useEffect(() => {
		if (style) {
			layer.setStyle(style);
		}
	}, [style, layer]);

	useEffect(() => {
		if (addToMap) {
			const hasLayer = map.getLayers().getArray().includes(layer);
			if (!hasLayer) {
				map.addLayer(layer);
			}
		}
		return () => {
			map.removeLayer(layer);
		};
	}, [addToMap, layer, map]);

	useEffect(() => {
		layer.setZIndex(zIndex);
	}, [zIndex, layer]);

	useEffect(() => {
		layer.setOpacity(opacity);
	}, [opacity, layer]);

	const zoomTo = useCallback(
		(padding?: number[]) => {
			const extent = getZoomExtent(layer, projection);
			if (extent) {
				map.getView().fit(extent, {
					padding: padding ?? [100, 50, 50, 50],
					duration: 100
				});
			}
		},
		[layer, projection, map]
	);

	const source = useMemo(() => layer.getSource() as VectorSource, [layer]);

	return [layer, zoomTo, isOnMap, source] as const;
}
