import { useLayerFilter } from '@agritechnovation/api-query';
import { useCallback, useMemo } from 'react';
import { useVectorLayer } from '@agritechnovation/ol-react';
import {
	type StyleMergeFN,
	useGeoJSONPolygonData,
	useGeoJSONPolygonStyle
} from '../../../data';
import { useFindLayer } from '../../../../helpers';
import {
	LAYERS_WITHOUT_POLY_LABELS,
	SOIL_CLASSIFICATION_UNIQUE_IDS_WITH_TRIMMED_CLASSES
} from '../constants';
import { useParseGeostyler } from '../../../../use-parse-geostyler';
import { useSyncProperties } from '../../default';
import type { TextSymbolizer } from 'geostyler-style';
import { mergeRawLegends } from '../../../helpers';
import type { MySoilClassificationOptions } from '../my-soil-classification-layer';

const soilClassText: TextSymbolizer = {
	kind: 'Text',
	label: '{{legend}}',
	size: 9,
	fontWeight: 'bold',
	font: ['Arial'],
	color: '#FFFFFF',
	haloColor: '#333333',
	haloWidth: 5,
	haloOpacity: 1,
	opacity: 1,
	maxAngle: 0,
	allowOverlap: true,
	avoidEdges: false,
	anchor: 'center'
};

export function useMySoilClassificationLayer(
	opts: MySoilClassificationOptions
) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);

	// show polygon labels for all layers except those in LAYERS_WITHOUT_POLY_LABELS
	const showPolygonLabels = useMemo(() => {
		if (!opts.soilClass.showPointLabels) return false;
		if (!layerFilter) return false;
		const withoutLabel = LAYERS_WITHOUT_POLY_LABELS.some((x) => {
			return layerFilter.unique_id.includes(x);
		});
		return !withoutLabel;
	}, [layerFilter, opts.soilClass.showPointLabels]);

	// show single label per polygon for all layers except those in LAYERS_WITHOUT_POLY_LABELS
	const singleLabelPerPolygon = useMemo(() => {
		if (!layerFilter) return false;
		if (layerFilter.unique_id.includes('contours')) return false;
		return !LAYERS_WITHOUT_POLY_LABELS.some((x) =>
			layerFilter.unique_id.includes(x)
		);
	}, [layerFilter]);

	const { data } = useGeoJSONPolygonData(opts.data);

	// some layers need a custom merger, so that the api stored style/legend is not merged with the one created from the geojson
	const shouldUseCustomMerger = useMemo(() => {
		if (!layerFilter) return false;
		return SOIL_CLASSIFICATION_UNIQUE_IDS_WITH_TRIMMED_CLASSES.includes(
			<string>layerFilter?.unique_id
		);
	}, [layerFilter]);

	const styleMerger: StyleMergeFN = useCallback((fromGeojson, _fromMFW) => {
		return mergeRawLegends(_fromMFW, fromGeojson).filter((e) =>
			e.area ? e.area > 0 : false
		);
	}, []);

	const { data: styleConfig } = useGeoJSONPolygonStyle(data, {
		...opts.data,
		merger: shouldUseCustomMerger ? styleMerger : undefined,
		conversionOptions: {
			textSymbolizer: showPolygonLabels ? soilClassText : undefined
		}
	});

	const { data: parsedStyle } = useParseGeostyler(styleConfig?.style, {
		singleLabelPerPolygon: showPolygonLabels && singleLabelPerPolygon
	});

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	const properties = useMemo(() => {
		return {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId,
			unit: styleConfig?.unit
		};
	}, [
		opts.data.dates,
		opts.data.farmId,
		opts.data.layerFilterId,
		opts.layer.uniqueId,
		styleConfig?.unit
	]);

	const [layer] = useVectorLayer({
		initialLayer,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex,
		data,
		style: parsedStyle?.output,
		opacity: opts.render.opacity,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			product: 'soilclass'
		}
	});

	useSyncProperties(layer, properties);

	return [layer, data, styleConfig?.merged] as const;
}
