import type TileSource from 'ol/source/Tile';
import type { OSM, XYZ } from 'ol/source';
import type { Coordinate } from 'ol/coordinate';
import type { Projection } from 'ol/proj';
import type TileLayer from 'ol/layer/Tile';

export async function getTilePreview(
	layer: TileLayer<TileSource | XYZ | OSM>,
	center: Coordinate,
	resolution: number
): Promise<string> {
	return new Promise((resolve, reject) => {
		const source = layer.getSource();
		if (!source) return reject('No source');
		if (!center) center = [0, 0];
		if (!resolution) resolution = 150;
		const projection = source.getProjection();
		if (!projection) return reject('No projection');
		const grid = source.getTileGrid();
		if (!grid) {
			layer.once('sourceready', () => {
				const grid = source.getTileGrid();
				if (!grid) {
					return reject('No grid');
				}
				const coord = grid?.getTileCoordForCoordAndResolution(
					center,
					resolution
				);
				if (!coord) {
					console.error('No coord');
				}
				resolve(getTilePreviewImageUrl(source, coord, projection));
			});
			return;
		}
		const coord = grid?.getTileCoordForCoordAndResolution(center, resolution);
		if (!coord) {
			console.error('No coord');
		}

		return resolve(getTilePreviewImageUrl(source, coord, projection));
	});
}

function getTilePreviewImageUrl(
	source: TileSource | XYZ | OSM,
	coord: Coordinate,
	projection: Projection
) {
	// @ts-expect-error - getTileUrlFunction is private
	// eslint-disable-next-line @typescript-eslint/ban-types
	const tileUrlFunction = source.getTileUrlFunction() as Function | undefined;
	if (typeof tileUrlFunction !== 'function') return '';
	return tileUrlFunction.call(source, coord, projection);
}
