import type VectorLayer from 'ol/layer/Vector';
import { useMap } from '../../map';
import { useCallback, useEffect } from 'react';
import type RenderEvent from 'ol/render/Event';
import { getRenderPixel } from 'ol/render';
import { useCompareContext } from './compare.context';
import type { FeatureLike } from 'ol/Feature';

export function useCompare<S extends FeatureLike>(
	left?: VectorLayer<S>,
	right?: VectorLayer<S>
) {
	const map = useMap();
	const { getPositionPx } = useCompareContext();
	const onPreRender = useCallback(
		(opts?: { left?: boolean; bothWebGl?: boolean; isRightWebGL?: boolean }) =>
			function (event: RenderEvent) {
				const ctx = event.context as CanvasRenderingContext2D;
				if (!ctx) return;
				const mapSize = map.getSize();
				if (!mapSize) return;
				const width = getPositionPx();
				// @ts-expect-error -- private property exists
				const devicePixelRatio = map.pixelRatio_;
				const bbox = opts?.left
					? {
							tl: [0, 0],
							tr: [width, 0],
							bl: [0, mapSize[1]],
							br: [width, mapSize[1]]
						}
					: {
							tl: [width, 0],
							tr: [mapSize[0], 0],
							bl: [width, mapSize[1]],
							br: [mapSize[0], mapSize[1]]
						};
				const tl = getRenderPixel(event, bbox.tl);
				const tr = getRenderPixel(event, bbox.tr);
				const bl = getRenderPixel(event, bbox.bl);
				const br = getRenderPixel(event, bbox.br);
				if (ctx instanceof WebGLRenderingContext) {
					if (opts?.left) {
						if (!opts.bothWebGl) {
							ctx.clear(ctx.COLOR_BUFFER_BIT);
						}
						ctx.enable(ctx.SCISSOR_TEST);
						ctx.scissor(
							bl[0],
							bl[1],
							width * devicePixelRatio,
							mapSize[1] * devicePixelRatio
						);
					} else {
						ctx.enable(ctx.SCISSOR_TEST);
						ctx.clear(ctx.COLOR_BUFFER_BIT);
						ctx.scissor(
							bl[0],
							bl[1],
							(mapSize[0] - width) * devicePixelRatio,
							mapSize[1] * devicePixelRatio
						);
					}
					return;
				}
				if (opts?.isRightWebGL) {
					ctx.clearRect(0, 0, br[0], br[1]);
				}
				ctx.save();
				ctx.beginPath();
				ctx.moveTo(tl[0], tl[1]);
				ctx.lineTo(bl[0], bl[1]);
				ctx.lineTo(br[0], br[1]);
				ctx.lineTo(tr[0], tr[1]);
				ctx.closePath();
				ctx.clip();
			},
		[getPositionPx, map]
	);

	const onPostRender = useCallback(function (event: RenderEvent) {
		const ctx = event.context as CanvasRenderingContext2D;
		if (!ctx) {
			return;
		}
		if (ctx instanceof WebGLRenderingContext) {
			ctx.disable(ctx.SCISSOR_TEST);
			return;
		}
		ctx.restore();
	}, []);

	useEffect(() => {
		const isLeftWebGL = left?.get('type') === 'cog';
		const isRightWebGL = right?.get('type') === 'cog';
		const bothWebGl = isLeftWebGL && isRightWebGL;

		const leftHandler = onPreRender({ left: true, bothWebGl, isRightWebGL });
		left?.on('prerender', leftHandler);
		left?.on('postrender', onPostRender);

		const rightHandler = onPreRender({ isRightWebGL, bothWebGl });
		right?.on('prerender', rightHandler);
		right?.on('postrender', onPostRender);
		map.render();
		return () => {
			left?.un('prerender', leftHandler);
			left?.un('postrender', onPostRender);
			right?.un('prerender', rightHandler);
			right?.un('postrender', onPostRender);
			map.render();
		};
	}, [getPositionPx, left, onPostRender, onPreRender, right, map]);
}
