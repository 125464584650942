import { useLayerFilter } from '@agritechnovation/api-query';
import type { LayerOptions } from '../../layer-options';
import { DefaultPolygonLayer } from './default-polygon-layer';
import { isPointLayer } from '../../helpers/layer-type';
import { DefaultPointLayer } from './default-point-layer';
import { DefaultGeoTIFFLayer } from './default-geotiff-layer';
import { DefaultLineLayer } from './default.line-layer';

export function DefaultLayer(opts: LayerOptions) {
	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	if (!layerFilter) return null;
	if (layerFilter.layer_type === 'geojson') {
		return <DefaultPolygonLayer {...opts} />;
	}
	if (isPointLayer(layerFilter)) {
		return <DefaultPointLayer {...opts} />;
	}
	if (layerFilter.layer_type === 'cog') {
		const isRGB = layerFilter.unique_id.includes('fieldimage');
		return (
			<DefaultGeoTIFFLayer
				key={`${isRGB}-${opts.layer.uniqueId}-${opts.data.farmId}-${opts.data.layerFilterId}-${opts.data.dates.join(',')}-${opts.data.dateBucket}-`}
				{...opts}
			/>
		);
	}
	if (layerFilter.layer_type === 'Line') {
		return <DefaultLineLayer {...opts} />;
	}
	return null;
}
