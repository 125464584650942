import { usePPMTraps } from './use-ppm-traps';
import { usePpmBlocks } from './use-ppm-blocks';
import type { LayerOptions } from '../../layer-options';
import { useGeoJSONPolygonData, useGeoJSONPolygonStyle } from '../../data';
import { useFindLayer } from '../../../helpers';
import { useVectorLayer } from '@agritechnovation/ol-react';
import { useLayerAvailableLayers } from '../../helpers';
import { useParseGeostyler } from '../../../use-parse-geostyler';

export type PPMOptions = {
	showTraps?: boolean;
	showBlockLabels?: boolean;
	showBlocks?: boolean;
};

export type PpmLayerOptions = LayerOptions & {
	ppm: PPMOptions;
};

export function usePpmLayer(opts: PpmLayerOptions) {
	const { data: availableLayers = [] } = useLayerAvailableLayers({
		farmId: opts.data.farmId,
		layerFilterId: opts.data.layerFilterId,
		dates: opts.data.dates,
		dateBucket: opts.data.dateBucket,
		depth: null
	});
	const { data } = useGeoJSONPolygonData(opts.data);

	const { data: style } = useGeoJSONPolygonStyle(data, {
		...opts.data,
		conversionOptions: {
			allowedNegativeMaxValues: true
		}
	});
	const { data: parsed } = useParseGeostyler(style?.style);
	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);
	const [layer] = useVectorLayer({
		initialLayer,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex,
		opacity: opts.render.opacity,
		data,
		style: parsed?.output,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId,
			available_layer_id: availableLayers?.[0]?.id
		}
	});

	usePpmBlocks({
		...opts,
		zIndex: opts.render.zIndex ? opts.render.zIndex + 1 : 201
	});
	usePPMTraps({
		...opts,
		siblingLayer: layer
	});
}
