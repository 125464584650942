import { create } from 'zustand';
import type { IMapLayersContext } from './map-layers.context';

export type MapLayersContextFactoryArgs = {
	onAddError: (error: Error) => void;
	staticIconsPath: string;
};

export function mapLayersContextFactory({
	onAddError,
	staticIconsPath
}: MapLayersContextFactoryArgs) {
	return create<IMapLayersContext>((set, get) => ({
		errors: [],
		staticIconsPath,
		addError: (layerId, error, reset) => {
			set((state) => {
				const existingError = state.errors.find((e) => e.layerId === layerId);
				if (existingError) {
					existingError.time = Date.now();
					existingError.error = error;
					existingError.reset = function () {
						state.removeError(layerId);
						reset();
					};
					return {
						...state,
						errors: [...state.errors]
					};
				}
				onAddError(error);
				return {
					...state,
					errors: [
						...state.errors,
						{
							layerId,
							error,
							time: Date.now(),
							reset: function () {
								state.removeError(layerId);
								reset();
							}
						}
					]
				};
			});
		},
		removeError: (layerId) => {
			set((state) => ({
				errors: state.errors.filter((e) => e.layerId !== layerId)
			}));
		},
		getIconPath: (icon) => {
			const { staticIconsPath } = get();
			if (icon.startsWith('/')) {
				if (staticIconsPath.endsWith('/')) {
					return staticIconsPath + icon.slice(1);
				} else {
					return `${staticIconsPath}${icon}`;
				}
			} else {
				if (staticIconsPath.endsWith('/')) {
					return `${staticIconsPath}${icon}`;
				} else {
					return `${staticIconsPath}/${icon}`;
				}
			}
		}
	}));
}
