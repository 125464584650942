import type { BoundaryOptions } from './use-farm-blocks';
import { useFarmBlocksLayer } from './use-farm-blocks';
import { forwardRef, useImperativeHandle } from 'react';
import type { MapLayerRef } from '../map-layers.types';
import type VectorLayer from 'ol/layer/Vector';
import type { Feature } from 'ol';

export type FarmBlocksProps = {
	id: number;
} & BoundaryOptions;

export const FarmBlocksLayer = forwardRef<
	MapLayerRef<VectorLayer<Feature>>,
	FarmBlocksProps
>(function FarmBlocks(props, ref) {
	const [query, layer, zoomTo, isOnMap] = useFarmBlocksLayer(props);
	useImperativeHandle(
		ref,
		() => ({
			query,
			layer,
			zoomTo,
			isOnMap
		}),
		[isOnMap, layer, query, zoomTo]
	);
	return null;
});
