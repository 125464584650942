import {
	farmLayerStyleQueryOptions,
	useApi,
	useLayerFilter
} from '@agritechnovation/api-query';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { RawLegend, RawLegendEntry } from '@agritechnovation/api';
import type { ConvertOpts, LegendFromGeoJSONOptions } from '../../helpers';
import {
	convertMfwStyleToGeoStyler,
	createLegendFromGeoJSON,
	getUnit
} from '../../helpers';
import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import type { LayerDataOptions } from '../layer-data-options';
import { mergeRawLegends } from '../../helpers/merge-legends';

export type GeoJSONPolygonStyleOptions = LayerDataOptions & {
	merger?: StyleMergeFN;
	conversionOptions?: ConvertOpts;
	fromGeoJSONOptions?: LegendFromGeoJSONOptions;
};

export type StyleMergeFN = (
	fromGeoJSON: RawLegend,
	fromMfw: RawLegend
) => RawLegendEntry[] | null;

export function useGeoJSONPolygonStyle<
	T extends FeatureCollection<Polygon | MultiPolygon>
>(geojson: T | undefined, options: GeoJSONPolygonStyleOptions) {
	const { data: layerFilter } = useLayerFilter(options.layerFilterId);

	const api = useApi();
	const queryOptions = farmLayerStyleQueryOptions(api, {
		farmId: options.farmId,
		layerFilter
	});

	const selector = useCallback(
		(rawLegend: RawLegend | null) => {
			if (!geojson || !layerFilter) {
				return {
					style: null,
					unit: null,
					merged: null
				};
			}
			const fromGeoJSON = createLegendFromGeoJSON(
				geojson,
				options.fromGeoJSONOptions
			);
			const merged = options.merger
				? options.merger(fromGeoJSON, rawLegend ?? {})
				: mergeRawLegends(rawLegend, fromGeoJSON);
			const geostyler = convertMfwStyleToGeoStyler(
				merged as RawLegendEntry[],
				options.conversionOptions
			);
			const unit = getUnit(merged as RawLegendEntry[], layerFilter);
			return {
				style: geostyler,
				unit,
				merged
			};
		},
		[geojson, layerFilter, options]
	);

	return useQuery({
		...queryOptions,
		select: (d) => selector(d as RawLegend),
		enabled: !!geojson && !!layerFilter
	});
}
