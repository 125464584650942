import type Layer from 'ol/layer/Layer';
import { useEffect } from 'react';

/**
 * Sync properties to a layer, without re-rendering the layer.
 */
export function useSyncProperties<T extends Record<string, unknown>>(
	layer: Layer,
	properties: T
) {
	useEffect(() => {
		layer.setProperties(properties);
	}, [layer, properties]);
}
