import { useApi } from '@agritechnovation/api-query';
import type { LayerDataOptions } from '../layer-data-options';
import type { UseQueryResult } from '@tanstack/react-query';
import { queryOptions, useQueries } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import type { SourceInfo } from 'ol/source/GeoTIFF';
import type {
	Api,
	AvailableLayerObservationWithFarmId
} from '@agritechnovation/api';
import { useLayerAvailableLayers } from '../../helpers';

export interface GeoTIFFStorage {
	save(key: string, data: Blob): Promise<void>;
	exists(key: string): Promise<boolean>;
	createURI(key: string): Promise<string | Blob>;
}

export type UseGeoTIFFDataOptions<T extends GeoTIFFStorage = GeoTIFFStorage> =
	LayerDataOptions & {
		isRGB?: boolean;
		tiffStorage?: T;
	};

export function createGeoTIFFDataQueryKey<T extends GeoTIFFStorage>(
	layer: AvailableLayerObservationWithFarmId,
	opts: Omit<
		UseGeoTIFFDataOptions<T>,
		'LayerFilter' | 'dateBucket' | 'dates' | 'isRGB'
	>
) {
	return [
		'farm',
		opts.farmId,
		'layerFilter',
		opts.layerFilterId,
		'data',
		'geotiff',
		layer.uuid
	];
}
export function createGeoTIFFDataQuery<T extends GeoTIFFStorage>(
	api: Api,
	layer: AvailableLayerObservationWithFarmId,
	opts: Omit<
		UseGeoTIFFDataOptions<T>,
		'LayerFilter' | 'dateBucket' | 'dates' | 'isRGB'
	>
) {
	return queryOptions({
		structuralSharing: false,
		// eslint-disable-next-line @tanstack/query/exhaustive-deps -- tiffStorage is not a dependency
		queryKey: [
			'farm',
			opts.farmId,
			'layerFilter',
			opts.layerFilterId,
			'data',
			'geotiff',
			layer.uuid
		],
		queryFn: async ({ signal }) => {
			const url = api.tms.createURL(`/${layer.uuid}/raw.tiff`);
			if (opts.tiffStorage) {
				const inStorage = await opts.tiffStorage.exists(layer.uuid);
				if (inStorage) {
					const uri = await opts.tiffStorage.createURI(layer.uuid);
					if (uri instanceof Blob) return uri;
					// see comment below
					const response = await fetch(uri, {
						signal
					});
					const blob = await response.blob();
					return blob;
				}
				const response = await fetch(url, {
					signal
				});
				// in the future, if capacitor can better support range requests (mobile), we could retern the url directly
				const blob = await response.blob();
				await opts.tiffStorage.save(layer.uuid, blob);
				return blob;
			}
			return url;
		},
		enabled: !!opts.tiffStorage
	});
}

/**
 * When used with storage, make sure to disabled the query pattern for geotiff data
 */
export function useGeoTIFFData<T extends GeoTIFFStorage>(
	opts: UseGeoTIFFDataOptions<T>
) {
	const api = useApi();
	const {
		data: availableLayers,
		isError: isAlError,
		error: AlError
	} = useLayerAvailableLayers(opts);

	if (isAlError) {
		throw AlError;
	}

	const queries = useMemo(() => {
		if (!availableLayers) return [];
		return availableLayers.map((layer) => {
			return queryOptions(createGeoTIFFDataQuery(api, layer, opts));
		});
	}, [api, availableLayers, opts]);

	const sources: Array<SourceInfo> = useMemo(() => {
		return (
			availableLayers?.map((layer) => {
				if (opts.isRGB) {
					return {
						url: api.tms.createURL(`/${layer.uuid}/raw.tiff`)
					};
				}
				return {
					url: api.tms.createURL(`/${layer.uuid}/raw.tiff`),
					nodata: 0,
					normalize: false,
					bands: [1]
				};
			}) || []
		);
	}, [api.tms, availableLayers, opts.isRGB]);

	const combiner = useCallback(
		(results: UseQueryResult<string | Blob>[]) => {
			const isError = results.some((q) => q.isError);
			const error = results.find((q) => q.isError)?.error;
			const isPending = results.some((q) => q.isPending);
			if (!opts.tiffStorage) {
				return {
					isPending: false,
					data: sources,
					isError,
					error
				};
			}
			const data = {
				isError,
				error,
				isPending,
				data: results
					.filter((r) => r.isSuccess)
					.map((r) => r.data)
					.map((d) => {
						if (opts.isRGB) {
							if (d instanceof Blob) {
								return {
									blob: d
								} satisfies SourceInfo;
							}
							return {
								url: d as string
							} satisfies SourceInfo;
						}
						if (d instanceof Blob) {
							return {
								blob: d,
								nodata: 0,
								// @ts-expect-error -- normalize is not a valid property
								normalize: false,
								bands: [1]
							} satisfies SourceInfo;
						}
						return {
							url: d as string,
							nodata: 0,
							// @ts-expect-error -- normalize is not a valid property
							normalize: false,
							bands: [1]
						} satisfies SourceInfo;
					}) as SourceInfo[]
			};
			return data;
		},
		[sources, opts.tiffStorage, opts.isRGB]
	);

	return useQueries({
		queries,
		combine: combiner
	});
}
