import type { RawLegend, RawLegendEntry } from '@agritechnovation/api';
import { isNoDataValue } from '@agritechnovation/api';

export function mergeRawLegends(
	r1: RawLegend | undefined | null,
	r2: RawLegend | undefined | null
) {
	// since we are mutating the legend entries, we need to clone them, otherwise we run into weird issues
	const r1Arr = structuredClone(
		Array.from(Object.values(r1 ?? {})) as RawLegendEntry[]
	);
	const r2Arr = structuredClone(
		Array.from(Object.values(r2 ?? {})) as RawLegendEntry[]
	);
	const mergedArr = r1Arr.concat(r2Arr);
	const removeSymbols = (item: string) =>
		item
			?.replace('>', '')
			.replace('<', '')
			.replace('≤', '')
			.replace('≥', '')
			.replace(' ', '')
			.replace('&gt;', '')
			.replace('&lt;', '')
			.replace('&le;', '')
			.replace('&ge;', '');

	mergedArr.sort((a, b) => {
		const bstring = String(b.legend);
		const astring = String(a.legend);
		if (isNoDataValue(astring) && isNoDataValue(bstring)) return 0;
		if (isNoDataValue(astring)) return 1;
		if (isNoDataValue(bstring)) return -1;
		const aLegend =
			typeof a.legend === 'string'
				? parseFloat(removeSymbols(a.legend))
				: a.legend;
		const bLegend =
			typeof b.legend === 'string'
				? parseFloat(removeSymbols(b.legend))
				: b.legend;
		if (isNaN(aLegend) && isNaN(bLegend)) {
			if (a.legend < b.legend) {
				return -1;
			} else {
				return 1;
			}
		}
		if (aLegend > bLegend) {
			return 1;
		}
		if (aLegend < bLegend) {
			return -1;
		}
		if (aLegend === bLegend) {
			if (a.legend < b.legend) {
				return 1;
			} else {
				return -1;
			}
		}
		if (aLegend === bLegend) {
			if (a.min && b.min) {
				if (a?.min > b?.min) {
					return 1;
				}
				if (a?.min < b?.min) {
					return -1;
				}
			}
		}
		return 0;
	});

	const deduped: RawLegendEntry[] = [];
	for (const entry of mergedArr) {
		const existingEntry = deduped.find((e) => {
			if (typeof e.legend === 'number') {
				return e.legend === entry.legend;
			}
			if (e.legend.trim() === String(entry.legend).trim()) {
				if (isNoDataValue(e.legend)) {
					return true;
				}
				return e.colour?.toLowerCase() === entry.colour?.toLowerCase();
			}
		});

		if (existingEntry) {
			applyProperty(entry, existingEntry, 'area');
			applyProperty(entry, existingEntry, 'areaPercentage');
			applyProperty(entry, existingEntry, 'legend_af');
			applyProperty(entry, existingEntry, 'legend_es');
			applyProperty(entry, existingEntry, 'legend_en');
			applyProperty(entry, existingEntry, 'graphicFill');
		} else {
			deduped.push(entry);
		}
	}
	return deduped;
}

function applyProperty(
	entry: RawLegendEntry,
	existingEntry: RawLegendEntry,
	property: keyof RawLegendEntry
) {
	if (entry[property]) {
		// @ts-expect-error - this is a valid property
		existingEntry[property] = entry[property];
	}
}
