import { createContext, useContext } from 'react';

export const CompareContext = createContext<{
	positionPx: number;
	positionPercent: number;
	setPositionPercent: (position: number) => void;
	getPositionPx: () => number;
	getHandlePositionPx: () => number;
	windowWidth: number;
	windowHeight: number;
	windowX: number;
	windowY: number;
	windowOffsetLeft: number;
} | null>(null);

export const useCompareContext = () => {
	const ctx = useContext(CompareContext);
	if (!ctx) throw new Error('CompareContext not found');
	return ctx;
};
