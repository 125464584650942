import { useFarmBlocks, useFarmSubBlocks } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react/src/layers/vector/use-vector-layer';
import type { BoundaryOptions } from './use-farm-blocks';
import { useBlockConfig } from './boundary.config';
import { useFindLayer } from '../helpers';
import { useMemo } from 'react';
import { useParseGeostyler } from '../use-parse-geostyler';

export function useFarmSubBlocksLayer(
	id: number,
	opts?: BoundaryOptions & {
		fallbackToBlocks?: boolean;
	}
) {
	const query = useFarmSubBlocks(id);
	const { data: blocks } = useFarmBlocks(id);
	const config = useBlockConfig(opts);

	const initialLayer = useFindLayer(
		(layer) => layer.get('farmId') === id && layer.get('type') === 'sub-blocks'
	);

	const styleQuery = useParseGeostyler(config.style);

	const dataToUse = useMemo(() => {
		if (query.data) {
			if (query.data.features.length === 0 && opts?.fallbackToBlocks) {
				return blocks;
			}
			return query.data;
		}
	}, [blocks, query.data, opts?.fallbackToBlocks]);

	const [layer, zoomTo] = useVectorLayer({
		initialLayer,
		style: styleQuery.data?.output,
		data: dataToUse,
		properties: {
			farmId: id,
			type: 'sub-blocks'
		}
	});

	return [layer, query, zoomTo] as const;
}
