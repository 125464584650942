import type { MapBrowserEvent } from 'ol';
import type Feature from 'ol/Feature';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { getWidth } from 'ol/extent';
import type VectorLayer from 'ol/layer/Vector';
import type { Style } from 'ol/style';
import type { StyleFunction } from 'ol/style/Style';
import { useCallback, useEffect } from 'react';
import { useDragBox } from './use-drag-box';
import { useSelectLayers } from './use-select-layers';

export const Select = ({
	layers,
	hoverStyle,
	selected,
	onAddFeature,
	onRemoveFeature,
	onRemoveFeatures,
	onAddFeatures,
	dragBox: dragBoxProp = false
}: {
	layers: VectorLayer<Feature>[];
	selected: Feature[];
	onAddFeature?: (f: Feature) => void;
	onAddFeatures?: (f: Feature[]) => void;
	onRemoveFeature?: (f: Feature) => void;
	onRemoveFeatures?: (f: Feature[]) => void;
	hoverStyle?: Style | StyleFunction;
	addCondition?: (e: Feature) => boolean;
	removeCondition?: (e: Feature) => boolean;
	dragBox?: boolean;
}) => {
	const { map, source } = useSelectLayers({ layers, selected, hoverStyle });

	const [dragBox] = useDragBox({
		condition: platformModifierKeyOnly
	});

	// Reference: https://openlayers.org/en/latest/examples/box-selection.html#:~:text=Using%20a%20DragBox%20interaction%20to,on%20Mac)%20to%20draw%20boxes.
	const onDragBoxEnd = useCallback(() => {
		const boxExtent = dragBox.getGeometry()?.getExtent();
		if (!boxExtent) return;
		const direction = dragBox.get('direction');

		// if the extent crosses the antimeridian process each world separately
		const worldExtent = map.getView().getProjection().getExtent();
		const worldWidth = getWidth(worldExtent);
		const startWorld = Math.floor((boxExtent[0] - worldExtent[0]) / worldWidth);
		const endWorld = Math.floor((boxExtent[2] - worldExtent[0]) / worldWidth);
		for (let world = startWorld; world <= endWorld; ++world) {
			const left = Math.max(boxExtent[0] - world * worldWidth, worldExtent[0]);
			const right = Math.min(boxExtent[2] - world * worldWidth, worldExtent[2]);
			const extent = [left, boxExtent[1], right, boxExtent[3]];
			let boxFeatures: Feature[] = [];
			if (direction === 'left') {
				boxFeatures = layers
					.flatMap((l) => {
						return l.getSource()?.getFeaturesInExtent(extent) ?? [];
					})
					.filter(
						(f) =>
							!selected.includes(f) && f.getGeometry()?.intersectsExtent(extent)
					);
			}
			if (direction === 'right') {
				boxFeatures = selected.filter((f) =>
					f.getGeometry()?.intersectsExtent(extent)
				);
			}
			// features that intersect the box geometry are added to the
			// collection of selected features

			// if the view is not obliquely rotated the box geometry and
			// its extent are equalivalent so intersecting features can
			// be added directly to the collection
			const rotation = map.getView().getRotation();
			const oblique = rotation % (Math.PI / 2) !== 0;

			// when the view is obliquely rotated the box extent will
			// exceed its geometry so both the box and the candidate
			// feature geometries are rotated around a common anchor
			// to confirm that, with the box geometry aligned with its
			// extent, the geometries intersect
			if (oblique) {
				const anchor = [0, 0];
				const geometry = dragBox.getGeometry()?.clone();
				if (!geometry) continue;
				geometry.translate(-world * worldWidth, 0);
				geometry.rotate(-rotation, anchor);
				const extent = geometry.getExtent();
				boxFeatures.forEach(function (feature) {
					const geometry = feature.getGeometry()?.clone();
					geometry?.rotate(-rotation, anchor);
					if (geometry?.intersectsExtent(extent)) {
						if (direction === 'right') {
							source.current.removeFeature(feature);
							onRemoveFeature?.(feature);
						} else {
							source.current.addFeature(feature);
							onAddFeature?.(feature);
						}
					}
				});
			} else {
				if (direction === 'right') {
					source.current.removeFeatures(boxFeatures);
					onRemoveFeatures?.(boxFeatures);
				} else {
					source.current.addFeatures(boxFeatures);
					onAddFeatures?.(boxFeatures);
				}
			}
		}
	}, [
		dragBox,
		layers,
		map,
		onAddFeature,
		onAddFeatures,
		onRemoveFeature,
		onRemoveFeatures,
		selected,
		source
	]);

	useEffect(() => {
		if (dragBoxProp) {
			dragBox.on('boxend', onDragBoxEnd);
			map.addInteraction(dragBox);
		}
		return () => {
			dragBox.un('boxend', onDragBoxEnd);
			map.removeInteraction(dragBox);
		};
	}, [dragBox, dragBoxProp, map, onDragBoxEnd]);

	useEffect(() => {
		const handler = (e: MapBrowserEvent<MouseEvent>) => {
			const allFeatures = layers.flatMap(
				(l) => l.getSource()?.getFeaturesAtCoordinate(e.coordinate) ?? []
			);
			const added = allFeatures.filter((f) => !selected.includes(f));
			for (const f of added) {
				source.current.addFeature(f);
			}
			if (added.length > 0) {
				onAddFeatures?.(added);
			}
			const removed = allFeatures.filter((f) => selected.includes(f));
			for (const f of removed) {
				source.current.removeFeature(f);
			}
			if (removed.length > 0) {
				onRemoveFeatures?.(removed);
			}
		};

		map.on('click', handler);
		return () => {
			map.un('click', handler);
		};
	}, [
		map,
		layers,
		selected,
		onRemoveFeature,
		onAddFeature,
		onRemoveFeatures,
		onAddFeatures,
		source
	]);

	return null;
};
