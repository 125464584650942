import type { BoundaryOptions } from './use-farm-blocks';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import type { MapLayerRef } from '../map-layers.types';
import type VectorLayer from 'ol/layer/Vector';
import type { Feature } from 'ol';
import { useFarmBoundaryLayer } from './use-farm-boundary';
import type { UseQueryResult } from '@tanstack/react-query';

export type FarmBoundaryProps = {
	id: number;
	zoomPadding?: number[];
} & BoundaryOptions & {
		onAdded?: () => void;
	};

export const FarmBoundaryLayer = forwardRef<
	MapLayerRef<VectorLayer<Feature>>,
	FarmBoundaryProps
>(function FarmBlocks(props, ref) {
	const [query, layer, zoomTo, isOnMap] = useFarmBoundaryLayer(props);
	useImperativeHandle(
		ref,
		() => ({
			query: query as UseQueryResult<unknown, unknown>,
			layer,
			zoomTo,
			isOnMap
		}),
		[query, layer, zoomTo, isOnMap]
	);

	useEffect(() => {
		if (!isOnMap) return;
		props.onAdded?.();
	}, [isOnMap, props]);
	return null;
});
