import type {
	FillSymbolizer,
	Rule,
	Style,
	TextSymbolizer
} from 'geostyler-style';
import type { RawLegendEntry } from '@agritechnovation/api';
import {
	B64,
	isTransparentHexColor,
	roundNumber
} from '@agritechnovation/utils';

export type RuleMetaData = RawLegendEntry & {
	ruleName: string;
	area: number;
	areaPercentage: number;
	areaHa: number;
	areaAcres: number;
	legend_en?: string;
	legend_es?: string;
	legend_af?: string;
	colour?: string;
	dontShowArea: boolean;
};
export type ConvertOpts = {
	allowedNegativeMaxValues?: boolean;
	isLine?: boolean;
	noOutline?: boolean;
	labelKey?: string;
	styleMetadata?: Record<string, unknown>;
	textSymbolizer?: TextSymbolizer;
	dontShowArea?: boolean;
};

export function convertMfwStyleToGeoStyler(
	inputStyle: RawLegendEntry[],
	opts?: ConvertOpts
) {
	const style: Style = {
		name: '',
		rules: [],
		metadata: {
			classified: true,
			rules: [],
			supportsHectares: true,
			...(opts?.styleMetadata ?? {})
		}
	};
	const addRuleWithMetaData = (rule: Rule, entry: RawLegendEntry) => {
		const metaData: RuleMetaData = {
			ruleName: rule.name,
			areaPercentage: entry.areaPercentage
				? roundNumber(entry.areaPercentage, 2)
				: 0,
			areaHa: entry.area ? roundNumber(entry.area / 10000, 2) : 0,
			area: entry.area ?? 0,
			areaAcres: entry.area ? roundNumber(entry.area / 4046.85642, 2) : 0,
			legend_en: entry.legend_en,
			legend_es: entry.legend_es,
			legend_af: entry.legend_af,
			legend: entry.legend,
			label: entry.label,
			colour: entry.colour,
			max: entry.max ?? 0,
			min: entry.min ?? 0,
			dontShowArea: opts?.dontShowArea ?? false
		};
		style.metadata?.rules.push(metaData);
		style.rules.push(rule);
	};
	for (const entry of inputStyle) {
		if (entry.max && entry.max < 0 && !opts?.allowedNegativeMaxValues) {
			continue;
		}
		const rule: Rule = {
			name: entry.legend ? `${entry.legend}` : '',
			filter: [
				'==',
				opts?.labelKey ? opts.labelKey : 'label',
				entry.legend ?? ''
			],
			symbolizers: []
		};
		const color = entry.colour?.replace('0x', '#');

		if (isTransparentHexColor(color)) {
			const symbolizer: FillSymbolizer = {
				kind: 'Fill',
				fillOpacity: 0,
				outlineOpacity: 1,
				color: '#000000',
				outlineColor: '#000000',
				outlineWidth: 1
				// width: opts?.isLine ? 3 : 1
			};
			rule.symbolizers.push(symbolizer);
			addRuleWithMetaData(rule, entry);
			continue;
		}
		const symbolizer: FillSymbolizer = {
			kind: 'Fill',
			opacity: 1,
			color: entry.colour?.replace('0x', '#'),
			outlineColor: entry.colour?.replace('0x', '#'),
			outlineWidth: opts?.isLine ? 3 : 1
		};
		if (opts?.noOutline) {
			delete symbolizer.outlineColor;
			delete symbolizer.outlineWidth;
		}
		rule.symbolizers.push(symbolizer);
		if (opts?.textSymbolizer) {
			// if the symbolizer is edited, cloning it ensures that the original is not modified, somehow sometimes even if the whole style is cloned, the reference is still the same...
			rule.symbolizers.push(structuredClone(opts.textSymbolizer));
		}
		if (entry.graphicFill) {
			rule.symbolizers.push({
				kind: 'Fill',
				graphicFill: {
					kind: 'Mark',
					wellKnownName: entry.graphicFill
				}
			} satisfies FillSymbolizer);
		}
		addRuleWithMetaData(rule, entry);
	}
	style.name = B64.encode(
		JSON.stringify({
			style,
			opts
		})
	);
	return style;
}
