import type { PropsWithChildren } from 'react';
import { useCallback, useRef, useState } from 'react';
import { useMap } from '../../map';
import { CompareContext } from './compare.context';
import { convertRemToPixels, roundNumber } from '@agritechnovation/utils';

export const CompareProvider = ({
	children,
	initialPositionPercent,
	windowWidth = 1,
	windowHeight = 1,
	windowOffsetLeft = 0,
	windowX = 0,
	windowY = 0
}: PropsWithChildren<{
	initialPositionPercent?: number;
	windowWidth?: number;
	windowHeight?: number;
	windowOffsetLeft?: number;
	windowX?: number;
	windowY?: number;
}>) => {
	const [positionPercent, setPositionPercent] = useState(() => {
		return initialPositionPercent ?? 50;
	});
	const map = useMap();
	const positionPxRef = useRef((windowWidth ?? 0) * (positionPercent / 100));
	const [positionPx, setPositionPx] = useState(positionPxRef.current);

	const getPositionPx = useCallback(() => {
		return (
			positionPxRef.current + windowOffsetLeft + convertRemToPixels('0.25')
		);
	}, [windowOffsetLeft]);

	const getHandlePositionPx = useCallback(() => {
		return positionPxRef.current;
	}, []);

	const handleSetPositionPercent = useCallback(
		(position: number) => {
			setPositionPercent(position);
			positionPxRef.current = roundNumber(
				(windowWidth || 0) * (position / 100),
				0
			);
			document.documentElement.style.setProperty(
				'--compare-slider-position-px',
				`${positionPxRef.current}px`
			);
			setPositionPx(positionPxRef.current);
			map.render();
		},
		[windowWidth, map]
	);
	return (
		<CompareContext.Provider
			value={{
				positionPx,
				positionPercent,
				getPositionPx,
				getHandlePositionPx,
				setPositionPercent: handleSetPositionPercent,
				windowWidth,
				windowHeight,
				windowX,
				windowY,
				windowOffsetLeft
			}}
		>
			{children}
		</CompareContext.Provider>
	);
};
