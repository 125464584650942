import { useVehicleDetails } from '@agritechnovation/api-query';
import { useMemo } from 'react';
import { useVectorLayer } from '@agritechnovation/ol-react';
import type { Feature, FeatureCollection, LineString } from 'geojson';
import type { Rule, Style } from 'geostyler-style';
import { useVehicleHistoryRange } from '@agritechnovation/api-query';
import format from 'date-fns/format';
import { useFindLayer } from '../../../helpers';
import { useParseGeostyler } from '../../../use-parse-geostyler';

export type MyAssetLayerProps = {
	farmId: number;
	vehicleId: string;
	dateRange: [string, string];
	showHistory: boolean;
	speedSettings: Array<{
		min: number;
		max: number;
		color: string;
		width: number;
	}>;
};

export function useMyAssetLayer({
	vehicleId,
	dateRange,
	showHistory,
	speedSettings,
	farmId
}: MyAssetLayerProps) {
	const { data: details } = useVehicleDetails(vehicleId);
	const today = format(new Date(), 'yyyy-MM-dd');
	const { data: history } = useVehicleHistoryRange({
		farmId,
		vehicleId,
		dateRange: dateRange || [today, today]
	});

	const historyWithDetails = useMemo(() => {
		if (!details || !history) return undefined;
		for (const feature of history.features) {
			feature.properties.details = details;
			feature.properties.vehicle_type = details.vehicle_type;
		}
		return history;
	}, [history, details]);

	const lineData = useMemo(() => {
		const fc: FeatureCollection<LineString> = {
			type: 'FeatureCollection',
			features: []
		};
		if (!historyWithDetails) return fc;
		for (let i = 0; i < historyWithDetails.features.length - 1; i++) {
			const current = historyWithDetails.features[i];
			const next = historyWithDetails.features[i + 1];
			if (!current || !next) continue;
			const line = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [current.geometry.coordinates, next.geometry.coordinates]
				},
				properties: {
					...current.properties,
					...next.properties
				}
			} satisfies Feature;
			fc.features.push(line);
		}
		return fc;
	}, [historyWithDetails]);

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === `vehicle_${vehicleId}`
	);

	const initialHistoryLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === `vehicle_${vehicleId}_history`
	);

	const currentLocation = useMemo(() => {
		const lastFeature = historyWithDetails?.features.at(-1);
		if (!lastFeature) return undefined;
		return {
			type: 'FeatureCollection',
			features: [lastFeature]
		} satisfies FeatureCollection;
	}, [historyWithDetails]);

	const vehicleStyle = useMemo(() => {
		if (!details)
			return {
				name: 'Vehicle Style_blank' + vehicleId,
				rules: [
					{
						name: 'ICON',
						symbolizers: []
					}
				]
			} satisfies Style;
		return {
			name: 'Vehicle Style_' + vehicleId,
			rules: [
				{
					name: 'ICON',
					symbolizers: [
						{
							kind: 'Icon',
							image: details.icon
								.replace(
									'https://myfarmweb.agritechnovation.com/media/icons/',
									'https://myfarmweb.com/static-data/media/icons/'
								)
								.replace(
									'https://us-mfw-site.s3.amazonaws.com/media/icons/',
									'https://us.myfarmweb.com/static-data/media/icons/'
								),
							size: 38,
							opacity: 1
						}
					]
				}
			]
		} satisfies Style;
	}, [details, vehicleId]);

	const { data: style } = useParseGeostyler(vehicleStyle);

	useVectorLayer({
		initialLayer,
		zIndex: 500,
		data: currentLocation,
		style: style?.output,
		declutter: false,
		properties: {
			mfw_uuid: `vehicle_${vehicleId}`,
			type: 'myasset',
			vehicle_id: vehicleId
		}
	});

	const lineStyle = useMemo(() => {
		return speedSettingsToStyle(speedSettings);
	}, [speedSettings]);

	const { data: lineStyleData } = useParseGeostyler(lineStyle);
	useVectorLayer({
		initialLayer: initialHistoryLayer,
		visible: showHistory,
		zIndex: 499,
		style: lineStyleData?.output,
		data: lineData,
		declutter: false,
		properties: {
			mfw_uuid: `vehicle_${vehicleId}_history`,
			type: 'myasset'
		}
	});

	return [];
}

function speedSettingsToStyle(
	settings: MyAssetLayerProps['speedSettings']
): Style {
	const rules: Rule[] = settings.map((setting, i) => {
		const nextSetting = settings[i + 1];
		return {
			name: 'LINE',
			filter: [
				'&&',
				['>=', { name: 'property', args: ['speed'] }, setting.min],
				[
					'<=',
					{ name: 'property', args: ['speed'] },
					!nextSetting ? Infinity : setting.max
				]
			],
			symbolizers: [
				{
					kind: 'Line',
					color: setting.color,
					width: setting.width
				}
			]
		} as Rule;
	});

	return {
		name: 'Vehicle Speed',
		rules
	};
}
