export function agentHas(keyword: string) {
	return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

export function isSafari() {
	return (
		// @ts-expect-error -- TS doesn't know about ApplePaySetupFeature
		(!!window.ApplePaySetupFeature || !!window.safari) &&
		agentHas('Safari') &&
		!agentHas('Chrome') &&
		!agentHas('CriOS')
	);
}

export function getSafariVersion() {
	const match = navigator.userAgent.match(/Version\/(\d+)/);
	return match ? parseInt(match[1], 10) : null;
}
