import { useMap } from '../../map';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState
} from 'react';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import type { DrawEvent } from 'ol/interaction/Draw';
import OlDraw from 'ol/interaction/Draw';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import type { MapBrowserEvent, Overlay as OlOverlay } from 'ol';
import { getUid } from 'ol';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import { LineString, Polygon } from 'ol/geom';
import type { Coordinate } from 'ol/coordinate';
import { Overlay } from '../../overlay';
import { getArea, getLength } from 'ol/sphere';
import type { StyleLike } from 'ol/style/Style';
import type { FlatStyle } from 'ol/style/flat';
import { roundNumber } from '@agritechnovation/utils';

import { getLastCoordinate } from '../../utils/get-last-coordinate';
import { useMapCenter, useMapView } from '../../hooks';

const formatLength = function (line: LineString, units: 'metric' | 'imperial') {
	let length = getLength(line);
	let output;
	if (units === 'metric') {
		if (length > 100) {
			output = roundNumber(length / 1000, 2) + ' ' + 'km';
		} else {
			output = roundNumber(length, 2) + ' ' + 'm';
		}
	} else if (units === 'imperial') {
		length *= 3.28084;
		if (length > 5280) {
			output = roundNumber(length / 5280, 2) + ' ' + 'mi';
		} else {
			output = roundNumber(length, 2) + ' ' + 'ft';
		}
	} else {
		output = 'unsupported units';
	}
	return output;
};

const formatArea = function (polygon: Polygon, units: 'metric' | 'imperial') {
	let area = getArea(polygon);
	let output;
	if (units === 'metric') {
		if (area > 10000) {
			output = roundNumber(area / 10000, 2) + ' ' + 'ha';
		} else {
			output = roundNumber(area, 2) + ' ' + 'm<sup>2</sup>';
		}
	} else if (units === 'imperial') {
		area *= 3.28084 * 3.28084;
		const acres = area / 43560;
		if (acres >= 1) {
			output = roundNumber(acres, 2) + ' ' + 'ac';
		} else {
			output = roundNumber(area, 2) + ' ' + 'ft<sup>2</sup>';
		}
	} else {
		output = 'unsupported units';
	}
	return output;
};

const defaultSketchStyle = new Style({
	fill: new Fill({
		color: 'rgba(255, 255, 255, 0.2)'
	}),
	stroke: new Stroke({
		color: 'rgba(0, 0, 0, 0.5)',
		lineDash: [10, 10],
		width: 2
	}),
	image: new CircleStyle({
		radius: 5,
		stroke: new Stroke({
			color: 'rgba(0, 0, 0, 0.7)'
		}),
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.2)'
		})
	})
});

const defaultVectorStyle = {
	'fill-color': 'rgba(255, 255, 255, 0.2)',
	'stroke-color': '#ffcc33',
	'stroke-width': 2,
	'circle-radius': 7,
	'circle-fill-color': '#ffcc33'
};

export type DrawHelpMessages = {
	clickToStart: string;
	clickToContinue: string;
	clickToClose: string;
	continueOrClose: string;
	rightClickClose?: string;
};

export type DrawProps = {
	type: 'Polygon' | 'LineString' | 'Point';
	maxFeatures?: number;
	maxPoints?: number;
	measuringSystem?: 'metric' | 'imperial';
	onAddFeature?: (feature: Feature) => void;
	onInvalidDrawStart?: () => void;
	onRemoveFeature?: (feature: Feature) => void;
	initialFeatures?: Feature[];
	sketchStyle?: Style | StyleLike | FlatStyle;
	vectorStyle?: Style | StyleLike | FlatStyle;
	hideMeasure?: boolean;
	helpMessages?: DrawHelpMessages;
	mask?: VectorLayer<Feature>[];
	extractPropertiesFromMask?: string[];
	// draw_layer_id is used to identify the layer in the map
	layerId?: string;
	staticHelpPosition?: 'top' | 'bottom';
};

export type DrawRef = {
	draw: OlDraw;
	layer: VectorLayer<Feature>;
};

const DEFAULT_HELP_MESSAGES: DrawHelpMessages = {
	clickToStart: 'Click to start drawing',
	clickToContinue: 'Click to continue drawing',
	clickToClose: 'Click to close drawing',
	continueOrClose: 'Continue drawing, or click the first point to close'
};
export const Draw = forwardRef<DrawRef, DrawProps>(function Draw(
	{
		type,
		maxFeatures,
		measuringSystem = 'metric',
		maxPoints,
		onAddFeature,
		onRemoveFeature,
		onInvalidDrawStart,
		sketchStyle,
		vectorStyle,
		hideMeasure,
		helpMessages,
		initialFeatures,
		mask,
		extractPropertiesFromMask,
		layerId,
		staticHelpPosition
	}: DrawProps,
	ref
) {
	const { current: vector } = useRef(
		new VectorLayer({
			source: new VectorSource(
				initialFeatures ? { features: initialFeatures } : undefined
			),
			zIndex: 300,
			style: vectorStyle ?? defaultVectorStyle,
			properties: {
				draw_layer_id: layerId
			}
		})
	);

	const feature = useRef<Feature>();
	const drawRef = useRef<OlDraw>();
	const map = useMap();
	const helpOverlayRef = useRef<OlOverlay>(null);
	const measureOverlayRef = useRef<OlOverlay>(null);
	const [help, setHelp] = useState(
		() => helpMessages?.clickToStart ?? DEFAULT_HELP_MESSAGES.clickToStart
	);
	const [measurement, setMeasurement] = useState('');

	useImperativeHandle(ref, () => {
		return {
			draw: drawRef.current as OlDraw,
			layer: vector
		};
	}, [vector]);

	useEffect(() => {
		map.addLayer(vector);
		return () => {
			map.removeLayer(vector);
		};
	}, [map, vector]);

	useEffect(() => {
		if (vectorStyle) {
			vector.setStyle(vectorStyle);
		} else {
			vector.setStyle(defaultVectorStyle);
		}
	}, [vector, vectorStyle]);

	useEffect(() => {
		const onMouseOut = () => {
			helpOverlayRef.current?.setPosition(undefined);
		};
		map.getViewport().addEventListener('mouseout', onMouseOut);

		return () => {
			map.getViewport().removeEventListener('mouseout', onMouseOut);
		};
	}, [map]);
	useEffect(() => {
		let draw = drawRef.current;
		const source = vector.getSource();
		if (!source) throw new Error('No source');
		if (draw && !feature.current) {
			map.removeInteraction(draw);
		}
		if (!draw) {
			draw = new OlDraw({
				source,
				maxPoints,
				features: new Collection([feature.current as Feature]),
				type,
				condition: (e) => {
					if (e.originalEvent.button === 2 && type === 'LineString') {
						draw?.finishDrawing();
						return false;
					}
					if (
						(e.originalEvent.button === 0 && type === 'Polygon') ||
						type === 'Point'
					) {
						if (!mask) return true;
						const features = e.map.getFeaturesAtPixel(e.pixel);
						const nonDrawingFeatures = features.filter(
							(x) => x.get('id') && !x.get('done')
						);
						const drawingFeatures = features.filter((x) => x.get('done'));
						if (nonDrawingFeatures.length === 0) {
							return false;
						}
						if (drawingFeatures.length >= 1) {
							const top = drawingFeatures.find((x) => x.get('done') === true);
							if (type === 'Point') {
								if (top && !feature.current) {
									onRemoveFeature?.(top as Feature);
									vector.getSource()?.removeFeature(top as Feature);
								}
								return false;
							}
							if (type === 'Polygon') {
								if (top && !feature.current) {
									onRemoveFeature?.(top as Feature);
									vector.getSource()?.removeFeature(top as Feature);
								}
							}
						}
						if (maxFeatures && source.getFeatures().length >= maxFeatures)
							return false;
					}
					return true;
				},
				style: sketchStyle ?? defaultSketchStyle
			});
		}
		const onFeatureChange = () => {
			if (feature.current) {
				const geom = feature.current.getGeometry();
				let coordinates: Coordinate[] = [];
				let infoCoords: number[] = [];
				if (geom instanceof Polygon) {
					coordinates = geom.getCoordinates().flat();
					infoCoords = geom.getInteriorPoint().getCoordinates();
					if (measureOverlayRef.current) {
						measureOverlayRef.current.setPosition(infoCoords);
						const area = formatArea(geom, measuringSystem);
						setMeasurement(area);
					}
				}
				if (geom instanceof LineString) {
					coordinates = geom.getCoordinates();
					if (measureOverlayRef.current) {
						infoCoords = geom.getLastCoordinate();
						measureOverlayRef.current.setPosition(infoCoords);
						const area = formatLength(geom, measuringSystem);
						setMeasurement(area);
					}
				}
				if (coordinates.length === 1) {
					setHelp(
						helpMessages?.clickToStart ?? DEFAULT_HELP_MESSAGES.clickToStart
					);
				}
				if (coordinates.length === 3) {
					setHelp(
						helpMessages?.clickToContinue ??
							DEFAULT_HELP_MESSAGES.clickToContinue
					);
				}
				if (type === 'Polygon') {
					if (coordinates.length === 4) {
						setHelp(
							helpMessages?.clickToContinue ??
								DEFAULT_HELP_MESSAGES.clickToContinue
						);
					}
					if (coordinates.length >= 5) {
						if (measureOverlayRef.current) {
						}
						setHelp(
							helpMessages?.continueOrClose ??
								DEFAULT_HELP_MESSAGES.continueOrClose
						);
					}
				}
				if (type === 'LineString') {
					if (coordinates.length >= 3) {
						setHelp(
							helpMessages?.rightClickClose ??
								'Continue drawing, or right-click to end'
						);
					}
				}
			}
		};

		const onDrawEnd = () => {
			if (feature.current) {
				if (extractPropertiesFromMask && mask) {
					const sources = mask.map((m) => m.getSource());
					const geom = feature.current.getGeometry();
					if (!geom) throw new Error('No geometry');
					const lastCoord = getLastCoordinate(geom);
					if (!lastCoord) throw new Error('No last coordinate');
					for (const maskSource of sources) {
						if (!maskSource) continue;
						const featuresAtCoordinate =
							maskSource.getFeaturesAtCoordinate(lastCoord);
						if (featuresAtCoordinate.length > 0) {
							const top = featuresAtCoordinate[0];
							if (top) {
								const props = top.getProperties();
								extractPropertiesFromMask.forEach((p) => {
									feature.current?.set(p, props[p]);
								});
							}
						}
					}
				}
				onAddFeature?.(feature.current);
				feature.current.set('done', true);
				feature.current.un('change:geometry', onFeatureChange);
			}
			feature.current = undefined;
			setHelp(helpMessages?.clickToStart ?? DEFAULT_HELP_MESSAGES.clickToStart);
			if (measureOverlayRef.current) {
				measureOverlayRef.current.setOffset([0, -7]);
				measureOverlayRef.current.setPosition(undefined);
			}
		};
		const onDrawStart = (e: DrawEvent) => {
			feature.current = e.feature;
			if (feature.current) {
				feature.current.set('fromDraw', true);
				feature.current.on('change', onFeatureChange);
			}
		};

		const onPointerMove = (e: MapBrowserEvent<UIEvent>) => {
			if (helpOverlayRef.current) {
				if (!staticHelpPosition)
					helpOverlayRef.current.setPosition(e.coordinate);
			}
		};

		map.on('pointermove', onPointerMove);
		draw.on('drawstart', onDrawStart);
		draw.on('drawend', onDrawEnd);
		map.addInteraction(draw);
		if (feature.current) {
			const geom = feature.current.getGeometry() as Polygon | LineString;
			if (geom) {
				if (geom instanceof LineString) {
					const coordinates = geom.getCoordinates();
					const inputPolygonCoords: Coordinate[] = [];
					const firstCoord = coordinates[0];
					for (let i = 0; i < coordinates.length; i++) {
						const coord = coordinates[i];
						if (i === coordinates.length - 1) {
							inputPolygonCoords.push(firstCoord);
						}
						inputPolygonCoords.push(coord);
					}

					const asPolygon = new Polygon([inputPolygonCoords]);
					feature.current = new Feature(asPolygon);
					const coords = geom.getCoordinates();
					const withoutLast = coords.slice(0, coords.length - 1);
					draw.appendCoordinates(withoutLast);
				}
				if (geom instanceof Polygon) {
					const coordinates = geom.getCoordinates().flat();
					const asLineString = new LineString(coordinates);
					feature.current = new Feature(asLineString);
					const coords = geom.getCoordinates().flat();
					const withoutLast = coords.slice(0, coords.length - 2);
					draw.appendCoordinates(withoutLast);
				}
			}
		}
		return () => {
			if (draw) {
				map.removeInteraction(draw);
				draw.un('drawstart', onDrawStart);
				draw.un('drawend', onDrawEnd);
			}
			map.un('pointermove', onPointerMove);
		};
	}, [
		maxPoints,
		type,
		map,
		vector,
		onAddFeature,
		sketchStyle,
		onRemoveFeature,
		extractPropertiesFromMask,
		mask,
		onInvalidDrawStart,
		measuringSystem,
		maxFeatures,
		helpMessages?.clickToStart,
		helpMessages?.clickToContinue,
		helpMessages?.continueOrClose,
		staticHelpPosition,
		helpMessages?.rightClickClose
	]);

	const view = useMapView();
	const center = useMapCenter();
	useEffect(() => {
		if (!staticHelpPosition) return;
		if (staticHelpPosition === 'top') {
			const { extent } = view.getViewStateAndExtent();
			const left = extent[0];
			const right = extent[2];
			const top = extent[3];
			const bottom = extent[1];
			const centerX = (left + right) / 2;

			const sizeVertical = top - bottom;
			const position = [centerX, top - sizeVertical * 0.05];
			helpOverlayRef.current?.setPosition(position);
		}
	}, [center, map, staticHelpPosition, view]);

	const features = vector.getSource()?.getFeatures();
	return (
		<>
			<Overlay
				show={true}
				ref={helpOverlayRef}
				offset={[0, -7]}
				disableAutoPan={true}
				className="bg-[#333333] bg-opacity-50 left-2 text-white rounded-sm text-xs px-2 w-fit whitespace-nowrap font-bold pointer-events-none"
			>
				<h1 className={'w-full pointer-events-none'}>{help}</h1>
			</Overlay>
			{!hideMeasure && (
				<Overlay
					show={!hideMeasure}
					className="rounded-sm text-white font-bold px-2 py-1 whitespace-nowrap bg-black bg-opacity-50 text-xs pointer-events-none absolute"
					ref={measureOverlayRef}
					positioning={'bottom-center'}
					stopEvent={false}
					insertFirst={false}
					offset={type === 'Polygon' ? [-40, -5] : [0, -50]}
				>
					<h1
						className="pointer-events-none"
						dangerouslySetInnerHTML={{
							__html: measurement
						}}
					></h1>
				</Overlay>
			)}
			{features?.map((f) => {
				if (f.get('done')) {
					const geom = f.getGeometry();
					const position =
						geom instanceof Polygon
							? geom.getInteriorPoint().getCoordinates()
							: geom instanceof LineString
								? geom.getCoordinateAt(0.4)
								: undefined;
					const measurement =
						geom instanceof Polygon
							? formatArea(geom, measuringSystem)
							: geom instanceof LineString
								? formatLength(geom, measuringSystem)
								: '';

					return (
						<Overlay
							disableAutoPan={true}
							key={getUid(f)}
							show={!hideMeasure}
							position={position}
							className="rounded-sm text-white font-bold px-2 py-1 whitespace-nowrap bg-black bg-opacity-50 text-xs absolute"
							positioning={'bottom-center'}
							stopEvent={false}
							insertFirst={false}
							offset={geom instanceof Polygon ? [-40, -5] : [0, -20]}
						>
							<h1
								dangerouslySetInnerHTML={{
									__html: measurement
								}}
							></h1>
						</Overlay>
					);
				}
				return null;
			})}
		</>
	);
});
