// https://tanstack.com/router/latest/docs/framework/react/guide/custom-search-param-serialization/#safe-binary-encodingdecoding
export class B64 {
	static decode(str: string): string {
		return decodeURIComponent(
			Array.prototype.map
				.call(window.atob(str), function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
	}

	static encode(str: string): string {
		return window.btoa(
			encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_, p1) {
				return String.fromCharCode(parseInt(p1, 16));
			})
		);
	}
}
