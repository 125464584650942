import type {
	Api,
	AvailableLayerObservationWithFarmId
} from '@agritechnovation/api';
import { useApi, useLayerFilter } from '@agritechnovation/api-query';
import { mergeFeatureCollections } from '@agritechnovation/utils';
import type {
	QueriesOptions,
	QueriesResults,
	UseQueryResult
} from '@tanstack/react-query';
import { queryOptions, useQueries } from '@tanstack/react-query';
import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import type { LayerDataOptions } from '../layer-data-options';
import { useLayerAvailableLayers } from '../../helpers';
import { useCallback } from 'react';

export type GeoJSONPolygonDataOptions = {
	farmId: number;
	layerFilterId: number;
	date: AvailableLayerObservationWithFarmId;
};

export function createGeoJSONPolygonDataQueryKey(
	options: GeoJSONPolygonDataOptions
) {
	return [
		'farm',
		options.farmId,
		'layer-filter',
		options.layerFilterId,
		'geojson',
		'polygon',
		options.date.uuid
	];
}

export function createGeoJSONPolygonDataQuery(
	api: Api,
	options: GeoJSONPolygonDataOptions
) {
	return queryOptions({
		queryKey: [
			'farm',
			options.farmId,
			'layer-filter',
			options.layerFilterId,
			'geojson',
			'polygon',
			options.date.uuid
		],
		queryFn: async ({ signal }) => {
			return api.geojson.get<FeatureCollection<Polygon | MultiPolygon>>(
				options.date.uuid,
				{
					abort: signal
				}
			);
		},
		structuralSharing: false
	});
}

/**
 * Should be renamed to useGeoJSONData
 **/
export function useGeoJSONPolygonData(
	opts: LayerDataOptions,
	variant?: number
) {
	const { data: availableLayers = [] } = useLayerAvailableLayers({
		...opts,
		variant
	});

	const { data: layerFilter } = useLayerFilter(opts.layerFilterId);

	const geom = layerFilter?.layer_type;
	if (geom === 'point' || geom === 'pdf_point') {
		throw new Error('Cannot use point layer filter with polygon/geojson data');
	}

	const api = useApi();

	return useQueries(
		useCreateGeoJSONPolygonDataQueries(api, availableLayers, opts)
	);
}

export function useCreateGeoJSONPolygonDataQueries(
	api: Api,
	availableLayers: AvailableLayerObservationWithFarmId[],
	opts: LayerDataOptions
	// select?: GeoJSONPolygonDataSelector -- combine function will not actually return new data even if it is updated see https://github.com/TanStack/query/discussions/6337
) {
	const combine = useCallback((data: UseQueryResult[]) => {
		const actualData = data.filter((d) => d.data);
		const refetch = () => {
			return data.map((d) => d.refetch());
		};

		const isPending = data.some((d) => d.isPending);

		if (isPending) {
			return {
				isPending: true,
				data: undefined,
				refetch
			};
		}

		const isError = data.some((d) => d.isError);
		const error = data.find((d) => d.isError)?.error;

		const merged = mergeFeatureCollections(
			...(actualData.map((d) => d.data) as FeatureCollection<
				Polygon | MultiPolygon
			>[])
		);

		return {
			isPending,
			data: merged,
			refetch,
			isError,
			error
		};
	}, []);

	return createQueryOptions({
		queries: availableLayers.map((d) => {
			return createGeoJSONPolygonDataQuery(api, {
				farmId: d.farm_id,
				layerFilterId: opts.layerFilterId,
				date: d
			});
		}),
		combine: combine
	});
}

function createQueryOptions<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Array<any>,
	TCombinedResult = QueriesResults<T>
>({
	queries,
	combine
}: {
	queries: readonly [...QueriesOptions<T>];
	combine?: (result: QueriesResults<T>) => TCombinedResult;
}) {
	return {
		queries,
		combine
	};
}
