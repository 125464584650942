export function isObject(value: unknown): value is Record<string, unknown> {
	return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function sortObject<T extends Record<string, unknown>>(obj: T): T {
	return Object.keys(obj)
		.sort()
		.reduce((result, key) => {
			result[key as keyof T] = obj[key] as T[keyof T];
			return result;
		}, {} as T);
}
