export const isTransparentHexColor = (color?: string) => {
	if (!color) return true;
	const hasTransparency = color?.length === 9;
	if (!hasTransparency) return false;
	const lastTwo = color.slice(-2);
	return lastTwo === '00';
};
export const randomHsl = (opacity?: number) =>
	`hsla(${Math.random() * 360}, 100%, 50%, ${opacity ?? 1})`;

export const randomHex = () => {
	const randomColor = Math.floor(Math.random() * 16777215).toString(16);
	return `#${randomColor}`;
};

/**
 * Converts HSL color to Hex color
 * @param _color - string in HSL/A format
 * @returns Hex color
 * @example
 * HSLtoHex('hsl(120, 100%, 50%)') // '#00ff00'
 * @summary Converts HSL color to Hex color, ignoring alpha channel if present
 */
export function HSLtoHex(_color: string): string {
	const color = _color
		.replaceAll('hsla(', '')
		.replaceAll(')', '')
		.replaceAll('hsl(', '');
	// eslint-disable-next-line prefer-const
	let [h, s, l] = color.split(',').map((c) => parseFloat(c));
	l /= 100;
	const a = (s * Math.min(l, 1 - l)) / 100;
	const f = (n: number) => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color)
			.toString(16)
			.padStart(2, '0'); // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}

export function isHSL(color: string) {
	return color.startsWith('hsl');
}
