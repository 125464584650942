import {
	Overlay,
	useFindOlLayer,
	useIsInView,
	useMap,
	useOlLayers,
	useTooltipOverlayPositioning,
	useVectorLayer
} from '@agritechnovation/ol-react';
import { convertRemToPixels } from '@agritechnovation/utils';
import type { Style } from 'geostyler-style';
import type { Feature, MapBrowserEvent } from 'ol';
import { useCallback, useEffect } from 'react';
import { useParseGeostyler } from '../../../../use-parse-geostyler';
import { extractMetaFromOlLayer } from '../../../helpers';
import type { FeatureLike } from 'ol/Feature';
import type BaseLayer from 'ol/layer/Base';

/**
 * When a user hovers over a soil classification layer poly or legend item, we want to highlight the polygon/class with a slash pattern.
 */

const HIGHLIGHT_STYLE = {
	name: 'highlight-msc',
	rules: [
		{
			name: 'default',
			symbolizers: [
				{
					kind: 'Fill',
					graphicFill: {
						kind: 'Mark',
						wellKnownName: 'shape://slash'
					}
				}
			]
		}
	]
} satisfies Style;

export type MySoilClassificationHoverOverlayProps = {
	uuid: string;
	zIndex: number;
};
/**
 * If a user loads many layers, we could run into performance issues with this component.
 * So we only render this component if the layer is in view.
 */
export function MySoilClassificationHoverOverlay({
	uuid,
	zIndex,
	farmId
}: MySoilClassificationHoverOverlayProps & { farmId: number }) {
	const layer = useFindOlLayer((l) => l.get('mfw_uuid') === uuid);
	const inView = useIsInView(layer);
	const layers = useOlLayers();
	const otherSoilClass = layers
		.filter(
			(l) =>
				l.get('product') === 'soilclass' &&
				farmId === l.get('farmId') &&
				l.get('mfw_uuid') !== uuid
		)
		.toSorted((a, b) => (a.getZIndex() ?? 0) - (b.getZIndex() ?? 0));
	const isTop = otherSoilClass.every((l) => (l.getZIndex() ?? 0) < zIndex);
	if (!inView) return null;
	if (!isTop) return null;

	return (
		<>
			<InternalMySoilClassificationHoverOverlay uuid={uuid} zIndex={zIndex} />
			<InternalHoverListener uuid={uuid} zIndex={zIndex} />
		</>
	);
}

function InternalHoverListener({
	uuid
}: MySoilClassificationHoverOverlayProps) {
	// const layer = useFindOlLayer((l) => l.get('mfw_uuid') === uuid);
	useMySoilClassificationHover(uuid);
	return null;
}

function InternalMySoilClassificationHoverOverlay({
	uuid,
	zIndex
}: MySoilClassificationHoverOverlayProps) {
	const overlayRef = useTooltipOverlayPositioning();
	const layer = useFindOlLayer((l) => l.get('mfw_uuid') === uuid);
	const { data: parsedHighightStyle } = useParseGeostyler(HIGHLIGHT_STYLE);
	const [highlightingLayer] = useVectorLayer({
		data: undefined,
		style: parsedHighightStyle?.output,
		zIndex: zIndex
	});

	const info = layer ? extractMetaFromOlLayer(layer) : null;

	useEffect(() => {
		const source = layer?.getSource();
		if (!source) return;
		if (highlightingLayer) {
			const highlighted = info?.highlightedLegend;
			if (!highlighted) {
				highlightingLayer.getSource()?.clear();
				return;
			}
			const features = source.getFeatures();
			const featuresToHighlight = features.filter(
				(f) => f.get('label') === highlighted
			);
			if (featuresToHighlight.length) {
				highlightingLayer.getSource()?.clear();
				highlightingLayer.getSource()?.addFeatures(featuresToHighlight);
			} else {
				highlightingLayer.getSource()?.clear();
			}
		}
	}, [highlightingLayer, info?.highlightedLegend, layer]);

	if (!layer) return null;
	if (!info?.highlightedLegend) return null;

	const xOffset =
		(convertRemToPixels('0.75') * info.highlightedLegend.length) /
		(2 * devicePixelRatio);

	return (
		<Overlay
			ref={overlayRef}
			show={true}
			offset={[xOffset, -7]}
			className="bg-[#333333] bg-opacity-50 left-2 text-white rounded-sm text-xs px-2 w-fit whitespace-nowrap font-bold pointer-events-none"
		>
			<h1 className={'w-full pointer-events-none'}>
				{info?.highlightedLegend}
			</h1>
		</Overlay>
	);
}

/**
 * set the highlighted legend/class/label on hover
 */

function useMySoilClassificationHover(uuid: string) {
	const map = useMap();
	const onMouseOver = useCallback(
		async (e: MapBrowserEvent<MouseEvent>) => {
			const feats: Array<{
				layer: BaseLayer;
				feature: FeatureLike;
			}> = [];
			map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
				feats.push({ layer, feature });
			});
			const soilClassLayer = feats.find(
				(f) => f.layer?.get('mfw_uuid') === uuid
			);
			if (!soilClassLayer) {
				const soilClassLayer = map
					.getLayers()
					.getArray()
					.find((l) => l.get('mfw_uuid') === uuid);
				soilClassLayer?.set('highlighted-legend', null);
			} else {
				const feature = soilClassLayer.feature as Feature;
				const label = feature.get('label');
				soilClassLayer.layer.set('highlighted-legend', label);
			}
		},
		[map, uuid]
	);

	useEffect(() => {
		if (!map) return;
		map.on('pointermove', onMouseOver);
		return () => {
			map.un('pointermove', onMouseOver);
		};
	}, [map, onMouseOver]);
}
