import { useVectorLayer } from '@agritechnovation/ol-react';
import { useGeoJSONPointData } from '../../data/geojson/use-geojson-point-data';
import { useGeoJSONPointStyle } from '../../data/geojson/use-geojson-point-style';
import type { LayerOptions } from '../../layer-options';
import { useFindLayer } from '../../../helpers';
import { useMemo } from 'react';
import { useSyncProperties } from './use-sync-properties';
import { useParseGeostyler } from '../../../use-parse-geostyler';
import { useLayerAvailableLayers } from '../../helpers';

export function useDefaultPointLayer(opts: LayerOptions) {
	const style = useGeoJSONPointStyle({
		...opts.data,
		displayKey: opts.style?.displayKey,
		iconURLCreator: opts.style?.iconURLCreator
	});
	const {
		data: parsed,
		isError: isStyleParseError,
		error: styleParseError
	} = useParseGeostyler(style.style);

	if (isStyleParseError) {
		throw styleParseError;
	}

	const {
		data: availableLayers = [],
		isError: isALError,
		error: ALError
	} = useLayerAvailableLayers({
		farmId: opts.data.farmId,
		layerFilterId: opts.data.layerFilterId,
		dates: opts.data.dates,
		dateBucket: opts.data.dateBucket,
		depth: opts.data.depth
	});

	if (isALError) {
		throw ALError;
	}

	const { data, isError, error } = useGeoJSONPointData(opts.data);
	if (data && data.features.length === 0) {
		throw new Error('Layer has no features');
	}

	if (isError) {
		throw error;
	}

	const initialLayer = useFindLayer(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);

	const properties = useMemo(() => {
		return {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			dates: opts.data.dates,
			layerFilterId: opts.data.layerFilterId,
			available_layer_id: availableLayers?.[0]?.id,
			geoStylerStyle: style.style,
			unit: style.unit
		};
	}, [
		availableLayers,
		opts.data.dates,
		opts.data.farmId,
		opts.data.layerFilterId,
		opts.layer.uniqueId,
		style.style,
		style.unit
	]);

	const [layer] = useVectorLayer({
		initialLayer,
		declutter: opts.render.declutter,
		zIndex: opts.render.zIndex ? opts.render.zIndex + 100 : 250,
		opacity: opts.render.opacity,
		data,
		style: parsed?.output
	});

	useSyncProperties(layer, properties);
}
