import {
	useCallback,
	useDeferredValue,
	useEffect,
	useRef,
	useState
} from 'react';
import type { Coordinate } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';
import { useMap } from '../map';

/**
 * Hook to get the center of the map
 * @param debounce - Debounce time in milliseconds
 * @returns The center of the map in current map projection
 */
export const useMapCenter = (debounce?: number) => {
	const map = useMap();
	const [center, setCenter] = useState(() => {
		const center = map.getView().getCenter();
		if (center) return toLonLat(center);
		return [0, 0] as Coordinate;
	});
	const timeoutRef = useRef<number | null>(null);
	const onCenterChange = useCallback(() => {
		const center = map.getView().getCenter();
		if (!center) return;
		if (debounce) {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			timeoutRef.current = window.setTimeout(() => {
				setCenter(toLonLat(center));
			}, debounce);
			return;
		} else {
			setCenter(toLonLat(center));
		}
	}, [debounce, map]);
	useEffect(() => {
		map.on('moveend', onCenterChange);
		return () => {
			map.un('moveend', onCenterChange);
		};
	}, [onCenterChange, map]);
	return useDeferredValue(center);
};
