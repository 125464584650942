import type VectorLayer from 'ol/layer/Vector';
import { useCompare } from './use-compare';
import type { FeatureLike } from 'ol/Feature';

export function CompareLayers<S extends FeatureLike>({
	leftLayer,
	rightLayer
}: {
	leftLayer?: VectorLayer<S>;
	rightLayer?: VectorLayer<S>;
}) {
	useCompare(leftLayer, rightLayer);
	return null;
}
