import type { LayerFilter, RawLegendEntry } from '@agritechnovation/api';

export function getUnit(
	merged?: RawLegendEntry[],
	layerFilter?: LayerFilter
): string {
	const fromLegend = merged?.find((x) =>
		!isNaN(Number(x?.max)) ? Number(x?.max) < 0 : false
	);
	if (fromLegend?.legend) {
		return fromLegend.legend.toString();
	}
	if (layerFilter?.unit) {
		return layerFilter.unit;
	}
	return '';
}
