import { type PropsWithChildren } from 'react';
import type { IMapLayersContext } from './map-layers.context';
import { MapLayersContext } from './map-layers.context';

export function MapLayerProvider({
	children,
	ctx
}: PropsWithChildren<{
	ctx: IMapLayersContext;
}>) {
	return (
		<MapLayersContext.Provider value={ctx}>
			{children}
		</MapLayersContext.Provider>
	);
}
