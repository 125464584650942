import type { PpmLayerOptions } from './use-ppm-layer';
import { useMemo } from 'react';
import { usePPMBlocksGEOJSONByDateBucket } from '@agritechnovation/api-query';
import { useVectorLayer } from '@agritechnovation/ol-react/src/layers/vector/use-vector-layer';
import {
	useLayerFilter,
	usePPMCropsOptions,
	usePPMObservationMethodByName
} from '@agritechnovation/api-query';

import { useQuery } from '@tanstack/react-query';
import { extractTranslatedCropAndMethodFromLayerFilter } from '@agritechnovation/api';
import { type BoundaryOptions, useBlockConfig } from '../../../boundary';
import { useFindLayer } from '../../../helpers';
import { useLayerAvailableLayers } from '../../helpers';
import { useParseGeostyler } from '../../../use-parse-geostyler';

export function usePpmBlocks(opts: PpmLayerOptions & BoundaryOptions) {
	const { data: layerDates } = useLayerAvailableLayers(opts.data);

	const { data: layerFilter } = useLayerFilter(opts.data.layerFilterId);
	const cropsOptions = usePPMCropsOptions();
	const { data: info, isPending: isCropPending } = useQuery({
		...cropsOptions,
		enabled: !!layerFilter,
		select: (data) => {
			if (!layerFilter) return;
			try {
				const { crop, method } =
					extractTranslatedCropAndMethodFromLayerFilter(layerFilter);
				return {
					crop_id: data.find((c) => c.crop === crop)?.id,
					method: method
				};
			} catch {
				return;
			}
		}
	});

	const { data: observationMethod, isPending: isObservationPending } =
		usePPMObservationMethodByName(info?.method);

	const shouldTryFetchBlocks = useMemo(() => {
		// basically, if extracting the crop and method from the layer filter fails, we should then try fetch the blocks but only using the date_bucket and farmId
		if (!isCropPending && isObservationPending && !info) {
			return true;
		}
		return !isCropPending && !isObservationPending;
	}, [info, isCropPending, isObservationPending]);

	const date = useMemo(() => layerDates?.at(0)?.date, [layerDates]);

	const date_bucket = useMemo(
		() => layerDates?.at(0)?.date_bucket,
		[layerDates]
	);

	const { data } = usePPMBlocksGEOJSONByDateBucket(
		{
			farmId: opts.data.farmId,
			date_bucket,
			crop_id: info?.crop_id,
			observation_method_id: observationMethod?.id
		},
		{
			wait: !shouldTryFetchBlocks
		}
	);

	const config = useBlockConfig(opts);
	const styleQuery = useParseGeostyler(config.style);
	const initialLayer = useFindLayer(
		(l) =>
			l.get('mfw_uuid') === `ppm-blocks-${opts.data.farmId}-date-${date_bucket}`
	);
	const [layer] = useVectorLayer({
		initialLayer,
		data,
		zIndex: opts.zIndex,
		properties: {
			available_layer_id: date,
			mfw_uuid: `${opts.layer.uniqueId}-ppm-blocks-${opts.data.farmId}-date-${date}`,
			type: 'blocks',
			farmId: opts.data.farmId
		},
		style: styleQuery.data?.output,
		visible: opts.ppm.showBlocks === undefined ? true : opts.ppm.showBlocks
	});

	return [layer] as const;
}
