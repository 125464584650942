import type BaseLayer from 'ol/layer/Base';
import type Map from 'ol/Map';
import type { VectorLayerWithVectorSource } from './map-layers.types';
import { useMemo } from 'react';
import { useMap } from '@agritechnovation/ol-react';

export function findLayer<T extends BaseLayer = VectorLayerWithVectorSource>(
	map: Map,
	find: (l: BaseLayer) => boolean
) {
	return map.getLayers().getArray().find(find) as T | undefined;
}

export function useFindLayer<T extends BaseLayer = VectorLayerWithVectorSource>(
	find: (l: BaseLayer) => boolean
) {
	const map = useMap();
	return useMemo(() => findLayer<T>(map, find), [find, map]);
}
