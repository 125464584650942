import { useMap } from '../map';
import { useCallback, useEffect, useState } from 'react';
import { useMapCenter } from './use-map-center';
import { useMapZoom } from './use-map-zoom';

export const useMapView = () => {
	const map = useMap();
	const [view, setView] = useState(() => map.getView());
	const listener = useCallback(() => {
		setView(map.getView());
	}, [map]);
	useEffect(() => {
		map.on('change:view', listener);
		return () => map.un('change:view', listener);
	}, [map, listener]);
	return view;
};

export const useMapViewExtent = () => {
	const view = useMapView();
	const map = useMap();
	useMapCenter();
	useMapZoom();
	return view.calculateExtent(map.getSize());
};

export const useMapProjection = () => {
	const view = useMapView();
	const [projection, setProjection] = useState(() => view.getProjection());

	const listener = useCallback(() => {
		const currentCode = view.getProjection().getCode();
		const previousCode = projection.getCode();
		if (currentCode !== previousCode) {
			setProjection(view.getProjection());
		}
	}, [projection, view]);

	useEffect(() => {
		view.on('change', listener);
		return () => {
			view.un('change', listener);
		};
	}, [listener, view]);

	return projection;
};
